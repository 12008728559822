import { SvgIcon } from '@mui/material';
import React from 'react';

function CircleCheckmark({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '30px', ...sx }} width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M15 26.25C21.2132 26.25 26.25 21.2132 26.25 15C26.25 12.616 25.5084 10.4051 24.2434 8.58555L15.105 18.7393C14.4124 19.5089 13.2468 19.6226 12.4184 19.0013L8.15 15.8C7.70817 15.4686 7.61863 14.8418 7.95 14.4C8.28137 13.9582 8.90817 13.8686 9.35 14.2L13.6184 17.4013L22.9466 7.03671C20.9114 5.00576 18.1024 3.75 15 3.75C8.7868 3.75 3.75 8.7868 3.75 15C3.75 21.2132 8.7868 26.25 15 26.25Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default CircleCheckmark;
