import { SvgIcon } from '@mui/material';
import React from 'react';

function CircleStopInfo({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '30px', ...sx }} width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M15 26.25C21.2132 26.25 26.25 21.2132 26.25 15C26.25 8.7868 21.2132 3.75 15 3.75C8.7868 3.75 3.75 8.7868 3.75 15C3.75 21.2132 8.7868 26.25 15 26.25ZM8.75 16.5H21.25V13.5H8.75V16.5Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default CircleStopInfo;
