import { SvgIcon } from '@mui/material';
import React from 'react';

function DevKitIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '24px', ...sx }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <ellipse cx="12" cy="7" rx="7" ry="3" stroke="#6E6B7B" strokeWidth="2" />
      <path d="M5 13C5 13 5 15.3431 5 17C5 18.6569 8.13401 20 12 20C15.866 20 19 18.6569 19 17C19 16.173 19 13 19 13" stroke="#6E6B7B" strokeWidth="2" strokeLinecap="square" />
      <path d="M5 7C5 7 5 10.3431 5 12C5 13.6569 8.13401 15 12 15C15.866 15 19 13.6569 19 12C19 11.173 19 7 19 7" stroke="#6E6B7B" strokeWidth="2" />
    </SvgIcon>
  );
}

export default DevKitIcon;
