import { SvgIcon } from '@mui/material';
import React from 'react';

function DoneDisableIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '114px', ...sx }} width="114" height="113" viewBox="0 0 114 113" fill="none" {...props}>
      <circle cx="57.4663" cy="57.1562" r="56.5" fill="#EBE9F1" fillOpacity="0.05" />
      <circle cx="57.4665" cy="57.1545" r="38.2912" fill="#EBE9F1" fillOpacity="0.1" />
      <circle cx="57.4663" cy="57.1523" r="32.6172" fill="#EBE9F1" />
      <path d="M54.6099 66.2639C54.3769 66.4982 54.0593 66.629 53.7291 66.629C53.399 66.629 53.0813 66.4982 52.8484 66.2639L45.8146 59.229C45.0846 58.4989 45.0846 57.3154 45.8146 56.5866L46.6954 55.7059C47.4254 54.9758 48.6077 54.9758 49.3377 55.7059L53.7291 60.0973L65.5953 48.2311C66.3253 47.5011 67.5088 47.5011 68.2376 48.2311L69.1184 49.1119C69.8484 49.8419 69.8484 51.0254 69.1184 51.7542L54.6099 66.2639Z" fill="white" />

    </SvgIcon>
  );
}

export default DoneDisableIcon;
