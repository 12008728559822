import { SvgIcon } from '@mui/material';
import React from 'react';

function GetInsightIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '30px', ...sx }} width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.8562 15.6858L14.8562 15.6858C14.8219 15.769 14.7885 15.8525 14.7562 15.9363C12.9685 16.5341 11.0314 16.534 9.24375 15.9362C9.21144 15.8524 9.17811 15.7689 9.14377 15.6858C9.04854 15.4554 9.00093 15.3401 8.91839 15.2337C8.83585 15.1273 8.69186 15.0193 8.40387 14.8033C6.94433 13.7089 6 11.9647 6 10C6 6.68629 8.68629 4 12 4C15.3137 4 18 6.68629 18 10C18 11.9647 17.0557 13.7089 15.5961 14.8033L15.5961 14.8034C15.3081 15.0193 15.1641 15.1273 15.0816 15.2337C14.9991 15.3401 14.9515 15.4554 14.8562 15.6858ZM9.85021 18.1664C9.93686 18.6932 9.98631 19.227 9.99753 19.7639C10.0006 19.9086 10.0817 20.0409 10.2111 20.1056C11.3373 20.6686 12.6627 20.6686 13.7889 20.1056C13.9183 20.0409 13.9994 19.9086 14.0025 19.7639C14.0137 19.227 14.0631 18.6933 14.1498 18.1665C12.7318 18.4573 11.2682 18.4573 9.85021 18.1664Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default GetInsightIcon;
