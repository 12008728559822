import { SvgIcon } from '@mui/material';
import React from 'react';

function EbookIcon(props) {
  return (
    <SvgIcon width="13" height="16" viewBox="0 0 13 16" fill="none" {...props}>
      <path d="M8.02637 4.86401H11.6176L7.62012 0.994904V4.48499C7.62012 4.69029 7.79887 4.86401 8.02637 4.86401Z" fill="#615E8B" />
      <path d="M8.02639 6.04843C7.13264 6.04843 6.40139 5.33778 6.40139 4.46921V0.394806H1.02264C0.470137 0.394806 0.0151367 0.836989 0.0151367 1.37393V14.4025C0.0151367 14.9395 0.470137 15.3817 1.02264 15.3817H11.1951C11.7476 15.3817 12.2026 14.9395 12.2026 14.4025V6.03264H8.02639V6.04843Z" fill="white" />
    </SvgIcon>
  );
}

export default EbookIcon;
