import { SvgIcon } from '@mui/material';
import React from 'react';

function ExportToExcelIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={sx} width="16" height="13" viewBox="0 0 16 13" fill="none" {...props}>
      <path d="M11.0185 7.62881C11.2803 7.89064 11.2803 8.31386 11.0185 8.57569C10.7566 8.83752 10.3334 8.83752 10.0716 8.57569L8.53611 7.0402L8.53611 11.7277C8.53611 12.098 8.23677 12.3973 7.86646 12.3973C7.49615 12.3973 7.19682 12.098 7.19682 11.7277L7.19682 7.0402L5.66133 8.57569C5.3995 8.83752 4.97628 8.83752 4.71445 8.57569C4.45262 8.31386 4.45262 7.89064 4.71445 7.62881L6.91959 5.42368C7.17874 5.16453 7.51959 5.03395 7.86044 5.03261L7.86646 5.03127L7.87249 5.03261C8.21334 5.03395 8.55419 5.16453 8.81334 5.42368L11.0185 7.62881Z" fill="#108AE2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.5625 7.03125C14.0447 7.03125 13.625 6.61152 13.625 6.09375L13.625 2.74554L2.375 2.74553L2.375 6.09375C2.375 6.61152 1.95527 7.03125 1.4375 7.03125C0.919733 7.03125 0.5 6.61152 0.5 6.09375L0.5 2.74553L0.500001 0.602677L2.375 0.602678L13.625 0.602679L15.5 0.602679L15.5 2.74554L15.5 6.09375C15.5 6.61152 15.0803 7.03125 14.5625 7.03125Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default ExportToExcelIcon;
