import { SvgIcon } from '@mui/material';
import React from 'react';

function CopyIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '21px', ...sx }} width="19" height="21" viewBox="0 0 19 21" fill="none" {...props}>
      <path d="M15.8333 17.5H3C1.34314 17.5 0 16.1569 0 14.5V2.625C0 1.92881 0.250222 1.26113 0.695621 0.768845C1.14102 0.276562 1.74511 0 2.375 0L13.4583 0C14.0882 0 14.6923 0.276562 15.1377 0.768845C15.5831 1.26113 15.8333 1.92881 15.8333 2.625V17.5ZM17.4167 3.661V19.25H4.04167C3.55842 19.25 3.16667 19.6418 3.16667 20.125C3.16667 20.6082 3.55842 21 4.04167 21H19V6.125C18.998 5.58402 18.8448 5.05695 18.5613 4.61589C18.2779 4.17483 17.8781 3.84132 17.4167 3.661Z" fill="#D3D0D9" />
    </SvgIcon>
  );
}

export default CopyIcon;
