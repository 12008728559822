import { SvgIcon } from '@mui/material';
import React from 'react';

function CheckedStepperIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '30px', ...sx }} width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <rect width="30" height="30" rx="5" fill="#108AE2" />
      <path d="M21.7022 10.2736C21.6162 10.1869 21.5139 10.1181 21.4012 10.0711C21.2885 10.0242 21.1676 10 21.0454 10C20.9233 10 20.8024 10.0242 20.6897 10.0711C20.577 10.1181 20.4747 10.1869 20.3887 10.2736L13.4973 17.1742L10.6021 14.2697C10.5128 14.1834 10.4074 14.1156 10.2919 14.0701C10.1764 14.0246 10.0531 14.0023 9.92894 14.0044C9.80482 14.0066 9.68234 14.0331 9.56849 14.0826C9.45464 14.1321 9.35165 14.2035 9.26541 14.2928C9.17916 14.3821 9.11134 14.4875 9.06583 14.603C9.02032 14.7185 8.99799 14.8418 9.00014 14.9659C9.00229 15.09 9.02886 15.2125 9.07834 15.3264C9.12783 15.4402 9.19925 15.5432 9.28853 15.6294L12.8406 19.1815C12.9266 19.2682 13.0289 19.337 13.1416 19.384C13.2543 19.4309 13.3752 19.4551 13.4973 19.4551C13.6195 19.4551 13.7404 19.4309 13.8531 19.384C13.9658 19.337 14.0681 19.2682 14.1541 19.1815L21.7022 11.6334C21.7961 11.5468 21.871 11.4416 21.9223 11.3246C21.9735 11.2076 22 11.0812 22 10.9535C22 10.8258 21.9735 10.6994 21.9223 10.5824C21.871 10.4654 21.7961 10.3602 21.7022 10.2736Z" fill="white" />
    </SvgIcon>
  );
}

export default CheckedStepperIcon;
