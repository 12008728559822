import { SvgIcon } from '@mui/material';
import React from 'react';

function InfoIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '16px', ...sx }} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M8.00017 1.33325C6.68162 1.33325 5.39269 1.72425 4.29636 2.45679C3.20004 3.18933 2.34555 4.23052 1.84097 5.4487C1.33638 6.66687 1.20436 8.00731 1.4616 9.30052C1.71883 10.5937 2.35377 11.7816 3.28612 12.714C4.21847 13.6463 5.40636 14.2813 6.69956 14.5385C7.99277 14.7957 9.33322 14.6637 10.5514 14.1591C11.7696 13.6545 12.8108 12.8 13.5433 11.7037C14.2758 10.6074 14.6668 9.31846 14.6668 7.99992C14.6668 7.12444 14.4944 6.25753 14.1594 5.4487C13.8243 4.63986 13.3333 3.90493 12.7142 3.28587C12.0952 2.66682 11.3602 2.17575 10.5514 1.84072C9.74255 1.50569 8.87565 1.33325 8.00017 1.33325V1.33325ZM8.00017 13.3333C6.94533 13.3333 5.91419 13.0205 5.03712 12.4344C4.16006 11.8484 3.47648 11.0154 3.07281 10.0409C2.66914 9.06636 2.56352 7.994 2.76931 6.95944C2.9751 5.92487 3.48305 4.97456 4.22893 4.22868C4.97481 3.4828 5.92512 2.97485 6.95968 2.76906C7.99425 2.56328 9.06661 2.66889 10.0411 3.07256C11.0157 3.47623 11.8486 4.15981 12.4347 5.03688C13.0207 5.91394 13.3335 6.94508 13.3335 7.99992C13.3335 9.41441 12.7716 10.771 11.7714 11.7712C10.7712 12.7713 9.41465 13.3333 8.00017 13.3333V13.3333Z" fill="#D3D0D9" />
      <path d="M8.00016 6.00008C8.36835 6.00008 8.66683 5.7016 8.66683 5.33341C8.66683 4.96522 8.36835 4.66675 8.00016 4.66675C7.63197 4.66675 7.3335 4.96522 7.3335 5.33341C7.3335 5.7016 7.63197 6.00008 8.00016 6.00008Z" fill="#D3D0D9" />
      <path d="M8.00016 6.66675C7.82335 6.66675 7.65378 6.73699 7.52876 6.86201C7.40373 6.98703 7.3335 7.1566 7.3335 7.33341V10.6667C7.3335 10.8436 7.40373 11.0131 7.52876 11.1382C7.65378 11.2632 7.82335 11.3334 8.00016 11.3334C8.17697 11.3334 8.34654 11.2632 8.47157 11.1382C8.59659 11.0131 8.66683 10.8436 8.66683 10.6667V7.33341C8.66683 7.1566 8.59659 6.98703 8.47157 6.86201C8.34654 6.73699 8.17697 6.66675 8.00016 6.66675Z" fill="#D3D0D9" />
    </SvgIcon>
  );
}

export default InfoIcon;
