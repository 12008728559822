import { SvgIcon } from '@mui/material';
import React from 'react';

function PlusIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '11px !important', ...sx }} width="11" height="11" viewBox="0 0 11 11" fill="none" {...props}>
      <path d="M10.0833 4.58333H6.41667V0.916667C6.41667 0.673552 6.32009 0.440394 6.14818 0.268486C5.97627 0.0965773 5.74311 0 5.5 0C5.25689 0 5.02373 0.0965773 4.85182 0.268486C4.67991 0.440394 4.58333 0.673552 4.58333 0.916667V4.58333H0.916667C0.673552 4.58333 0.440394 4.67991 0.268486 4.85182C0.0965773 5.02373 0 5.25689 0 5.5C0 5.74311 0.0965773 5.97627 0.268486 6.14818C0.440394 6.32009 0.673552 6.41667 0.916667 6.41667H4.58333V10.0833C4.58333 10.3264 4.67991 10.5596 4.85182 10.7315C5.02373 10.9034 5.25689 11 5.5 11C5.74311 11 5.97627 10.9034 6.14818 10.7315C6.32009 10.5596 6.41667 10.3264 6.41667 10.0833V6.41667H10.0833C10.3264 6.41667 10.5596 6.32009 10.7315 6.14818C10.9034 5.97627 11 5.74311 11 5.5C11 5.25689 10.9034 5.02373 10.7315 4.85182C10.5596 4.67991 10.3264 4.58333 10.0833 4.58333Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default PlusIcon;
