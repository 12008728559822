import { SvgIcon } from '@mui/material';
import React from 'react';

function EditIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '11px', ...sx }} width="12" height="11" viewBox="0 0 12 11" fill="none" {...props}>
      <path d="M7.19348 2.60669L0.5 9.29971V11.0001H2.20041L8.89343 4.30664L7.19348 2.60669Z" fill="#108AE2" />
      <path d="M11.1483 0.352046C10.9228 0.126629 10.617 0 10.2981 0C9.97925 0 9.67344 0.126629 9.44791 0.352046L7.84375 1.9585L9.5437 3.65845L11.1479 2.05429C11.2598 1.94262 11.3486 1.80997 11.4092 1.66394C11.4698 1.51791 11.501 1.36137 11.5011 1.20326C11.5011 1.04516 11.47 0.888596 11.4094 0.742535C11.3489 0.596474 11.2602 0.46378 11.1483 0.352046Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default EditIcon;
