export const EXPORT_KEYS_RAW_REPORT = [
  { title: 'Date', field: 'reportDate' },
  { title: 'Customer', field: 'partner_customer_id' },
  { title: 'Partner', field: 'partner_name' },
  { title: 'Impression Date', field: 'impression_date' },
  { title: 'Conversion Date', field: 'action_date' },
  { title: 'Average Time To Conversion', field: 'diffDays' },
  { title: 'Impression Profile', field: 'impression_profile_id' },
  { title: 'Conversion Profile', field: 'action_profile_id' },
  { title: 'Impression Device ID', field: 'impression_device_id' },
  { title: 'Impression Beacon', field: 'impression_beacon' },
  { title: 'Conversion Beacon', field: 'action_beacon' },
  { title: 'Frequency', field: 'frequency' },
  { title: 'Total Conversions', field: 'totalActions' },
];
