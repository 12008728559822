import { SvgIcon } from '@mui/material';
import React from 'react';

function EmptyCollectedIds({ sx, ...props }) {
  return (
    <SvgIcon sx={{ ...sx }} width="146" height="130" viewBox="0 0 146 130" {...props}>
      <mask id="mask0_3624_18517" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="48" y="66" width="61" height="9">
        <path fillRule="evenodd" clipRule="evenodd" d="M101.1 71.3463L97.2383 67.807L93.1061 70.8742L91.3739 68.4877L84.536 70.8742L68.7837 68.4877L59.6273 71.0157C62.4271 70.9221 65.2719 70.8742 68.1543 70.8742C82.5759 70.8742 96.0719 72.0742 107.611 74.1611C108.055 72.1868 108.355 70.1562 108.497 68.0799L107.19 66.4805L101.1 71.3463ZM48.5146 71.64C52.1223 71.3525 55.8328 71.1426 59.6271 71.016L52.863 66.6022L48.5146 71.64Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3624_18517)">
        <path fillRule="evenodd" clipRule="evenodd" d="M46.6836 75.9904H110.323V64.6523H46.6836V75.9904Z" fill="#E0E2EE" />
      </g>
      <mask id="mask1_3624_18517" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="32" y="65" width="16" height="9">
        <path fillRule="evenodd" clipRule="evenodd" d="M32.4189 71.1158C32.5338 71.8952 32.6724 72.6676 32.8337 73.4316C37.5514 72.7064 42.5559 72.123 47.7802 71.6973L40.5052 65.0195L32.4189 71.1158Z" fill="white" />
      </mask>
      <g mask="url(#mask1_3624_18517)">
        <path fillRule="evenodd" clipRule="evenodd" d="M30.5898 75.261H49.6085V63.1914H30.5898V75.261Z" fill="#E0E2EE" />
      </g>
      <mask id="mask2_3624_18517" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="70" width="146" height="37">
        <ellipse cx="72.7888" cy="88.486" rx="72.7888" ry="18.2165" fill="#E8EBF2" />
      </mask>
      <g mask="url(#mask2_3624_18517)">
        <circle cx="70.3191" cy="63.4021" r="38.9802" fill="#E8EBF2" />
      </g>
      <path opacity="0.5" d="M50.4727 80.6275C50.4727 80.6275 63.7605 86.8757 69.032 89.2736C73.2074 91.173 94.7078 97.249 97.2692 95.5219C99.8305 93.7948 105.486 89.0546 103.619 85.807C101.752 82.5595 91.9329 76.7283 87.2371 75.8763C82.5412 75.0243 69.114 78.8974 69.114 78.8974L57.6609 80.8642L50.4727 80.6275Z" fill="url(#paint0_linear_3624_18517)" />
      <path d="M60.3251 52.6904L45.5178 63.5781L49.466 54.3152L64.2949 44.901L60.3251 52.6904Z" fill="#E1E3ED" />
      <path d="M49.2168 54.5077L64.2921 45.0703V70.8086L50.4731 80.4972L49.2168 54.5077Z" fill="#E4E6ED" />
      <path d="M64.2949 45.0703L87.4103 50.4782V76.2259L64.2949 70.818V45.0703Z" fill="#EEF0F9" />
      <path d="M49.2168 54.5078L72.8347 60.0273V85.7749L50.4731 80.64L49.2168 54.5078Z" fill="#EEF0F9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M67.6801 76.4593L66.5998 75.1851C67.3633 74.1681 67.8157 72.8671 67.8278 71.3916C67.8602 67.4362 64.7147 63.7261 60.8034 63.1066C56.8921 62.4871 53.6915 65.1921 53.659 69.1475C53.6266 73.1029 56.7721 76.813 60.6834 77.4325C62.135 77.6624 63.4843 77.4344 64.6168 76.8576L65.702 78.1375C66.2397 78.7717 67.122 78.9115 67.6663 78.4486C68.208 77.9854 68.2154 77.0931 67.6801 76.4593ZM64.5687 72.7946L64.1288 72.2707C63.5911 71.6365 62.7088 71.4967 62.1645 71.9596C61.6178 72.422 61.6105 73.3143 62.1483 73.946L62.5833 74.4591C62.0166 74.6539 61.377 74.7098 60.7042 74.6032C58.3382 74.2285 56.4342 71.9829 56.4538 69.5902C56.4735 67.1975 58.4093 65.56 60.7753 65.9347C63.1412 66.3094 65.0452 68.5551 65.0256 70.9478C65.0175 71.6353 64.8569 72.2612 64.5687 72.7946Z" fill="#D8DBEA" fillOpacity="0.7" />
      <path d="M72.4521 60.1678L87.5274 50.7305V76.4687L72.4521 85.9061V60.1678Z" fill="#D8DBEA" />
      <mask style={{ maskType: 'luminance' }} id="mask3_3624_18517" maskUnits="userSpaceOnUse" x="72" y="50" width="16" height="36">
        <path d="M72.4521 60.1678L87.5274 50.7305V76.4687L72.4521 85.9061V60.1678Z" fill="white" />
      </mask>
      <g mask="url(#mask3_3624_18517)">
        <rect x="78.0537" y="48.5859" width="4.73794" height="39.4653" fill="#D8DBEA" />
        <path fillRule="evenodd" clipRule="evenodd" d="M86.1684 70.7615L85.2483 70.1161C85.7653 68.7508 86.023 67.2786 85.9298 65.8708C85.6798 62.0968 83.0006 60.2174 79.947 61.6739C76.8934 63.1304 74.6178 67.3731 74.8678 71.1471C75.1178 74.9211 77.7969 76.8005 80.8506 75.344C81.9839 74.8034 83.0066 73.8814 83.8382 72.7414L84.7625 73.3898C85.2205 73.7111 85.9093 73.3825 86.2961 72.6583C86.6809 71.9351 86.6245 71.0837 86.1684 70.7615ZM83.5187 68.9073L83.1437 68.6397C82.6857 68.3185 81.9969 68.647 81.6102 69.3712C81.2215 70.0963 81.2779 70.9476 81.7357 71.2665L82.1063 71.5264C81.6836 72.0077 81.1952 72.3952 80.6699 72.6458C78.8227 73.5269 77.201 72.3893 77.0498 70.1063C76.8986 67.8234 78.2748 65.2559 80.122 64.3748C81.9691 63.4938 83.5908 64.6314 83.742 66.9143C83.7836 67.5716 83.7035 68.2501 83.5187 68.9073Z" fill="#EEF0F9" />
      </g>
      <path d="M79.1119 67.683L93.9409 58.2687L87.1619 50.5977L72.333 60.0119L79.1119 67.683Z" fill="#E1E3ED" />
      <g filter="url(#filter0_i_3624_18517)">
        <path fillRule="evenodd" clipRule="evenodd" d="M104.523 80.2057L100.576 76.197C95.7524 71.3513 87.9024 71.3513 83.1003 76.197C78.2762 81.0426 78.2762 88.9279 83.1003 93.7516L87.0034 97.6721C81.982 100.183 76.3027 101.571 70.3165 101.571C49.2661 101.571 32.1846 84.4126 32.1846 63.2678C32.1846 42.123 49.2661 24.9648 70.3165 24.9648C91.3669 24.9648 108.448 42.123 108.448 63.2678C108.427 69.3469 107.045 75.0957 104.523 80.2057Z" fill="#D8DBEA" fillOpacity="0.01" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M132.539 108.09L122.831 98.3676C129.552 88.3373 133.483 76.2393 133.483 63.2175C133.483 28.3093 105.217 3.91283e-07 70.3621 2.52116e-07C35.507 1.12948e-07 7.21875 28.3093 7.21875 63.2175C7.21875 98.1257 35.485 126.435 70.3401 126.435C83.2763 126.435 95.268 122.542 105.305 115.877L115.057 125.643C119.888 130.482 127.751 130.482 132.561 125.643C137.349 120.804 137.349 112.929 132.539 108.09ZM104.58 80.1327L100.627 76.1293C95.7951 71.2901 87.9324 71.2901 83.1225 76.1293C78.2907 80.9685 78.2907 88.8432 83.1225 93.6604L87.0319 97.5757C82.0024 100.083 76.314 101.469 70.3182 101.469C49.2338 101.469 32.1247 84.334 32.1247 63.2175C32.1247 42.101 49.2338 24.9658 70.3182 24.9658C91.4025 24.9658 108.512 42.101 108.512 63.2175C108.49 69.2885 107.106 75.0295 104.58 80.1327Z" fill="#F1F5F6" />
      <g filter="url(#filter1_d_3624_18517)">
        <path d="M40.6298 52.3716L40.4373 51.8153C40.3012 51.4217 40.1552 51.0121 39.871 50.7335C39.5868 50.455 39.1197 50.3594 38.8362 50.6387C39.3226 49.8867 39.449 48.8616 39.1625 47.991C38.876 47.1205 38.1851 46.4298 37.3778 46.207C36.4957 45.9636 35.5716 46.2568 34.7132 46.5891C33.8424 46.9262 32.9705 47.3153 32.2499 47.9562C31.5293 48.5972 30.9699 49.5284 30.9181 50.5546C28.83 49.7241 26.5581 49.4747 24.3649 49.8354C23.7019 49.9445 23.0297 50.1157 22.4684 50.5241C21.9072 50.9324 21.4711 51.6189 21.4756 52.3661L21.5373 52.3716H40.6298V52.3716Z" fill="white" />
      </g>
      <g filter="url(#filter2_d_3624_18517)">
        <path d="M123.5 47.2888C123.509 46.8669 123.437 46.4507 123.251 46.0997C122.917 45.4704 122.303 45.1652 121.712 45.1348C121.121 45.1043 120.539 45.3098 119.969 45.5135C120.942 44.5003 121.484 42.8375 121.364 41.2325C121.244 39.6275 120.466 38.1286 119.36 37.3736C118.195 36.5777 116.785 36.6158 115.499 36.9959C112.488 37.8862 109.839 40.6254 108.512 44.2215C106.589 42.091 103.915 41.134 101.415 41.6811C98.9144 42.2283 96.6354 44.2692 95.3655 47.0983L95.3555 47.2888H123.5V47.2888Z" fill="white" />
      </g>
      <path fill="transparent" d="M73.9086 25.2344C65.374 25.9568 62.2373 33.1808 64.807 37.3917C66.6202 40.3627 69.6928 44.378 75.4203 43.4149C81.1475 42.4515 81.63 37.1511 78.6138 36.4301C75.5975 35.7093 64.8518 39.0719 66.0663 46.84C66.9429 52.4449 74.5414 54.0605 74.5414 54.0605" stroke="#BDBDFF" strokeWidth="0.44208" strokeDasharray="2.06 2.06" />
      <path fillRule="evenodd" clipRule="evenodd" d="M78.8409 20.7998C78.8409 20.7998 80.0638 17.6051 80.1927 16.3624C80.2606 15.713 79.1844 13.905 77.925 15.5774C76.6656 17.2498 77.2401 19.3004 78.8409 20.7998" fill="#E42581" />
      <path fillRule="evenodd" clipRule="evenodd" d="M74.5005 17.318C73.0678 16.8336 72.4192 17.2113 72.1429 17.9653C71.8663 18.7195 72.2524 20.1552 74.4132 20.4824C76.5743 20.8097 78.7166 20.7972 78.7166 20.7972C78.7166 20.7972 75.9331 17.8025 74.5005 17.318" fill="#E42581" />
      <path fillRule="evenodd" clipRule="evenodd" d="M78.5979 21.4335C78.6304 21.3 78.6734 21.172 78.7213 21.0469C78.4568 21.0887 78.1877 21.1473 77.9169 21.2268C77.623 21.3127 77.3488 21.4255 77.0909 21.5547C76.9789 21.7663 76.8882 21.9923 76.8293 22.235C76.4663 23.729 77.3234 25.2349 78.7634 25.728C78.9193 25.6971 79.076 25.6614 79.233 25.6155C79.6715 25.487 80.0761 25.317 80.4418 25.12C79.0355 24.503 78.2261 22.9633 78.5979 21.4335" fill="#CECEF3" />
      <path fillRule="evenodd" clipRule="evenodd" d="M76.5427 22.2118C76.5771 22.0642 76.6238 21.9231 76.6756 21.7852C75.4297 22.5833 74.732 23.822 74.8471 24.5443C74.051 24.8865 73.5439 25.1747 73.5439 25.1747C73.5439 25.1747 74.1446 25.129 75.0199 24.9608C75.4887 25.6458 76.7882 26.1045 78.2251 25.94C76.9234 25.2669 76.1887 23.735 76.5427 22.2118" fill="#CECEF3" />
      <path fillRule="evenodd" clipRule="evenodd" d="M80.6629 22.7548C80.3328 22.6756 80.1282 22.3441 80.2077 22.0121C80.2869 21.6823 80.6205 21.478 80.9502 21.5572C81.2818 21.6369 81.4843 21.9697 81.4054 22.2997C81.3257 22.6314 80.9946 22.8342 80.6629 22.7548ZM82.11 22.3108C81.8444 21.3908 80.6232 20.9157 79.1827 21.0784C79.1225 21.221 79.0712 21.3713 79.0346 21.5271C78.7139 22.8926 79.4548 24.2661 80.7115 24.7425C81.7601 24.0755 82.3489 23.1309 82.11 22.3108Z" fill="#CECEF3" />
      <defs>
        <filter id="filter0_i_3624_18517" x="32.1846" y="24.9648" width="76.2637" height="76.6055" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="3.91735" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3624_18517" />
        </filter>
        <filter id="filter1_d_3624_18517" x="15.5812" y="39.3386" width="36.8375" height="23.9371" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="2.9472" dy="2.06304" />
          <feGaussianBlur stdDeviation="4.4208" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.110459 0 0 0 0 0.0661979 0 0 0 0 0.3875 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3624_18517" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3624_18517" result="shape" />
        </filter>
        <filter id="filter2_d_3624_18517" x="89.4611" y="29.9558" width="45.8287" height="28.2379" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="2.9472" dy="2.06304" />
          <feGaussianBlur stdDeviation="4.4208" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.110459 0 0 0 0 0.0661979 0 0 0 0 0.3875 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3624_18517" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3624_18517" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_3624_18517" x1="48.5201" y1="82.7941" x2="52.3854" y2="100.128" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C4CDD1" />
          <stop offset="1" stopColor="#C6CFD3" stopOpacity="0.01" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}

export default EmptyCollectedIds;
