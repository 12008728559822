import { SvgIcon } from '@mui/material';
import React from 'react';

function OfflineDataNavbar({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '18px', ...sx }} width="18" height="20" viewBox="0 0 18 20" fill="none" {...props}>
      <path d="M13.7126 5.3005H10.0912C9.26975 5.3005 8.60547 4.63622 8.60547 3.81479V0.193359C8.83404 0.293359 9.04118 0.436216 9.2269 0.621931L13.284 4.67907C13.4698 4.86479 13.6126 5.07193 13.7126 5.3005Z" fill="#615E8B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.53248 3.81429C7.53248 5.22429 8.67962 6.37143 10.0896 6.37143H13.9039V10.2986C13.5382 10.2207 13.1603 10.1786 12.7725 10.1786C9.76891 10.1786 7.32605 12.6214 7.32605 15.625C7.32605 16.7879 7.69533 17.8643 8.31891 18.75H2.12533C0.953906 18.75 0.00390625 17.8 0.00390625 16.6286V2.12143C0.00390625 0.95 0.953906 0 2.12533 0H7.53248V3.81429ZM6.10605 4.26786H2.68319C2.32819 4.26786 2.04033 4.55571 2.04033 4.91071C2.04033 5.26571 2.32819 5.55357 2.68319 5.55357H6.10605C6.46105 5.55357 6.74891 5.26571 6.74891 4.91071C6.74891 4.55571 6.46105 4.26786 6.10605 4.26786ZM2.68319 9.01357H11.2218C11.5768 9.01357 11.8639 8.72571 11.8639 8.37071C11.8639 8.01571 11.576 7.72786 11.221 7.72786H2.68319C2.32819 7.72786 2.04033 8.01571 2.04033 8.37071C2.04033 8.72571 2.32819 9.01357 2.68319 9.01357Z" fill="#615E8B" />
      <path d="M12.7734 11.25C10.357 11.25 8.39844 13.2086 8.39844 15.625C8.39844 18.0414 10.3577 20 12.7734 20C15.1899 20 17.1484 18.0414 17.1484 15.625C17.1484 13.2086 15.1899 11.25 12.7734 11.25ZM14.2849 15.6386C14.162 15.7493 14.0084 15.8036 13.8549 15.8036C13.6949 15.8036 13.5399 15.7364 13.4163 15.6193V17.29C13.4163 17.645 13.1284 17.9329 12.7734 17.9329C12.4184 17.9329 12.1306 17.645 12.1306 17.29V15.62C11.8899 15.8486 11.5134 15.8643 11.262 15.6386C10.9977 15.4007 10.977 14.9943 11.2142 14.7307L12.2956 13.53C12.4177 13.3943 12.5913 13.3171 12.7734 13.3171C12.9556 13.3171 13.1292 13.3943 13.2513 13.53L14.332 14.7307C14.5699 14.995 14.5484 15.4014 14.2849 15.6386Z" fill="white" />
    </SvgIcon>
  );
}

export default OfflineDataNavbar;
