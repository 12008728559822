import { SvgIcon } from '@mui/material';
import React from 'react';

function PerformanceIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fonSize: '19px', ...sx }} width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
      <path d="M7.46429 19H17.6429C18.389 19 19 18.389 19 17.6429V7.46429H7.46429V19ZM0 17.6429C0 18.389 0.610986 19 1.35714 19H6V13.5V7.46429H0V17.6429ZM17.6429 0H9.5H1.35714C0.610986 0 0 0.610986 0 1.35714V5.42857H19V1.35714C19 0.610986 18.389 0 17.6429 0Z" fill="#615E8B" />
      <path d="M0 1C0 0.447715 0.447715 0 1 0H18C18.5523 0 19 0.447715 19 1V6H0V1Z" fill="white" />
    </SvgIcon>
  );
}

export default PerformanceIcon;
