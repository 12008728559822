import { SvgIcon } from '@mui/material';
import React from 'react';

function UserIcon(props) {
  return (
    <SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path d="M22.3561 22.3184C22.9457 22.1955 23.2968 21.5789 23.0085 21.0501C22.3289 19.8039 21.2443 18.7089 19.8509 17.8773C18.0827 16.822 15.9161 16.25 13.6872 16.25C11.4584 16.25 9.29179 16.822 7.52352 17.8773C6.13019 18.7089 5.04552 19.8039 4.36596 21.0501C4.07761 21.5789 4.42875 22.1955 5.01837 22.3184L5.52636 22.4242C10.9091 23.546 16.4653 23.546 21.8481 22.4242L22.3561 22.3184Z" fill="#D3D0D9" />
      <circle cx="13.6875" cy="9.5" r="5.625" fill="#D3D0D9" />
    </SvgIcon>
  );
}

export default UserIcon;
