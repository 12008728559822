export const MAX_FILE_SIZE_MB = 150;

export const EXPORT_KEYS_FILE_TRACKER = [
  { title: 'Status', field: 'status' },
  { title: 'Uploading Date', field: 'ui_upload_date' },
  { title: 'File Name', field: 'file_name' },
  { title: 'Partner', field: 'partner_id' },
  { title: 'Customer', field: 'customer_id' },
  { title: 'Total Lines', field: 'number_of_lines_total' },
  { title: 'Valid lines', field: 'valid_lines' },
  { title: 'Lines WIth HEM', field: 'has_email' },
  { title: 'Enrichment Rate', field: 'enrichment_rate' },
  { title: 'User', field: 'user' },
  { title: 'Lines Without HEM', field: 'lines_without_hem' },
  { title: 'Action', field: 'action' },
  { title: 'Format', field: 'format' },
  { title: 'Impression Segment', field: 'impression_segment' },
  { title: 'Conversion Segment', field: 'conversion_segment' },
  { title: 'Duplicated Records', field: 'is_duplicate' },
  { title: 'Advertiser', field: 'advertiser_id' },
  { title: 'Lines Matched', field: 'lines_match' },
  { title: 'Match Rate', field: 'match_rate' },
];

export const OFFLINE_DATA_DATE_COLUMNS = ['51', '25'];
