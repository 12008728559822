import { SvgIcon } from '@mui/material';
import React from 'react';

function AudienceIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '30px', ...sx }} width="30" height="17" viewBox="0 0 30 17" fill="none" {...props}>
      <path d="M18.1786 10.7738C17.4483 10.3748 16.6107 10.1484 15.7204 10.1484H13.6044C12.6891 10.1484 11.83 10.3879 11.0861 10.8072C9.52167 11.6879 8.46509 13.3647 8.46509 15.2878V16.8732H20.8598V15.2878C20.8598 13.3397 19.7759 11.6454 18.1786 10.7738Z" fill="#615E8B" />
      <path d="M24.0219 8.74023H22.2629C21.2358 8.74023 20.2785 9.04382 19.4756 9.567C19.8614 9.82008 20.224 10.1157 20.5583 10.4499C21.8503 11.742 22.5619 13.4602 22.5619 15.288V16.0336H29.146V13.8642C29.146 11.0389 26.8472 8.74023 24.0219 8.74023Z" fill="#615E8B" />
      <path d="M6.97635 8.74023H5.21727C2.39197 8.74023 0.0932617 11.0389 0.0932617 13.8642V16.0336H6.76299V15.288C6.76299 13.4602 7.47513 11.742 8.7672 10.4499C9.08894 10.1282 9.43678 9.84221 9.80676 9.59537C8.99531 9.05517 8.02215 8.74023 6.97635 8.74023Z" fill="#615E8B" />
      <path d="M23.1399 1.0332C23.1309 1.0332 23.1216 1.03326 23.1125 1.03337C21.2083 1.04909 19.6725 2.69496 19.6891 4.70218C19.7056 6.69986 21.2533 8.31407 23.1452 8.31407C23.1542 8.31407 23.1636 8.31401 23.1726 8.3139C24.1064 8.30618 24.9777 7.91283 25.6261 7.20619C26.2595 6.51595 26.604 5.60645 26.5961 4.64509C26.5796 2.64741 25.0319 1.0332 23.1399 1.0332Z" fill="#615E8B" />
      <path d="M6.09454 1.0332C6.08551 1.0332 6.07615 1.03326 6.06707 1.03337C4.16285 1.04909 2.62712 2.69496 2.64369 4.70218C2.66014 6.69986 4.20785 8.31407 6.09976 8.31407C6.10884 8.31407 6.11814 8.31401 6.12722 8.3139C7.06089 8.30618 7.93226 7.91283 8.58073 7.20619C9.21417 6.51595 9.55861 5.60645 9.55066 4.64509C9.53415 2.64741 7.98651 1.0332 6.09454 1.0332Z" fill="#615E8B" />
      <path d="M14.6626 0.467773C12.4671 0.467773 10.6814 2.35452 10.6814 4.67423C10.6814 6.35046 11.6143 7.80084 12.9608 8.4761C13.4772 8.73542 14.0543 8.88012 14.6626 8.88012C15.2709 8.88012 15.848 8.73542 16.3643 8.4761C17.7109 7.80084 18.6438 6.35046 18.6438 4.67423C18.6438 2.35452 16.858 0.467773 14.6626 0.467773Z" fill="#615E8B" />
    </SvgIcon>
  );
}

export default AudienceIcon;
