import { SvgIcon } from '@mui/material';
import React from 'react';

function StatusPendingIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '67px', ...sx }} width="67" height="66" viewBox="0 0 67 66" fill="none" {...props}>
      <g filter="url(#filter0_f_3402_19798)">
        <rect x="15.2935" y="15" width="36" height="36" rx="18" fill="#C1D6E4" fillOpacity="0.2" />
      </g>
      <path d="M33.2935 26.5C32.0079 26.5 30.7512 26.8812 29.6823 27.5954C28.6133 28.3097 27.7802 29.3248 27.2882 30.5126C26.7963 31.7003 26.6676 33.0072 26.9184 34.2681C27.1692 35.529 27.7882 36.6872 28.6973 37.5962C29.6063 38.5052 30.7645 39.1243 32.0254 39.3751C33.2862 39.6259 34.5932 39.4972 35.7809 39.0052C36.9686 38.5132 37.9838 37.6801 38.698 36.6112C39.4122 35.5423 39.7935 34.2856 39.7935 33C39.7916 31.2767 39.1062 29.6244 37.8876 28.4059C36.669 27.1873 35.0168 26.5019 33.2935 26.5ZM33.2935 37.875C32.3293 37.875 31.3867 37.5891 30.5851 37.0534C29.7834 36.5177 29.1585 35.7564 28.7895 34.8656C28.4206 33.9748 28.324 32.9946 28.5121 32.0489C28.7002 31.1033 29.1645 30.2346 29.8463 29.5529C30.5281 28.8711 31.3967 28.4068 32.3424 28.2187C33.2881 28.0306 34.2682 28.1271 35.159 28.4961C36.0498 28.8651 36.8112 29.4899 37.3469 30.2916C37.8825 31.0933 38.1685 32.0358 38.1685 33C38.167 34.2925 37.6529 35.5316 36.739 36.4456C35.8251 37.3595 34.5859 37.8736 33.2935 37.875Z" fill="#C1D6E4" />
      <path d="M32.4811 32.4892L31.1811 33.3017C31.0907 33.3584 31.0123 33.4323 30.9504 33.5192C30.8885 33.6062 30.8443 33.7045 30.8204 33.8085C30.7965 33.9125 30.7934 34.0202 30.8111 34.1255C30.8289 34.2307 30.8672 34.3315 30.9239 34.4219C30.9805 34.5124 31.0544 34.5908 31.1414 34.6527C31.2283 34.7146 31.3266 34.7587 31.4306 34.7826C31.5347 34.8065 31.6424 34.8097 31.7476 34.7919C31.8529 34.7742 31.9536 34.7359 32.044 34.6792L33.5981 33.7042C33.754 33.6065 33.8824 33.4707 33.9713 33.3096C34.0602 33.1485 34.1066 32.9674 34.1061 32.7834V30.7109C34.1061 30.4954 34.0205 30.2888 33.8682 30.1364C33.7158 29.984 33.5091 29.8984 33.2936 29.8984C33.0782 29.8984 32.8715 29.984 32.7191 30.1364C32.5667 30.2888 32.4811 30.4954 32.4811 30.7109V32.4892Z" fill="#C1D6E4" />
      <defs>
        <filter id="filter0_f_3402_19798" x="0.293457" y="0" width="66" height="66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_3402_19798" />
        </filter>
      </defs>

    </SvgIcon>
  );
}

export default StatusPendingIcon;
