import { SvgIcon } from '@mui/material';
import React from 'react';

function HelpCircleIcon(props) {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M10 0C4.47734 0 0 4.47734 0 10C0.00585938 15.5203 4.47969 19.9941 10 20C15.5227 20 20 15.5227 20 10C20 4.47734 15.5227 0 10 0ZM10 4.1668C10.6902 4.1668 11.25 4.72617 11.25 5.4168C11.25 6.10742 10.6902 6.6668 10 6.6668C9.30977 6.6668 8.75 6.10703 8.75 5.4168C8.75 4.72656 9.30977 4.1668 10 4.1668V4.1668ZM11.6668 15C11.6668 15.4602 11.2937 15.8332 10.8332 15.8332C10.3727 15.8332 10 15.4602 10 15V10H9.1668C8.70625 10 8.3332 9.62695 8.3332 9.1668C8.3332 8.70664 8.70625 8.3332 9.1668 8.3332H10C10.9203 8.3332 11.6668 9.07969 11.6668 10V15V15Z" fill="#615E8B" />
      <path d="M10 6.66681C10.6904 6.66681 11.25 6.10717 11.25 5.41681C11.25 4.72645 10.6904 4.16681 10 4.16681C9.30964 4.16681 8.75 4.72645 8.75 5.41681C8.75 6.10717 9.30964 6.66681 10 6.66681Z" fill="white" />
      <path d="M11.6671 9.99999V15C11.6671 15.4601 11.294 15.8332 10.8335 15.8332C10.3729 15.8332 10.0003 15.4601 10.0003 15V9.99999H9.16709C8.70654 9.99999 8.3335 9.62694 8.3335 9.16678C8.3335 8.70663 8.70654 8.33319 9.16709 8.33319H10.0003C10.9206 8.33319 11.6671 9.07968 11.6671 9.99999V9.99999Z" fill="white" />
    </SvgIcon>
  );
}

export default HelpCircleIcon;
