import { SvgIcon } from '@mui/material';
import React from 'react';

function AlarmIcon({ sx, ...props }) {
  return (
    <SvgIcon width="63" height="63" viewBox="0 0 63 63" fill="none" {...props} sx={sx}>
      <g filter="url(#filter0_f_1220_4960)">
        <rect x="15.0806" y="15.7896" width="32" height="32" rx="16" fill="#FF9F43" fillOpacity="0.37" />
      </g>
      <path d="M34.5545 38.0242C34.4988 39.5837 33.4918 40.741 31.9617 41.0032C31.9376 41.0075 31.9159 41.011 31.9067 41.0369H31.1275C31.0333 40.9685 30.9174 40.9788 30.8118 40.9526C29.4571 40.6124 28.5255 39.431 28.5063 38.0263C30.5226 38.0256 32.5386 38.0252 34.5548 38.0245L34.5545 38.0242Z" fill="#FF9F43" />
      <path d="M31.6944 22.9006C32.1547 23.0665 32.3226 23.407 32.2907 23.8772C32.2776 24.0707 32.2897 24.266 32.29 24.4605C31.7844 24.4042 31.2787 24.4084 30.773 24.4587C30.773 24.2121 30.7759 23.9655 30.773 23.7192C30.7688 23.3425 30.936 23.0814 31.2865 22.9389C31.3063 22.9308 31.3226 22.9137 31.3403 22.901H31.6947L31.6944 22.9006Z" fill="#FF9F43" />
      <path d="M30.7723 24.4633C31.2779 24.413 31.7836 24.4084 32.2893 24.4651C33.0182 24.587 33.7102 24.8163 34.33 25.2238C35.949 26.2883 36.7786 27.7967 36.8193 29.7326C36.8342 30.4466 36.8137 31.1613 36.8253 31.8754C36.8491 33.359 37.4235 34.5982 38.5188 35.5943C38.7832 35.8349 39.0032 36.0957 39.0613 36.4593C39.1967 37.3073 38.595 38.0149 37.724 38.0209C36.7382 38.0277 35.7524 38.0224 34.7665 38.0227C34.6957 38.0227 34.6252 38.0266 34.5543 38.0287C32.538 38.0294 30.5221 38.0298 28.5058 38.0305C27.4736 38.0277 26.441 38.0248 25.4087 38.0231C25.2546 38.0231 25.1019 38.0142 24.9538 37.971C24.4594 37.8271 24.1352 37.5053 24.0133 37.0082C23.8889 36.5022 24.0289 36.055 24.4205 35.7113C25.2752 34.9607 25.8559 34.0479 26.1118 32.9352C26.1993 32.5547 26.2404 32.1688 26.2394 31.7779C26.2379 31.1053 26.2383 30.4324 26.2394 29.7599C26.2429 27.1777 27.9863 25.0377 30.5129 24.5144C30.5993 24.4963 30.6862 24.4807 30.773 24.4637L30.7723 24.4633Z" fill="#FF9F43" />
      <defs>
        <filter id="filter0_f_1220_4960" x="0.0805664" y="0.789551" width="62" height="62" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_1220_4960" />
        </filter>
      </defs>
    </SvgIcon>
  );
}

export default AlarmIcon;
