import { SvgIcon } from '@mui/material';
import React from 'react';

function CheckboxCircleIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '24px', ...sx }} width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx="12" cy="12" r="11.5" fill="#F5F7F9" stroke="#EBE9F1" />
    </SvgIcon>
  );
}

export default CheckboxCircleIcon;
