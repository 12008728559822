import { SvgIcon } from '@mui/material';
import React from 'react';

function HelpPageBackgroundIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '652px', ...sx }} width="652" height="654" viewBox="0 0 652 654" fill="none" {...props}>
      <circle opacity="0.2" cx="318" cy="326.5" r="311.5" fill="url(#paint0_linear_2631_15378)" />
      <g filter="url(#filter0_d_2631_15378)">
        <path d="M212.055 427.815C209.935 427.51 208.463 425.545 208.766 423.424C210.726 410.265 213.392 399.935 216.764 392.432C220.211 384.94 224.493 379.104 229.611 374.925C234.729 370.745 240.683 367.147 247.474 364.13C252.558 361.789 257.171 359.265 261.313 356.558C265.454 353.851 268.893 350.698 271.63 347.097C274.377 343.422 276.101 339.138 276.801 334.247C277.544 329.054 276.957 324.323 275.04 320.053C273.123 315.784 270.213 312.293 266.311 309.581C262.484 306.88 258.056 305.168 253.027 304.444C248.148 303.743 243.375 304.17 238.709 305.726C234.054 307.207 230.036 309.855 226.656 313.67C224.191 316.405 222.331 319.782 221.076 323.801C220.274 326.371 217.828 328.184 215.164 327.8L179.07 322.609C176.29 322.209 174.37 319.592 174.981 316.851C177.798 304.198 182.851 293.976 190.14 286.182C198.382 277.306 208.477 271.27 220.425 268.073C232.385 264.801 245.158 264.142 258.745 266.096C273.682 268.245 286.538 272.628 297.312 279.247C308.097 285.791 316.087 294.198 321.282 304.469C326.477 314.74 328.132 326.461 326.247 339.631C324.987 348.436 322.404 356.052 318.496 362.479C314.675 368.842 309.797 374.285 303.864 378.808C297.941 383.255 291.151 387.079 283.492 390.278C277.055 392.963 271.649 395.948 267.271 399.236C262.969 402.534 259.518 406.569 256.92 411.341C254.397 416.125 252.571 422.198 251.44 429.563C251.137 431.682 249.172 433.153 247.053 432.849L212.055 427.815ZM220.173 502.715C212.666 501.636 206.625 498.079 202.05 492.044C197.56 485.945 195.885 479.176 197.027 471.736C198.007 464.35 201.501 458.401 207.51 453.889C213.593 449.388 220.388 447.677 227.894 448.757C235.025 449.782 240.916 453.318 245.567 459.363C250.217 465.408 252.053 472.124 251.072 479.51C250.287 484.466 248.321 488.83 245.177 492.602C242.118 496.31 238.385 499.114 233.976 501.014C229.578 502.839 224.977 503.406 220.173 502.715Z" fill="url(#paint1_linear_2631_15378)" />
      </g>
      <g filter="url(#filter1_i_2631_15378)">
        <path fillRule="evenodd" clipRule="evenodd" d="M492.185 406.067L472.26 385.816C447.907 361.336 408.278 361.336 384.036 385.816C359.683 410.296 359.683 450.131 384.036 474.499L403.739 494.305C378.39 506.99 349.72 514 319.5 514C213.232 514 127 427.32 127 320.5C127 213.68 213.232 127 319.5 127C425.768 127 512 213.68 512 320.5C511.889 351.211 504.915 380.252 492.185 406.067Z" fill="#D8DBEA" fillOpacity="0.01" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M633.743 546.836L584.652 497.65C618.639 446.906 638.519 385.701 638.519 319.822C638.519 143.219 495.577 1.97872e-06 319.315 1.27495e-06C143.053 5.71176e-07 -5.71838e-07 143.219 -1.27697e-06 319.822C-1.9821e-06 496.426 142.942 639.645 319.204 639.645C384.622 639.645 445.264 619.948 496.021 586.23L545.335 635.639C569.769 660.12 609.531 660.12 633.854 635.639C658.067 611.157 658.067 571.318 633.743 546.836ZM492.356 405.398L472.364 385.144C447.93 360.663 408.168 360.663 383.845 385.144C359.41 409.626 359.41 449.465 383.845 473.836L403.614 493.644C378.18 506.33 349.414 513.341 319.093 513.341C212.47 513.341 125.949 426.652 125.949 319.822C125.949 212.992 212.47 126.304 319.093 126.304C425.717 126.304 512.237 212.992 512.237 319.822C512.126 350.536 505.129 379.58 492.356 405.398Z" fill="#FBFBFB" />
      <g filter="url(#filter2_d_2631_15378)">
        <path d="M595.996 250C596.038 248.241 595.727 246.506 594.926 245.043C593.489 242.42 590.852 241.147 588.31 241.02C585.768 240.893 583.268 241.75 580.817 242.599C585.001 238.375 587.331 231.443 586.816 224.752C586.299 218.061 582.954 211.812 578.2 208.665C573.19 205.347 567.127 205.506 561.601 207.09C548.654 210.802 537.266 222.221 531.563 237.213C523.294 228.331 511.799 224.341 501.05 226.622C490.3 228.904 480.503 237.412 475.043 249.206L475 250H595.996V250Z" fill="white" />
      </g>
      <g filter="url(#filter3_d_2631_15378)">
        <path d="M162 252L161.146 249.509C160.541 247.747 159.894 245.913 158.632 244.666C157.371 243.42 155.298 242.992 154.04 244.242C156.199 240.875 156.76 236.286 155.488 232.389C154.217 228.492 151.151 225.399 147.568 224.402C143.654 223.312 139.553 224.625 135.744 226.113C131.88 227.622 128.01 229.364 124.813 232.233C121.615 235.102 119.133 239.272 118.903 243.865C109.636 240.147 99.5546 239.031 89.8216 240.646C86.8796 241.134 83.8967 241.901 81.4059 243.729C78.9152 245.557 76.9802 248.63 77.0002 251.975L77.2737 252H162V252Z" fill="white" />
      </g>
      <g filter="url(#filter4_d_2631_15378)">
        <path d="M513.998 81.0713L431.014 100.608L436.255 106.074L513.998 81.0713Z" fill="#B5B5E3" />
        <path d="M514 81.0713L414.645 131.186L428.839 106.074L514 81.0713Z" fill="#DBDBFF" />
        <path d="M513.998 81.0713L445.767 87.5702L431.014 100.608L513.998 81.0713Z" fill="#DBDBFF" />
        <path d="M435.104 120.844L444.006 128.932L513.997 81.0732L435.104 120.844Z" fill="#CECEF3" />
        <path d="M435.104 120.844L438.756 124.162L513.997 81.0732L435.104 120.844Z" fill="#B5B5E3" />
      </g>
      <path d="M405.044 113C369.025 113 312.842 165.721 357.019 220.735C396.857 270.346 470.222 230.477 450.783 193.228C431.916 164.575 395.439 182.569 398.184 215.578C401.614 256.838 435.918 259.703 435.918 302.683C435.918 326.178 411.333 359.989 321 364" stroke="#BDBDFF" strokeWidth="1.5" strokeDasharray="7 7" fill="none" />
      <g filter="url(#filter5_d_2631_15378)">
        <path fillRule="evenodd" clipRule="evenodd" d="M202.829 152.379C204.342 151.735 206.086 152.437 206.731 153.95C207.375 155.464 206.672 157.208 205.159 157.852C203.645 158.496 201.901 157.794 201.256 156.281C200.612 154.767 201.315 153.023 202.829 152.379ZM211.039 148.883C212.553 148.239 214.296 148.941 214.94 150.455C215.585 151.968 214.882 153.712 213.369 154.356C211.855 155 210.112 154.298 209.467 152.784C208.823 151.27 209.525 149.527 211.039 148.883ZM219.248 145.389C220.762 144.744 222.505 145.447 223.15 146.96C223.794 148.474 223.092 150.217 221.578 150.862C220.064 151.506 218.321 150.804 217.677 149.29C217.032 147.776 217.734 146.033 219.248 145.389ZM207.339 171.47L220.399 165.911C221.63 165.386 221.634 165.395 222.409 165.58C223.186 165.765 231.88 167.488 231.88 167.488L229.55 162.015L229.865 161.881C233.474 160.345 235.232 156.384 233.791 152.997L226.186 135.132C224.744 131.745 220.652 130.244 217.051 131.777L194.525 141.366C190.925 142.899 189.169 146.889 190.61 150.276L198.215 168.141C199.657 171.528 203.739 173.002 207.339 171.47Z" fill="url(#paint2_linear_2631_15378)" />
      </g>
      <g filter="url(#filter6_d_2631_15378)">
        <path fillRule="evenodd" clipRule="evenodd" d="M509.402 326.331C511.749 327.471 512.725 330.29 511.584 332.638C510.443 334.987 507.623 335.961 505.276 334.82C502.928 333.679 501.951 330.86 503.093 328.512C504.234 326.163 507.054 325.189 509.402 326.331ZM522.141 332.52C524.489 333.661 525.464 336.48 524.322 338.828C523.181 341.176 520.363 342.151 518.015 341.01C515.666 339.869 514.691 337.05 515.833 334.702C516.974 332.354 519.792 331.379 522.141 332.52ZM534.881 338.708C537.23 339.849 538.205 342.667 537.063 345.016C535.922 347.364 533.104 348.339 530.755 347.198C528.407 346.056 527.432 343.238 528.574 340.89C529.715 338.542 532.533 337.567 534.881 338.708ZM491.247 351.615L511.505 361.461C513.416 362.389 513.409 362.403 513.995 363.524C514.583 364.645 521.574 376.849 521.574 376.849L525.7 368.359L526.19 368.597C531.787 371.317 538.361 369.298 540.914 364.044L554.382 336.332C556.935 331.078 554.47 324.615 548.884 321.901L513.941 304.919C508.357 302.205 501.75 304.259 499.197 309.512L485.729 337.225C483.176 342.478 485.663 348.901 491.247 351.615Z" fill="url(#paint3_linear_2631_15378)" />
      </g>
      <defs>
        <filter id="filter0_d_2631_15378" x="156.867" y="257" width="188.133" height="274" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.829051 0 0 0 0 0.125509 0 0 0 0 0.589847 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2631_15378" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2631_15378" result="shape" />
        </filter>
        <filter id="filter1_i_2631_15378" x="127" y="127" width="385" height="387" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2631_15378" />
        </filter>
        <filter id="filter2_d_2631_15378" x="455" y="183" width="181" height="104" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="10" dy="7" />
          <feGaussianBlur stdDeviation="15" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.110459 0 0 0 0 0.0661979 0 0 0 0 0.3875 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2631_15378" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2631_15378" result="shape" />
        </filter>
        <filter id="filter3_d_2631_15378" x="57" y="201" width="145" height="88" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="10" dy="7" />
          <feGaussianBlur stdDeviation="15" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.110459 0 0 0 0 0.0661979 0 0 0 0 0.3875 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2631_15378" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2631_15378" result="shape" />
        </filter>
        <filter id="filter4_d_2631_15378" x="397.645" y="75.0713" width="153.355" height="104.114" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="10" dy="21" />
          <feGaussianBlur stdDeviation="13.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.215408 0 0 0 0 0.174601 0 0 0 0 0.470833 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2631_15378" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2631_15378" result="shape" />
        </filter>
        <filter id="filter5_d_2631_15378" x="172.106" y="123.177" width="80.1882" height="76.8984" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.829051 0 0 0 0 0.125509 0 0 0 0 0.589847 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2631_15378" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2631_15378" result="shape" />
        </filter>
        <filter id="filter6_d_2631_15378" x="466.725" y="295.722" width="106.665" height="109.127" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.829051 0 0 0 0 0.125509 0 0 0 0 0.589847 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2631_15378" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2631_15378" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_2631_15378" x1="208.975" y1="-711.833" x2="1441.09" y2="-187.192" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFEFEF" />
          <stop offset="1" stopColor="#B7B7D1" />
        </linearGradient>
        <linearGradient id="paint1_linear_2631_15378" x1="341.987" y1="294.253" x2="225.131" y2="493.331" gradientUnits="userSpaceOnUse">
          <stop stopColor="#108AE2" />
          <stop offset="1" stopColor="#FF53A5" />
        </linearGradient>
        <linearGradient id="paint2_linear_2631_15378" x1="228.922" y1="131.889" x2="230.614" y2="172.54" gradientUnits="userSpaceOnUse">
          <stop stopColor="#108AE2" />
          <stop offset="1" stopColor="#FF53A5" />
        </linearGradient>
        <linearGradient id="paint3_linear_2631_15378" x1="561.114" y1="336.229" x2="514.209" y2="380.595" gradientUnits="userSpaceOnUse">
          <stop stopColor="#108AE2" />
          <stop offset="1" stopColor="#FF53A5" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}

export default HelpPageBackgroundIcon;
