import { SvgIcon } from '@mui/material';
import React from 'react';

function CameraIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '25px', ...sx }} width="25" height="25" viewBox="0 0 25 25" fill="none" {...props}>
      <circle cx="12.5" cy="12.5" r="12.5" fill="#FBE4EF" />
      <g clipPath="url(#clip0_3242_19895)">
        <path d="M12.9829 15.3546C14.4486 15.3546 15.6368 14.1664 15.6368 12.7007C15.6368 11.235 14.4486 10.0469 12.9829 10.0469C11.5173 10.0469 10.3291 11.235 10.3291 12.7007C10.3291 14.1664 11.5173 15.3546 12.9829 15.3546Z" fill="#108AE2" />
        <path d="M17.6554 7.67909H15.5785L15.2323 7.10216C15.0593 6.8137 14.7708 6.64062 14.4247 6.64062H11.54C11.1939 6.64062 10.9054 6.8137 10.7323 7.10216L10.3862 7.67909H8.30927C7.21312 7.67909 6.29004 8.60216 6.29004 9.69832V15.756C6.29004 16.8522 7.21312 17.7752 8.30927 17.7752H17.7131C18.8093 17.7752 19.7323 16.8522 19.7323 15.756V9.69832C19.6747 8.54447 18.8093 7.67909 17.6554 7.67909ZM12.9823 16.1022C11.0785 16.1022 9.5785 14.6022 9.5785 12.6983C9.5785 10.8522 11.0785 9.29447 12.9823 9.29447C14.8862 9.29447 16.3862 10.7945 16.3862 12.6983C16.3862 14.6022 14.8862 16.1022 12.9823 16.1022ZM16.9631 10.2752C16.6747 10.2752 16.4439 10.0445 16.4439 9.75601C16.4439 9.46755 16.6747 9.23678 16.9631 9.23678C17.2516 9.23678 17.4823 9.46755 17.4823 9.75601C17.4823 10.0445 17.2516 10.2752 16.9631 10.2752Z" fill="#108AE2" />
        <path d="M9.46297 6.8125H8.4822C8.13604 6.8125 7.90527 7.10096 7.90527 7.38942H10.0399C10.0399 7.10096 9.75143 6.8125 9.46297 6.8125Z" fill="#108AE2" />
      </g>
      <defs>
        <clipPath id="clip0_3242_19895">
          <rect width="14.4231" height="11.5385" fill="white" transform="translate(5.76953 6.41016)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default CameraIcon;
