import { createTheme } from '@mui/material';

export const datonicsTheme = createTheme({
  palette: {
    text: {
      default: '#6E6B7B',
      primary: '#5E5873',
      secondary: '#B9B9C3',
      lightGrey: '#F5F7FA',
      pink: '#D32096',
      grey: '#D4DDEA',
      white: '#FFFFFF',
      yellow: '#FF9F43',
    },
    primary: {
      main: '#E01F7B',
      mainBackground: '#E01F7B1A',
      background: '#0E0E3F',
      light: 'rgba(224, 31, 123, 0.05)',
      dark: 'rgba(224, 31, 123, 0.05)',
      black: '#131330',
      purple: '#8373E7',
      red: '#F43F5E',
      redSecondary: '#E02037',
      redError: '#E90D41',
      yellow: '#FFAC2F',
      orange: '#FF795C',
      green: '#63D4A0',
      greenLight: '#B1EF9C',
      greenHover: '#63d4a014',
      pink: '#EC317F',
      pinkLight: '#FFA5E0',
      pinkBackground: '#FFDAF2',
      pinkHover: 'rgba(224,31,123,.12)',
      purpleBackground: 'rgba(139, 139, 233, 0.13)',
      blue: '#42B0FF',
      blueLight: '#7DE1EE',
      blueDark: '#5F82FF',
      grey: '#C1D6E4',
      greyLight: '#EBE9F1',
      greyIcons: '#615E8B',
      greyBackground: '#D9D9D9',
      hover: '#F5F7F9',
      placeholder: '#F9FAFC',
      border: '#D3D0D9',
      icon: '#DDEEFF',
      boxShadow: '#E9EBF1',
      white: '#fff',
    },
  },
  shadows: [
    'inset 0px 2px 27px -4px #E9EBF1',
    '-22px 18px 20px -20px rgba(80, 93, 111, 0.2)',
    '0px 5px 10px #E9EBF1',
    '0px -7px 20px -13px  rgba(80, 93, 111, 0.2)',
    'inset 0px 2px 27px -4px #E9EBF1',
    '',
  ],
  typography: {
    fontFamily: '"Inter", Arial, sans-serif',
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '128.91%',
          color: '#6E6B7B',
          '&.Mui-selected, &:hover, &.Mui-selected:hover': {
            backgroundColor: '#FFDAF2',
            color: '#E01F7B',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          span: {
            svg: {
              '&:first-of-type': {
                fontSize: '20px',
              },
              '&:last-of-type': {
                fontSize: '26px',
                top: '-3px',
                left: '-3px',
              },
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#fff',
          backgroundColor: '#E01F7B',
          boxShadow: 'none',
          height: '40px',
          borderRadius: '5px',
          padding: '12px 15px',
          textTransform: 'initial',
          fontSize: '13px',
          lineHeight: '16px',
          cursor: 'pointer',
          boxSizing: 'border-box',

          '&:hover': {
            backgroundColor: '#E01F7B',
            path: {
              fill: '#fff',
            },
          },
          '&.Mui-disabled': {
            backgroundColor: '#D3D0D9',
            color: '#fff',
          },
        },
        outlined: {
          backgroundColor: '#fff',
          color: '#E01F7B',
          border: '1px solid #E01F7B',

          '&:hover': {
            color: '#fff',
            path: {
              fill: '#E01F7B',
            },
          },
          '&.Mui-disabled': {
            borderColor: '#FFA5E0',
            color: '#FFA5E0',
            backgroundColor: '#fff',

            path: {
              fill: '#FFA5E0',
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '40px',
          padding: '15px 11px 11px 11px',
          boxSizing: 'border-box',
          '&::placeholder': {
            color: '#D3D0D9',
            fontSize: '14px',
            opacity: 1,
          },
        },
        adornedEnd: {
          paddingRight: '9px',
        },
        multiline: {
          minHeight: '80px',
          padding: '11px',
          textarea: {
            mb: 'auto',
            padding: 0,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          boxSizing: 'border-box',
          height: '40px',
          minHeight: '40px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          top: 0,
          borderColor: '#D3D0D9',
        },
        root: {
          color: '#6E6B7B',
          fontSize: '13px',
          height: '45px',
          marginTop: '-5px',
          borderRadius: '5px',
        },
        input: {
          padding: '14px 15px 11px 15px',
          height: '20px',
          boxSizing: 'content-box',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: '#5E5873',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '128%',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          width: '33px',
          height: '18px',
        },
        switchBase: {
          padding: 0,
          width: '33px',
          height: '18px',
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(14px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#E01F7B',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: '#D3D0D9',
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: '#D3D0D9',
          },
          '&:hover': {
            backgroundColor: 'initial',
          },
        },
        thumb: {
          boxSizing: 'border-box',
          backgroundColor: '#fff',
          width: '14px',
          height: '14px',
          marginTop: '-4px',
          marginLeft: '-18px',
        },
        track: {
          borderRadius: '117px',
          width: '33px',
          height: '18px',
          backgroundColor: '#D3D0D9',
          opacity: 1,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#fff',
          fontSize: '10px',
          lineHeight: '128.91%',
          color: '#5E5873',
          padding: '12px',
          borderRadius: '4px',
          border: '1px solid #EBE9F1',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        },
        arrow: {
          '&::before': {
            color: '#fff',
            border: '1px solid #EBE9F1',
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            path: {
              fill: '#E01F7B',
            },
          },
          '& .MuiPickersDay-root.Mui-selected': {
            borderRadius: '50%',
            backgroundColor: 'rgba(224, 31, 123, 0.05)',
            color: '#E01F7B',

            '&:hover, &:focused': {
              backgroundColor: 'rgba(224, 31, 123, 0.05)',
            },
          },
        },
      },
    },
  },
});
