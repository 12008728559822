import { SvgIcon } from '@mui/material';
import React from 'react';

function AttributableMatchesIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '30px', ...sx }} width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <rect x="7.5" y="16.25" width="5" height="5" rx="2.5" transform="rotate(90 7.5 16.25)" fill="#108AE2" stroke="#108AE2" strokeWidth="2" />
      <rect x="21.25" y="15" width="5" height="5" rx="2.5" transform="rotate(-90 21.25 15)" fill="#108AE2" stroke="#108AE2" strokeWidth="2" />
      <path d="M22.5 13.75L20.3284 15.9216C18.9951 17.2549 18.3284 17.9216 17.5 17.9216C16.6716 17.9216 16.0049 17.2549 14.6716 15.9216L14.0784 15.3284C12.7451 13.9951 12.0784 13.3284 11.25 13.3284C10.4216 13.3284 9.75491 13.9951 8.42157 15.3284L6.25 17.5" stroke="#108AE2" strokeWidth="2" fill="none" />
    </SvgIcon>
  );
}

export default AttributableMatchesIcon;
