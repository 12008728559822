import { SvgIcon } from '@mui/material';
import React from 'react';

function BellStatusIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '66px', ...sx }} width="66" height="66" viewBox="0 0 66 66" fill="none" {...props}>
      <g filter="url(#filter0_f_551_16186)">
        <rect x="15" y="15" width="36" height="36" rx="18" fill="#3ADB90" fillOpacity="0.12" />
      </g>
      <path d="M36.9086 40.014C36.846 41.7685 35.7131 43.0705 33.9917 43.3655C33.9646 43.3703 33.9403 43.3743 33.9299 43.4034H33.0533C32.9473 43.3264 32.8169 43.338 32.6981 43.3085C31.1741 42.9258 30.126 41.5967 30.1045 40.0164C32.3728 40.0156 34.6407 40.0152 36.909 40.0144L36.9086 40.014Z" fill="#63D4A0" />
      <path d="M33.6904 23C34.2082 23.1866 34.3972 23.5697 34.3613 24.0987C34.3466 24.3163 34.3601 24.536 34.3605 24.7549C33.7916 24.6915 33.2228 24.6963 32.6539 24.7529C32.6539 24.4754 32.6571 24.1979 32.6539 23.9209C32.6491 23.4971 32.8373 23.2033 33.2315 23.0431C33.2539 23.0339 33.2722 23.0148 33.2921 23.0004H33.6908L33.6904 23Z" fill="#63D4A0" />
      <path d="M32.6534 24.758C33.2223 24.7014 33.7911 24.6962 34.36 24.76C35.18 24.8971 35.9586 25.155 36.6558 25.6135C38.4773 26.811 39.4105 28.5081 39.4563 30.6859C39.4731 31.4892 39.45 32.2932 39.4631 33.0965C39.4898 34.7657 40.136 36.1597 41.3683 37.2803C41.6657 37.551 41.9132 37.8444 41.9786 38.2534C42.1309 39.2074 41.454 40.0035 40.4741 40.0103C39.3651 40.0179 38.256 40.0119 37.147 40.0123C37.0672 40.0123 36.9879 40.0167 36.9082 40.019C34.6399 40.0198 32.372 40.0202 30.1036 40.021C28.9424 40.0179 27.7807 40.0147 26.6194 40.0127C26.446 40.0127 26.2742 40.0027 26.1076 39.9541C25.5515 39.7922 25.1867 39.4302 25.0496 38.8709C24.9096 38.3017 25.0671 37.7986 25.5076 37.4119C26.4692 36.5675 27.1225 35.5406 27.4104 34.2889C27.5088 33.8607 27.5551 33.4266 27.5539 32.9869C27.5523 32.2302 27.5527 31.4732 27.5539 30.7166C27.5579 27.8116 29.5192 25.4042 32.3616 24.8154C32.4589 24.795 32.5565 24.7775 32.6542 24.7584L32.6534 24.758Z" fill="#63D4A0" />
      <defs>
        <filter id="filter0_f_551_16186" x="0" y="0" width="66" height="66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_551_16186" />
        </filter>
      </defs>
    </SvgIcon>
  );
}

export default BellStatusIcon;
