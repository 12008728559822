import { SvgIcon } from '@mui/material';
import React from 'react';

function EmptyDataDatonics({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '372px', ...sx }} width="372" height="330" viewBox="0 0 372 330" fill="none" {...props}>
      <g style={{ mixBlendMode: 'multiply' }}>
        <rect x="119.921" y="69.9592" width="126.719" height="126.719" stroke="#F5F7F9" strokeWidth="2" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <rect x="0.246582" y="305.843" width="61.1058" height="61.1058" fill="#F5F7F9" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <rect x="82.3149" y="32.3979" width="36.6058" height="36.6058" fill="#EAEEF1" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <rect x="591.394" y="350.898" width="36.6058" height="36.6058" fill="#EAEEF1" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <rect x="558.292" y="0.260864" width="15.2741" height="15.2741" fill="#E5E8EC" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <rect x="247.64" y="197.679" width="128.719" height="128.719" fill="#F5F7F9" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <rect x="378.36" y="70.9592" width="124.719" height="124.719" stroke="#F5F7F9" strokeWidth="4" />
      </g>
      <path d="M439.55 190.7L439.132 190.283L429.235 200.18L419.756 190.7L429.235 181.222L439.132 191.118L439.55 190.7L439.968 190.283L429.235 179.55L418.084 190.7L429.235 201.851L440.385 190.7L439.968 190.283L439.55 190.7Z" fill="#E01F7B" />
      <path d="M161.524 15.5347L161.272 15.2834L155.312 21.2441L149.602 15.5347L155.312 9.82601L161.272 15.7867L161.524 15.5347L161.776 15.2834L155.312 8.8194L148.596 15.5347L155.312 22.2507L162.027 15.5347L161.776 15.2834L161.524 15.5347Z" fill="#E01F7B" />
      <path d="M142.663 362.484L142.041 361.862L127.285 376.618L113.152 362.484L127.285 348.352L142.041 363.108L142.663 362.484L143.287 361.862L127.285 345.861L110.66 362.484L127.285 379.11L143.909 362.484L143.287 361.862L142.663 362.484Z" fill="#E01F7B" />
      <path d="M385.87 93.3044L393.963 101.396L394.799 100.561L386.706 92.4688" fill="#E01F7B" />
      <path d="M393.963 92.4688L385.87 100.561L386.706 101.396L394.799 93.3044" fill="#E01F7B" />
      <path d="M543.247 244.291L551.34 252.383L552.176 251.547L544.083 243.455" fill="#E01F7B" />
      <path d="M551.34 243.455L543.247 251.547L544.083 252.383L552.176 244.291" fill="#E01F7B" />
      <path d="M133.998 42.6468L151.964 60.6101L153.819 58.755L135.853 40.7917" fill="#E01F7B" />
      <path d="M151.964 40.7917L133.998 58.755L135.853 60.6101L153.819 42.6468" fill="#E01F7B" />
      <path d="M226.107 327.554L237.306 338.751L238.462 337.595L227.264 326.398" fill="#E01F7B" />
      <path d="M237.306 326.398L226.107 337.595L227.264 338.751L238.462 327.554" fill="#E01F7B" />
      <path d="M394.799 281.287H234.198L166.824 45.4985H327.425L394.799 281.287Z" fill="#E1E7EB" />
      <path d="M242.934 119.035H200.507L182.708 56.744H225.136L242.934 119.035Z" fill="#F5F7F9" />
      <path d="M315.028 64.7544H240.688L238.536 56.744H312.878L315.028 64.7544Z" fill="#F5F7F9" />
      <path d="M319.766 80.9951H245.424L243.274 72.9846H317.614L319.766 80.9951Z" fill="#F5F7F9" />
      <path d="M324.502 97.2358H250.161L248.01 89.2265H322.352L324.502 97.2358Z" fill="#F5F7F9" />
      <path d="M329.24 113.478H254.898L252.748 105.467H327.089L329.24 113.478Z" fill="#BCC2E7" />
      <path d="M333.976 129.718H259.635L257.484 121.708H331.826L333.976 129.718Z" fill="#BCC2E7" />
      <path d="M338.713 145.959H264.371L262.221 137.949H336.562L338.713 145.959Z" fill="#BCC2E7" />
      <path d="M440.052 315.423L282.625 318.323L215.251 100.606H375.853L440.052 315.423Z" fill="white" />
      <path d="M409.615 102.195C409.752 102.035 409.897 101.881 410.044 101.731C410.107 101.726 410.168 101.721 410.231 101.717C410.021 101.868 409.816 102.028 409.615 102.195Z" fill="#80B6E6" />
      <path d="M301.032 190.372L351.653 226.196L354.271 222.496L303.651 186.672" fill="#E01F7B" />
      <path d="M337.719 186.672L312.358 222.496L317.585 226.196L342.946 190.372" fill="#E01F7B" />
    </SvgIcon>
  );
}

export default EmptyDataDatonics;
