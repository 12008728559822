export const errorAlertSx = {
  g: {
    '& > rect': {
      fill: (theme) => theme.palette.primary.red,
    },
  },
  path: {
    fill: (theme) => theme.palette.primary.redSecondary,
  },
};

export const pendingAlertSx = {
  g: {
    '& > rect': {
      fill: (theme) => theme.palette.primary.grey,
    },
  },
  path: {
    fill: (theme) => theme.palette.primary.grey,
  },
};

export const utilizeAlertSx = {
  g: {
    '& > rect': {
      fill: (theme) => theme.palette.primary.blue,
    },
  },
  path: {
    fill: (theme) => theme.palette.primary.blue,
  },
};

export const warningAlertSx = {
  g: {
    '& > rect': {
      fill: (theme) => theme.palette.text.yellow,
    },
  },
  path: {
    fill: (theme) => theme.palette.primary.yellow,
  },
};
