import { SvgIcon } from '@mui/material';
import React from 'react';

function FilterIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '13px', ...sx }} width="13" height="12" viewBox="0 0 13 12" fill="none" {...props}>
      <path d="M12.9135 0.542228C12.7598 0.207978 12.4344 0 12.0644 0H0.935725C0.565729 0 0.2403 0.207978 0.0861734 0.542228C-0.0665602 0.873692 -0.0136374 1.25251 0.224051 1.53012L4.64311 6.67153V10.6774C4.64311 10.8487 4.73735 11.0061 4.88823 11.0869C4.95693 11.1236 5.03214 11.1417 5.10735 11.1417C5.19741 11.1417 5.28701 11.1152 5.365 11.0637L8.15041 9.20673C8.27947 9.12038 8.357 8.97553 8.357 8.82048V6.67153L12.7761 1.53059C13.0137 1.25251 13.0667 0.873692 12.9135 0.542228Z" fill="#131330" />
    </SvgIcon>
  );
}

export default FilterIcon;
