import { SvgIcon } from '@mui/material';
import React from 'react';

function ArrowStatisticIcon(props) {
  return (
    <SvgIcon width="12" height="15" viewBox="0 0 12 15" fill="none" {...props}>
      <path d="M5.6048 2.00015L5.60474 13.2096" stroke="#EC317F" strokeWidth="2.5997" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.84758 8.96823L5.60543 13.2104L1.36328 8.96823" stroke="#EC317F" strokeWidth="2.5997" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export default ArrowStatisticIcon;
