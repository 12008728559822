import { SvgIcon } from '@mui/material';
import React from 'react';

function RawReportIcon(props) {
  return (
    <SvgIcon width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
      <path d="M9.54467 2.42326L9.53443 1.63439H8.74555C8.69045 1.63387 8.63534 1.63387 8.58023 1.63439C3.80095 1.68004 -0.0364525 5.59144 0.00919207 10.3707C0.0548366 15.15 3.96624 18.9874 8.74552 18.9418C13.5226 18.9365 17.3939 15.0655 17.3996 10.2885V9.49961H9.53443L9.54467 2.42326Z" fill="#615E8B" />
      <path d="M11.1274 0.0589142V7.92332H18.9911C18.9708 3.58868 15.4621 0.0796583 11.1274 0.0589142Z" fill="white" />
    </SvgIcon>
  );
}

export default RawReportIcon;
