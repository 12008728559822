import { SvgIcon } from '@mui/material';
import React from 'react';

function StatusErrorIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '67px', ...sx }} width="67" height="66" viewBox="0 0 67 66" fill="none" {...props}>
      <g filter="url(#filter0_f_3402_19776)">
        <rect x="15.2935" y="15" width="36" height="36" rx="18" fill="#F43F5E" fillOpacity="0.12" />
      </g>
      <path d="M37.6923 36.0936L35.0996 33.5008L37.6923 30.9081C37.8642 30.7362 37.9608 30.503 37.9608 30.2599C37.9608 30.0168 37.8642 29.7837 37.6923 29.6117C37.5204 29.4398 37.2872 29.3433 37.0441 29.3433C36.801 29.3433 36.5679 29.4398 36.3959 29.6117L33.8032 32.2045L31.2105 29.6117C31.0386 29.4398 30.8054 29.3433 30.5623 29.3433C30.3192 29.3433 30.086 29.4398 29.9141 29.6117C29.7422 29.7837 29.6456 30.0168 29.6456 30.2599C29.6456 30.503 29.7422 30.7362 29.9141 30.9081L32.5069 33.5008L29.9141 36.0936C29.7422 36.2655 29.6456 36.4986 29.6456 36.7417C29.6456 36.9849 29.7422 37.218 29.9141 37.3899C30.086 37.5618 30.3192 37.6584 30.5623 37.6584C30.8054 37.6584 31.0386 37.5618 31.2105 37.3899L33.8032 34.7972L36.3959 37.3899C36.5679 37.5618 36.801 37.6584 37.0441 37.6584C37.2872 37.6584 37.5204 37.5618 37.6923 37.3899C37.8642 37.218 37.9608 36.9849 37.9608 36.7417C37.9608 36.4986 37.8642 36.2655 37.6923 36.0936Z" fill="#E90D41" />
      <defs>
        <filter id="filter0_f_3402_19776" x="0.293457" y="0" width="66" height="66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_3402_19776" />
        </filter>
      </defs>

    </SvgIcon>
  );
}

export default StatusErrorIcon;
