import { SvgIcon } from '@mui/material';
import React from 'react';

function UploadIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '18px', ...sx }} width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path d="M9 3.75L8.29289 3.04289L9 2.33579L9.70711 3.04289L9 3.75ZM10 10.5C10 11.0523 9.55229 11.5 9 11.5C8.44772 11.5 8 11.0523 8 10.5L10 10.5ZM4.54289 6.79289L8.29289 3.04289L9.70711 4.45711L5.95711 8.20711L4.54289 6.79289ZM9.70711 3.04289L13.4571 6.79289L12.0429 8.20711L8.29289 4.45711L9.70711 3.04289ZM10 3.75L10 10.5L8 10.5L8 3.75L10 3.75Z" fill="white" />
      <path d="M3.75 12L3.75 12.75C3.75 13.5784 4.42157 14.25 5.25 14.25L12.75 14.25C13.5784 14.25 14.25 13.5784 14.25 12.75V12" stroke="white" strokeWidth="2" />
    </SvgIcon>
  );
}

export default UploadIcon;
