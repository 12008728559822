import { SvgIcon } from '@mui/material';
import React from 'react';

function AboutIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '24px', ...sx }} width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx="12" cy="12" r="9" stroke="#EC317F" strokeWidth="2" fill="#fff" />
      <path d="M12.5 7.5C12.5 7.77614 12.2761 8 12 8C11.7239 8 11.5 7.77614 11.5 7.5C11.5 7.22386 11.7239 7 12 7C12.2761 7 12.5 7.22386 12.5 7.5Z" fill="#EC317F" stroke="#EC317F" />
      <path d="M12 17V10" stroke="#EC317F" strokeWidth="2" />
    </SvgIcon>
  );
}

export default AboutIcon;
