import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from '../services/Http';
import { exportToCsv } from '../utility';
import { EXPORT_KEYS_INSIGHTS } from '../constants/insights';
import {
  toastSuccess,
} from '../utility/toast';

const initialState = {
  data: [],
  charts: [],
  segments: [],
  customers: [],
};

export const getCustomers = createAsyncThunk(
  'insights/customers',
  async (partner_id) => {
    const { data } = await http.get('dashboard/performance/customers', {
      params: {
        partner_id,
      },
    });

    return data;
  },
);

export const getInsights = createAsyncThunk(
  'insights',
  async (params) => {
    const { data } = await http.get('/insights', { params });

    return data;
  },
);

export const deleteInsights = createAsyncThunk(
  'insights/delete',
  async (id) => {
    const { data } = await http.delete(`/insights/${id}`);

    return data;
  },
);

export const createInsight = createAsyncThunk(
  'insights/create',
  async ({ refreshInsights, ...values }) => {
    const { data } = await http.post('/insights', values);
    refreshInsights();

    return data;
  },
);

export const sendGetSimilarRequest = createAsyncThunk(
  'insights/send-get-similar-request',
  async (params) => {
    const { data } = await http.post('/insights/send-get-similar-request', params);
    toastSuccess('Successfully Sent!');
    return data;
  },
);

export const getInsightsCharts = createAsyncThunk(
  'insights/charts',
  async (params) => {
    const { data } = await http.get('/insights/charts', { params });

    return data;
  },
);

export const getInsightsChartsCsv = createAsyncThunk(
  'insights/charts/csv',
  async (params) => {
    const { data } = await http.get('/insights/charts', { params: { ...params, isExport: true } });

    exportToCsv({ name: 'insights.csv', data, keys: EXPORT_KEYS_INSIGHTS });
    return data;
  },
);

export const getInsightsSegments = createAsyncThunk(
  'insights/segments',
  async (params) => {
    const { data } = await http.get('/insights/segments', { params });

    return data;
  },
);

export const insightsSlice = createSlice({
  name: 'insights',
  extraReducers: {
    [getInsights.pending]: (state) => {
      state.loading = true;
    },
    [getCustomers.fulfilled]: (state, { payload }) => {
      state.customers = payload;
    },
    [getInsights.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [getInsightsChartsCsv.pending]: (state) => {
      state.loading = true;
    },
    [getInsightsChartsCsv.fulfilled]: (state) => {
      state.loading = false;
    },
    [getInsightsSegments.pending]: (state) => {
      state.loading = true;
    },
    [getInsightsSegments.fulfilled]: (state, { payload }) => {
      state.segments = payload;
      state.loading = false;
    },
    [getInsightsCharts.pending]: (state) => {
      state.loading = true;
    },
    [getInsightsCharts.fulfilled]: (state, { payload }) => {
      state.charts = payload;
      state.loading = false;
    },
  },
  reducers: {
    resetInsightsReducer: () => initialState,
    clearInsights: (state) => {
      state.data = [];
    },
  },
  initialState,
});

export const { resetInsightsReducer, clearInsights } = insightsSlice.actions;
export default insightsSlice.reducer;
