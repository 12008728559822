import { SvgIcon } from '@mui/material';
import React from 'react';

function SearchIcon(props) {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" fill="none" stroke="none" {...props}>
      <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke="#D3D0D9" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M12.5 12.5L9.5 9.5" stroke="#D3D0D9" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export default SearchIcon;
