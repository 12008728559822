import { SvgIcon } from '@mui/material';
import React from 'react';

function CampaignStatusesIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '35px', ...sx }} width="27" height="35" viewBox="0 0 27 35" fill="none" {...props}>
      <g filter="url(#filter0_d_1921_11809)">
        <rect width="26.76" height="26.76" rx="4" fill="#108AE2" />
      </g>
      <path d="M16.1065 18.0109C16.0586 19.3525 15.1922 20.3481 13.8759 20.5737C13.8552 20.5774 13.8366 20.5804 13.8287 20.6027H13.1583C13.0772 20.5439 12.9775 20.5527 12.8867 20.5301C11.7212 20.2375 10.9198 19.2211 10.9033 18.0127C12.6379 18.0121 14.3722 18.0118 16.1068 18.0112L16.1065 18.0109Z" fill="white" />
      <path d="M13.6457 5C14.0417 5.14267 14.1862 5.43563 14.1588 5.84016C14.1475 6.00661 14.1579 6.17458 14.1582 6.34195C13.7231 6.29348 13.2881 6.29713 12.8531 6.34042C12.8531 6.12825 12.8555 5.91607 12.8531 5.7042C12.8494 5.38015 12.9933 5.15547 13.2948 5.03292C13.3119 5.02591 13.3259 5.01128 13.3412 5.0003H13.646L13.6457 5Z" fill="white" />
      <path d="M12.8526 6.34431C13.2876 6.30103 13.7226 6.29706 14.1577 6.34584C14.7847 6.45071 15.3801 6.64794 15.9133 6.99852C17.3061 7.91429 18.0198 9.21203 18.0549 10.8774C18.0677 11.4917 18.05 12.1066 18.06 12.7208C18.0805 13.9973 18.5746 15.0633 19.5169 15.9202C19.7443 16.1272 19.9336 16.3516 19.9836 16.6644C20.1001 17.3939 19.5825 18.0027 18.8331 18.0078C17.985 18.0136 17.137 18.0091 16.2889 18.0094C16.2279 18.0094 16.1672 18.0127 16.1063 18.0146C14.3717 18.0152 12.6374 18.0155 10.9028 18.0161C10.0148 18.0136 9.12643 18.0112 8.2384 18.0097C8.10579 18.0097 7.9744 18.0021 7.84697 17.9649C7.42171 17.8411 7.14277 17.5643 7.0379 17.1366C6.9309 16.7013 7.05132 16.3165 7.38818 16.0208C8.12347 15.3752 8.62312 14.5899 8.84322 13.6327C8.91852 13.3052 8.95388 12.9733 8.95297 12.637C8.95175 12.0584 8.95205 11.4795 8.95297 10.9009C8.95602 8.67946 10.4559 6.83848 12.6294 6.38821C12.7038 6.37267 12.7785 6.35925 12.8532 6.34462L12.8526 6.34431Z" fill="white" />
      <defs>
        <filter id="filter0_d_1921_11809" x="0" y="0" width="26.7598" height="34.76" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="5" operator="erode" in="SourceAlpha" result="effect1_dropShadow_1921_11809" />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.845788 0 0 0 0 0.737242 0 0 0 0 0.883218 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1921_11809" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1921_11809" result="shape" />
        </filter>
      </defs>
    </SvgIcon>
  );
}

export default CampaignStatusesIcon;
