import { SvgIcon } from '@mui/material';
import React from 'react';

function LinkIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '24px', ...sx }} width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M17.2994 13.3499C17.037 13.3488 16.7856 13.2447 16.5994 13.0599C16.4131 12.8726 16.3086 12.6191 16.3086 12.3549C16.3086 12.0907 16.4131 11.8373 16.5994 11.6499L18.7194 9.52992C18.9053 9.34417 19.0528 9.12359 19.1535 8.8808C19.2541 8.638 19.3059 8.37775 19.3059 8.11492C19.3059 7.85209 19.2541 7.59183 19.1535 7.34904C19.0528 7.10624 18.9053 6.88566 18.7194 6.69992L17.2994 5.27992C16.9169 4.91844 16.4106 4.71704 15.8844 4.71704C15.3581 4.71704 14.8518 4.91844 14.4694 5.27992L12.3494 7.39992C12.1577 7.55329 11.9163 7.63079 11.6711 7.61765C11.426 7.60452 11.1942 7.50168 11.02 7.3287C10.8458 7.15573 10.7413 6.92473 10.7264 6.67969C10.7116 6.43464 10.7874 6.19269 10.9394 5.99992L13.0594 3.87992C13.822 3.15244 14.8354 2.74658 15.8894 2.74658C16.9433 2.74658 17.9567 3.15244 18.7194 3.87992L20.1294 5.28992C20.5013 5.66141 20.7963 6.10256 20.9976 6.58816C21.1989 7.07375 21.3025 7.59425 21.3025 8.11992C21.3025 8.64558 21.1989 9.16609 20.9976 9.65168C20.7963 10.1373 20.5013 10.5784 20.1294 10.9499L17.9994 13.0599C17.8131 13.2447 17.5617 13.3488 17.2994 13.3499Z" fill="#D3D0D9" />
      <path d="M8.11041 21.3001C7.58497 21.3005 7.06459 21.1974 6.57901 20.9966C6.09343 20.7959 5.65216 20.5014 5.28041 20.1301L3.87041 18.7201C3.49851 18.3486 3.20347 17.9074 3.00217 17.4218C2.80088 16.9363 2.69727 16.4157 2.69727 15.8901C2.69727 15.3644 2.80088 14.8439 3.00217 14.3583C3.20347 13.8727 3.49851 13.4316 3.87041 13.0601L6.00041 10.9401C6.19319 10.7881 6.43514 10.7123 6.68018 10.7272C6.92522 10.7421 7.15623 10.8466 7.3292 11.0208C7.50217 11.195 7.60502 11.4267 7.61815 11.6718C7.63128 11.917 7.55378 12.1584 7.40041 12.3501L5.28041 14.4701C5.09446 14.6558 4.94694 14.8764 4.84629 15.1192C4.74564 15.362 4.69384 15.6223 4.69384 15.8851C4.69384 16.1479 4.74564 16.4082 4.84629 16.651C4.94694 16.8938 5.09446 17.1143 5.28041 17.3001L6.70041 18.7201C7.08287 19.0816 7.58916 19.283 8.11541 19.283C8.64166 19.283 9.14796 19.0816 9.53041 18.7201L11.6504 16.6001C11.7377 16.4894 11.8473 16.3984 11.9722 16.3331C12.0971 16.2678 12.2344 16.2296 12.3751 16.2211C12.5157 16.2126 12.6566 16.2338 12.7885 16.2836C12.9204 16.3333 13.0402 16.4103 13.1403 16.5096C13.2403 16.6089 13.3182 16.7283 13.3688 16.8598C13.4195 16.9913 13.4418 17.132 13.4342 17.2727C13.4267 17.4135 13.3895 17.551 13.3251 17.6764C13.2606 17.8017 13.1705 17.912 13.0604 18.0001L10.9404 20.1201C10.5695 20.4932 10.1286 20.7895 9.64296 20.992C9.15735 21.1945 8.63655 21.2992 8.11041 21.3001Z" fill="#D3D0D9" />
      <path d="M8.82025 16.1799C8.68864 16.1806 8.55818 16.1554 8.43634 16.1057C8.31451 16.0559 8.20369 15.9826 8.11025 15.8899C8.01652 15.7969 7.94213 15.6863 7.89136 15.5645C7.84059 15.4426 7.81445 15.3119 7.81445 15.1799C7.81445 15.0479 7.84059 14.9172 7.89136 14.7953C7.94213 14.6734 8.01652 14.5628 8.11025 14.4699L14.4803 8.10988C14.6676 7.92363 14.9211 7.81909 15.1853 7.81909C15.4494 7.81909 15.7029 7.92363 15.8903 8.10988C15.984 8.20285 16.0584 8.31345 16.1091 8.43531C16.1599 8.55717 16.1861 8.68787 16.1861 8.81988C16.1861 8.9519 16.1599 9.0826 16.1091 9.20446C16.0584 9.32632 15.984 9.43692 15.8903 9.52988L9.52025 15.8899C9.33399 16.0746 9.08259 16.1788 8.82025 16.1799Z" fill="#D3D0D9" />
    </SvgIcon>
  );
}

export default LinkIcon;
