import { SvgIcon } from '@mui/material';
import React from 'react';

const CalendarIcon = ({ sx, ...props }) => (
  <SvgIcon sx={{ fontSize: '19px', ...sx }} width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
    <path d="M9.6875 12.6328C10.3218 12.6328 10.8359 12.1186 10.8359 11.4844C10.8359 10.8501 10.3218 10.3359 9.6875 10.3359C9.05324 10.3359 8.53906 10.8501 8.53906 11.4844C8.53906 12.1186 9.05324 12.6328 9.6875 12.6328Z" fill="white"/>
    <path d="M5.85938 12.6328C6.49364 12.6328 7.00781 12.1186 7.00781 11.4844C7.00781 10.8501 6.49364 10.3359 5.85938 10.3359C5.22511 10.3359 4.71094 10.8501 4.71094 11.4844C4.71094 12.1186 5.22511 12.6328 5.85938 12.6328Z" fill="white"/>
    <path d="M13.5156 12.6328C14.1499 12.6328 14.6641 12.1186 14.6641 11.4844C14.6641 10.8501 14.1499 10.3359 13.5156 10.3359C12.8814 10.3359 12.3672 10.8501 12.3672 11.4844C12.3672 12.1186 12.8814 12.6328 13.5156 12.6328Z" fill="white"/>
    <g clipPath="url(#clip0_551_16057)">
    <path d="M1 14.1458C1.00112 15.0848 1.37462 15.985 2.03857 16.6489C2.70252 17.3129 3.6027 17.6864 4.54167 17.6875H14.4583C15.3973 17.6864 16.2975 17.3129 16.9614 16.6489C17.6254 15.985 17.9989 15.0848 18 14.1458V7.77084H1V14.1458ZM13.0417 10.9583C13.2518 10.9583 13.4572 11.0207 13.632 11.1374C13.8067 11.2542 13.9429 11.4201 14.0233 11.6142C14.1037 11.8084 14.1247 12.022 14.0838 12.2281C14.0428 12.4342 13.9416 12.6236 13.793 12.7721C13.6444 12.9207 13.4551 13.0219 13.2489 13.0629C13.0428 13.1039 12.8292 13.0829 12.6351 13.0025C12.4409 12.922 12.275 12.7859 12.1582 12.6111C12.0415 12.4364 11.9792 12.231 11.9792 12.0208C11.9792 11.7391 12.0911 11.4688 12.2904 11.2695C12.4896 11.0703 12.7599 10.9583 13.0417 10.9583ZM9.5 10.9583C9.71014 10.9583 9.91557 11.0207 10.0903 11.1374C10.265 11.2542 10.4012 11.4201 10.4816 11.6142C10.562 11.8084 10.5831 12.022 10.5421 12.2281C10.5011 12.4342 10.3999 12.6236 10.2513 12.7721C10.1027 12.9207 9.91339 13.0219 9.70728 13.0629C9.50118 13.1039 9.28755 13.0829 9.0934 13.0025C8.89925 12.922 8.73331 12.7859 8.61656 12.6111C8.49981 12.4364 8.4375 12.231 8.4375 12.0208C8.4375 11.7391 8.54944 11.4688 8.7487 11.2695C8.94796 11.0703 9.21821 10.9583 9.5 10.9583ZM5.95833 10.9583C6.16848 10.9583 6.3739 11.0207 6.54863 11.1374C6.72335 11.2542 6.85954 11.4201 6.93996 11.6142C7.02037 11.8084 7.04141 12.022 7.00042 12.2281C6.95942 12.4342 6.85823 12.6236 6.70963 12.7721C6.56104 12.9207 6.37172 13.0219 6.16562 13.0629C5.95951 13.1039 5.74588 13.0829 5.55173 13.0025C5.35759 12.922 5.19165 12.7859 5.0749 12.6111C4.95815 12.4364 4.89583 12.231 4.89583 12.0208C4.89583 11.7391 5.00777 11.4688 5.20703 11.2695C5.40629 11.0703 5.67654 10.9583 5.95833 10.9583Z" fill="#D3D0D9"/>
    <path d="M14.4583 2.10417H13.75V1.39583C13.75 1.20797 13.6754 1.0278 13.5425 0.894966C13.4097 0.762128 13.2295 0.6875 13.0417 0.6875C12.8538 0.6875 12.6736 0.762128 12.5408 0.894966C12.408 1.0278 12.3333 1.20797 12.3333 1.39583V2.10417H6.66667V1.39583C6.66667 1.20797 6.59204 1.0278 6.4592 0.894966C6.32636 0.762128 6.14619 0.6875 5.95833 0.6875C5.77047 0.6875 5.5903 0.762128 5.45747 0.894966C5.32463 1.0278 5.25 1.20797 5.25 1.39583V2.10417H4.54167C3.6027 2.10529 2.70252 2.47879 2.03857 3.14274C1.37462 3.80669 1.00112 4.70687 1 5.64583L1 6.35417H18V5.64583C17.9989 4.70687 17.6254 3.80669 16.9614 3.14274C16.2975 2.47879 15.3973 2.10529 14.4583 2.10417Z" fill="#D3D0D9"/>
    </g>
    <defs>
    <clipPath id="clip0_551_16057">
    <rect width="17" height="17" fill="white" transform="translate(1 0.6875)"/>
    </clipPath>
    </defs>
  </SvgIcon>
);

export default CalendarIcon;

