export const EXPORT_KEYS_PERFORMANCE = [
  { title: 'Date', field: 'reportDate' },
  { title: 'Customer', field: 'customerName' },
  { title: 'Partner', field: 'partnerName' },
  { title: 'Impression', field: 'total1' },
  { title: 'Conversion', field: 'total2' },
  { title: 'Attribute matches', field: 'total3' },
  { title: 'Conversion rate', field: 'convRatePercent' },
  { title: 'Avg. Frequency', field: 'avg_frequency3' },
  { title: 'Avg. time to conversion', field: 'days_to_action' },
  { title: 'ROAS', field: 'revRoas' },
  { title: 'Raw conversion rate', field: 'rawConvRate' },
  { title: 'Ad spend', field: 'revImp' },
  { title: 'Non-Attributable Revenue', field: 'revNonAttr' },
  { title: 'Attributable Revenue', field: 'revSent' },
  { title: 'Accrued Attributable Revenue', field: 'revSentDeff' },
  { title: 'Match Impression', field: 'total_match1' },
  { title: 'Match Conversion', field: 'total_match2' },
  { title: 'Unique Impressions', field: 'uniqueImpActBy1' },
  { title: 'Unique Conversions', field: 'uniqueImpActBy2' },
  { title: 'Unique Attribute matches', field: 'uniqueImpActBy3' },
  { title: 'Utilize Conversion', field: 'utilize2Percent' },
  { title: 'Utilize Impression', field: 'utilize1Percent' },
  { title: 'Unique Attributable Matches', field: 'uniqueSentActBy3' },
];
