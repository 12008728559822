import { SvgIcon } from '@mui/material';
import React from 'react';

function ErrorWarningIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '16px', ...sx }} width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
      <path d="M6.7747 1.79444L1.16009 11.1676C1.04433 11.3681 0.983075 11.5954 0.982427 11.8269C0.981779 12.0583 1.04176 12.286 1.15639 12.4871C1.27103 12.6882 1.43633 12.8558 1.63584 12.9732C1.83536 13.0906 2.06214 13.1537 2.29362 13.1562H13.5229C13.7543 13.1537 13.9811 13.0906 14.1806 12.9732C14.3801 12.8558 14.5454 12.6882 14.6601 12.4871C14.7747 12.286 14.8347 12.0583 14.834 11.8269C14.8334 11.5954 14.7721 11.3681 14.6564 11.1676L9.04176 1.79444C8.92359 1.59962 8.7572 1.43855 8.55865 1.32676C8.3601 1.21498 8.13609 1.15625 7.90823 1.15625C7.68038 1.15625 7.45637 1.21498 7.25782 1.32676C7.05927 1.43855 6.89288 1.59962 6.7747 1.79444Z" stroke="#E90D41" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.9082 5.20312V7.85466" stroke="#E90D41" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.9082 10.5039H7.91543" stroke="#E90D41" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export default ErrorWarningIcon;
