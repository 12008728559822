export const EXPORT_KEYS_INSIGHTS = [
  { title: 'Name', field: 'chart_name' },
  { title: 'Label', field: 'label' },
  { title: 'Segment', field: 'segment_full_name' },
  { title: 'Expected', field: 'expected' },
  { title: 'Observed', field: 'total' },
  { title: 'Index', field: 'index_value' },
  { title: 'Type', field: 'type' },
];

export const ACTIVATIONS_PLATFORMS = [{ value: 'The Trade Desk', label: 'The Trade Desk' }];
