import { SvgIcon } from '@mui/material';
import React from 'react';

function TimerIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '15px', ...sx }} width="12" height="15" viewBox="0 0 12 15" fill="none" {...props}>
      <path d="M4 0.5C4 0.632608 4.05268 0.759785 4.14645 0.853553C4.24021 0.947322 4.36739 1 4.5 1H7.5C7.63261 1 7.75979 0.947322 7.85355 0.853553C7.94732 0.759785 8 0.632608 8 0.5C8 0.367392 7.94732 0.240215 7.85355 0.146447C7.75979 0.0526784 7.63261 0 7.5 0H4.5C4.36739 0 4.24021 0.0526784 4.14645 0.146447C4.05268 0.240215 4 0.367392 4 0.5Z" fill="#108AE2" />
      <path d="M11.9774 8.5225C12.0891 7.26249 11.7985 5.99951 11.1474 4.915C11.0874 4.8145 11.0052 4.72901 10.9072 4.66504C10.8091 4.60107 10.6977 4.5603 10.5816 4.54584C10.4654 4.53138 10.3474 4.5436 10.2367 4.58158C10.1259 4.61955 10.0253 4.68229 9.94245 4.765L7.64995 7.0575C7.32813 7.36032 7.09626 7.74619 6.97995 8.1725C6.91904 8.38324 6.79937 8.57228 6.63495 8.7175C6.54621 8.8097 6.43927 8.88242 6.32091 8.93106C6.20255 8.97969 6.07537 9.00317 5.94745 9C5.70026 8.98733 5.46655 8.88344 5.29153 8.70842C5.11651 8.5334 5.01262 8.29969 4.99995 8.0525C4.99678 7.92458 5.02026 7.7974 5.06889 7.67904C5.11753 7.56068 5.19025 7.45374 5.28245 7.365C5.42766 7.20058 5.6167 7.08091 5.82745 7.02C6.25376 6.90369 6.63963 6.67182 6.94245 6.35L9.23245 4.06C9.31516 3.97714 9.37789 3.87651 9.41587 3.76576C9.45385 3.65502 9.46607 3.53707 9.45161 3.42088C9.43714 3.3047 9.39638 3.19335 9.33241 3.09529C9.26844 2.99723 9.18295 2.91505 9.08245 2.855C7.96486 2.18579 6.65985 1.89846 5.36454 2.03642C4.06924 2.17439 2.85402 2.73014 1.90247 3.61974C0.950911 4.50933 0.31471 5.68444 0.0899753 6.96753C-0.134759 8.25063 0.0641803 9.57201 0.656741 10.7321C1.2493 11.8921 2.20329 12.8278 3.3746 13.3978C4.5459 13.9678 5.87088 14.1411 7.14939 13.8916C8.42789 13.642 9.59046 12.9832 10.4615 12.0146C11.3325 11.046 11.8646 9.82023 11.9774 8.5225Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default TimerIcon;
