import { SvgIcon } from '@mui/material';
import React from 'react';

function ManageTagsIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '18px', ...sx }} width="18" height="17" viewBox="0 0 18 17" fill="none" {...props}>
      <path d="M13.276 0.0203298L5.58022 0.328648L0.905355 9.65875C0.665043 10.1384 0.625099 10.6938 0.794312 11.2029C0.963525 11.7119 1.32803 12.1329 1.80765 12.3732L10.2468 16.6017C10.7267 16.8404 11.2815 16.8794 11.7901 16.7104C12.2987 16.5413 12.7198 16.1779 12.9613 15.6994L17.6362 6.36931L13.276 0.0203298ZM10.7087 5.14411C10.5299 5.0545 10.3817 4.91388 10.2828 4.74002C10.1838 4.56617 10.1387 4.36688 10.153 4.16737C10.1674 3.96786 10.2406 3.77708 10.3634 3.61917C10.4861 3.46125 10.653 3.34329 10.8428 3.28019C11.0326 3.2171 11.2369 3.21171 11.4297 3.2647C11.6226 3.3177 11.7954 3.4267 11.9264 3.57792C12.0573 3.72914 12.1405 3.91579 12.1653 4.11426C12.1902 4.31274 12.1556 4.51413 12.066 4.69296C11.9458 4.93277 11.7353 5.11502 11.4808 5.19963C11.2263 5.28424 10.9485 5.26426 10.7087 5.14411Z" fill="#615E8B" />
    </SvgIcon>
  );
}

export default ManageTagsIcon;
