import { FIRST_PARTY_SEGMENT, CONVERSION_SEGMENT, IMPRESSION_SEGMENT } from '../constants/segments';

export const TAG_TYPES = {
  AUDIENCE_SEGMENT: 'CDA Impression',
  CONVERSION_SEGMENT: 'CDA Action',
  FIRST_PARTY_SEGMENT: 'Audience Activation',
  APPS_FLYER_SEGMENT: 'App Flyer',
  CDA_GET_INSIGHT: 'CDA Get Insights',
};

export const TAG_TYPES_MAPPED = {
  [TAG_TYPES.AUDIENCE_SEGMENT]: IMPRESSION_SEGMENT,
  [TAG_TYPES.CONVERSION_SEGMENT]: CONVERSION_SEGMENT,
  [TAG_TYPES.APPS_FLYER_SEGMENT]: FIRST_PARTY_SEGMENT,
};

export const EVENT_TYPES = {
  IMAGE_TAG: 'Image Tag',
  SERVER_TO_SERVER: 'Server To Server',
  OFFLINE_FILE: 'Offline File',
  APPSFLYER_LINK: 'AppFlyer Link',
};

export const TAG_OPTIONS = [
  { value: TAG_TYPES.AUDIENCE_SEGMENT, label: 'Impression Segment Tag' },
  { value: TAG_TYPES.CONVERSION_SEGMENT, label: 'Conversion Segment Tag' },
  {
    value: TAG_TYPES.APPS_FLYER_SEGMENT,
    label: 'AppsFlyer App Install Attribution link',
  },
  {
    value: TAG_TYPES.CDA_GET_INSIGHT,
    label: 'Get Insights Tag',
  },
  { value: TAG_TYPES.FIRST_PARTY_SEGMENT, label: 'Audience Activation Tag' },
];

export const EVENT_OPTIONS = [
  { value: EVENT_TYPES.IMAGE_TAG, label: 'Image tag' },
  { value: EVENT_TYPES.SERVER_TO_SERVER, label: 'Server To Server' },
  { value: EVENT_TYPES.OFFLINE_FILE, label: 'Offline File' },
];
