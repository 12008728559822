import { SvgIcon } from '@mui/material';
import React from 'react';

function UploadPhotoIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '153px', ...sx }} width="153" height="156" viewBox="0 0 153 156" fill="none" {...props}>
      <rect width="153" height="153" rx="76.5" fill="#F5F7F9" />
      <path d="M77.0011 71.5296C86.5524 71.5296 94.2953 63.7868 94.2953 54.2355C94.2953 44.6842 86.5524 36.9414 77.0011 36.9414C67.4499 36.9414 59.707 44.6842 59.707 54.2355C59.707 63.7868 67.4499 71.5296 77.0011 71.5296Z" fill="#6E6B7B" />
      <path d="M109.117 96.2374V102.826C109.117 103.699 108.779 104.537 108.176 105.155C107.574 105.773 106.757 106.12 105.905 106.12H48.0936C47.2418 106.12 46.4249 105.773 45.8225 105.155C45.2202 104.537 44.8818 103.699 44.8818 102.826V96.2374C44.8818 90.9954 46.9121 85.9682 50.5261 82.2616C54.14 78.555 59.0415 76.4727 64.1524 76.4727H89.8465C94.9574 76.4727 99.859 78.555 103.473 82.2616C107.087 85.9682 109.117 90.9954 109.117 96.2374Z" fill="#6E6B7B" />
      <circle cx="116.342" cy="143.119" r="12.4312" fill="#FBE4EF" />
      <g clipPath="url(#clip0_3242_19776)">
        <path d="M116.822 145.958C118.279 145.958 119.461 144.777 119.461 143.319C119.461 141.861 118.279 140.68 116.822 140.68C115.364 140.68 114.183 141.861 114.183 143.319C114.183 144.777 115.364 145.958 116.822 145.958Z" fill="#108AE2" />
        <path d="M121.469 138.326H119.403L119.059 137.752C118.887 137.465 118.6 137.293 118.256 137.293H115.387C115.043 137.293 114.756 137.465 114.584 137.752L114.24 138.326H112.174C111.084 138.326 110.166 139.244 110.166 140.334V146.358C110.166 147.448 111.084 148.366 112.174 148.366H121.526C122.616 148.366 123.534 147.448 123.534 146.358V140.334C123.477 139.186 122.616 138.326 121.469 138.326ZM116.822 146.702C114.928 146.702 113.436 145.211 113.436 143.317C113.436 141.481 114.928 139.932 116.822 139.932C118.715 139.932 120.207 141.424 120.207 143.317C120.207 145.211 118.715 146.702 116.822 146.702ZM120.78 140.908C120.494 140.908 120.264 140.678 120.264 140.391C120.264 140.104 120.494 139.875 120.78 139.875C121.067 139.875 121.297 140.104 121.297 140.391C121.297 140.678 121.067 140.908 120.78 140.908Z" fill="#108AE2" />
        <path d="M113.322 137.465H112.346C112.002 137.465 111.772 137.752 111.772 138.039H113.895C113.895 137.752 113.608 137.465 113.322 137.465Z" fill="#108AE2" />
      </g>
      <defs>
        <clipPath id="clip0_3242_19776">
          <rect width="14.3438" height="11.475" fill="white" transform="translate(109.648 137.062)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default UploadPhotoIcon;
