import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE } from '../constants';
import { LOCAL_STORAGE_FAVORITE } from '../constants/storage';
import http from '../services/Http';
import { addFavorite, getFavorites, removeFavorite } from './home-data';

const initialState = {
  isFavorite: JSON.parse(localStorage.getItem(LOCAL_STORAGE_FAVORITE)),
  favorites: [],
  table: {
    data: [],
    loading: false,
    headers: {},
    total: 0,
    page: 1,
  },
};

export const getHistory = createAsyncThunk(
  'history/list',
  async ({
    page, perPage, filters, partner,
  }, { getState }) => {
    const { history: { isFavorite } } = getState();

    const { data } = await http.get('/dashboard/history', {
      params: {
        page,
        per_page: perPage,
        favorite: isFavorite || null,
        is_active: false,
        partner,
        ...{
          ...filters,
        },
      },
    });
    return data;
  },
);

export const historySlice = createSlice({
  name: 'history',
  reducers: {
    clearHistoryReducer: () => initialState,
    handleChangePage: (state, { payload: { page, isFavorite } }) => {
      state.table.page = page;
      state.isFavorite = isFavorite !== undefined ? isFavorite : state.isFavorite;
    },
  },
  initialState,
  extraReducers: {
    [addFavorite.pending]: (state) => {
      state.loading = true;
    },
    [removeFavorite.pending]: (state) => {
      state.loading = true;
    },
    [getHistory.pending]: (state) => {
      state.table.loading = true;
    },
    [getFavorites.fulfilled]: (state, { payload }) => {
      state.favorites = payload;
      state.loading = false;
    },
    [removeFavorite.fulfilled]: (state, { meta }) => {
      state.favorites = state.favorites.filter((id) => id !== meta.arg.favorite);
      state.loading = false;

      if (meta.arg.isFavorite) {
        const total = state.table.total - 1;
        state.table.data = state.table.data.filter((item) => item.id !== meta.arg.favorite);
        state.table.total = total;
        state.table.page = total % DEFAULT_PAGE_SIZE === 0
          ? state.table.page - 1
          : state.table.page;
      }
    },
    [addFavorite.fulfilled]: (state, { meta }) => {
      state.favorites.push(meta.arg.favorite);
      state.loading = false;
    },
    [getHistory.fulfilled]: (
      state,
      {
        payload: {
          data, pagination, headers,
        },
      },
    ) => {
      state.table = {
        ...state.table,
        loading: false,
        data: data.map((row) => ({
          ...row,
          user_id: 1,
          start_date: row.start_date && row.start_date.replace(/T.*/, ''),
        })),
        headers,
        ...pagination,
      };
    },
  },
});

export const { clearHistoryReducer, handleChangePage } = historySlice.actions;
export default historySlice.reducer;
