import { SvgIcon } from '@mui/material';
import React from 'react';

function SaveIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '13px', ...sx }} width="12" height="13" viewBox="0 0 12 13" fill="none" {...props}>
      <path d="M5.27357 1.92085H6.08194C6.17036 1.92085 6.24614 1.84507 6.24614 1.75665V0.165176C6.24614 0.0767611 6.17036 0.000976562 6.08194 0.000976562H5.27357C5.18516 0.000976562 5.10938 0.0767611 5.10938 0.165176V1.74402C5.10937 1.84507 5.18516 1.92085 5.27357 1.92085Z" fill="#108AE2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.99336 0.318699L11.6332 2.95853C11.8353 3.16062 11.949 3.4385 11.9616 3.71637V10.9285C11.9616 11.5222 11.4816 12.0021 10.888 12.0021H1.1244C0.53075 12.0021 0.0507812 11.5222 0.0507812 10.9285V1.07654C0.0507812 0.482898 0.53075 0.00292969 1.1244 0.00292969H2.71587V2.21331C2.71587 2.44067 2.90533 2.63013 3.13269 2.63013H6.59351C6.82087 2.63013 7.01033 2.44067 7.01033 2.21331V0.00292969H8.23551C8.51339 0.00292969 8.79126 0.116606 8.99336 0.318699ZM8 9.00098H3V8.00098H8V9.00098ZM3 7.00098H8V6.00098H3V7.00098Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default SaveIcon;
