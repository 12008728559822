export const ATTRIBUTION = 'attribution';
export const ACTIVATION = 'activation';
export const INSIGHT = 'insights';
export const IMPRESSION = 'Impression';
export const CONVERSION = 'Conversion';
export const NEW_FORMAT = 'new_format';
export const AUDIENCE_ACTIVATION = 'iiq_audience_activation';
export const COMMA = 1;
export const TAB = 2;

export const ACTION_OPTIONS = [
  { value: ATTRIBUTION, label: 'Attribution' },
  { value: ACTIVATION, label: 'Activation' },
  { value: INSIGHT, label: 'Get Insight' },
  { value: AUDIENCE_ACTIVATION, label: 'IIQ ID Audience activation' },
];

export const DATA_TYPE_OPTIONS = [
  { value: IMPRESSION, label: 'Impression' },
  { value: CONVERSION, label: 'Conversion' },
];

export const FILE_FORMAT_OPTIONS = [
  { value: NEW_FORMAT, label: 'Add New File Format' },
];

export const DELIMITER_OPTIONS = [
  { value: COMMA, label: 'Comma (,)' },
  { value: TAB, label: 'Tab (/t)' },
];

export const DELIMITER_TYPE = {
  [COMMA]: 'Comma (,)',
  [TAB]: 'Tab (/t)',
};
