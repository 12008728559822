import { SvgIcon } from '@mui/material';
import React from 'react';

function SmallLogo({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '35px', ...sx }} width="35" height="28" viewBox="0 0 35 28" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.766492 0.0840518C0.42993 0.207774 0.171864 0.468018 0.0805494 0.77564C-0.0280863 1.14186 -0.0262518 26.2929 0.0825147 26.6593C0.176705 26.9767 0.503733 27.2775 0.859903 27.3743C1.03266 27.4213 1.73349 27.4398 2.83808 27.4266C4.81418 27.403 4.93589 27.3731 5.235 26.8397L5.39841 26.5483V13.7142V0.880161L5.235 0.588771C5.13081 0.402957 4.97681 0.247497 4.81013 0.159869C4.56652 0.0317256 4.42821 0.0213 2.78383 0.00678329C1.39941 -0.00548993 0.964546 0.0112044 0.766492 0.0840518ZM19.3537 0.0623427C14.6271 0.601044 10.4751 3.64025 8.48184 8.0206C7.63067 9.89101 7.26385 11.6058 7.26385 13.7142C7.26385 15.8227 7.63067 17.5375 8.48184 19.4079C10.273 23.344 13.8891 26.2774 18.0146 27.141C21.3834 27.8462 24.8957 27.2598 27.7633 25.5135L28.3619 25.149L29.5282 26.3301C30.5409 27.3558 30.7585 27.5434 31.1813 27.7557C31.646 27.9889 31.6981 28 32.3286 28C32.9604 28 33.0105 27.9892 33.487 27.7515C34.5451 27.2236 35.1402 26.12 34.9717 24.9977C34.8436 24.144 34.6736 23.8872 33.3277 22.514L32.1544 21.3171L32.3363 21.0293C33.5388 19.1271 34.2151 17.0126 34.3946 14.5936C34.6343 11.3626 33.6468 8.06942 31.6334 5.38562C31.1093 4.68697 29.8035 3.3678 29.1153 2.8417C27.0542 1.26611 24.7309 0.328592 22.216 0.0577238C21.4837 -0.0211283 20.0663 -0.0188188 19.3537 0.0623427ZM22.4585 5.6289C25.7992 6.33877 28.3326 8.99361 28.9053 12.3848C29.005 12.9753 29.0032 14.424 28.902 15.0339C28.7842 15.7439 28.3655 17.085 28.2039 17.2702C28.1916 17.2843 27.9097 17.0348 27.5776 16.7157C27.2067 16.3595 26.8425 16.0749 26.6335 15.9784C25.5813 15.4921 24.392 15.6948 23.5985 16.4958C22.9671 17.1333 22.6945 17.9594 22.8289 18.8281C22.929 19.4745 23.1438 19.8534 23.7948 20.5327L24.388 21.1517L23.9135 21.3517C21.5477 22.3487 18.6317 22.0848 16.4508 20.6765C14.5119 19.4245 13.1479 17.3418 12.7842 15.0778C12.2061 11.4795 13.9797 7.96326 17.2009 6.32181C17.776 6.02871 18.8568 5.67839 19.5264 5.568C20.2641 5.44632 21.744 5.47714 22.4585 5.6289Z" fill="#008BD2" />
    </SvgIcon>
  );
}

export default SmallLogo;
