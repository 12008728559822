import { SvgIcon } from '@mui/material';
import React from 'react';

function ExportIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '18px', ...sx }} width="17" height="18" viewBox="0 0 17 18" fill="none" {...props}>
      <g clipPath="url(#clip0_882_9963)">
        <path d="M10.4023 5.88H13.9936L9.99609 1.89875V5.49C9.99609 5.70125 10.1748 5.88 10.4023 5.88Z" fill="#108AE2" />
        <path d="M10.4019 7.09875C9.50813 7.09875 8.77688 6.3675 8.77688 5.47375V1.28125H3.39813C2.84563 1.28125 2.39062 1.73625 2.39062 2.28875V15.695C2.39062 16.2475 2.84563 16.7025 3.39813 16.7025H13.5706C14.1231 16.7025 14.5781 16.2475 14.5781 15.695V7.0825H10.4019V7.09875Z" fill="#108AE2" />
      </g>
      <defs>
        <clipPath id="clip0_882_9963">
          <rect width="16.25" height="16.25" fill="white" transform="translate(0.375 0.875)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default ExportIcon;
