import { SvgIcon } from '@mui/material';
import React from 'react';

function ChartIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '16px !important', ...sx }} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#clip0_669_10106)">
        <path d="M8.03769 2.04062L8.02907 1.3763H7.36475C7.31835 1.37587 7.27194 1.37587 7.22553 1.3763C3.20088 1.41474 -0.0306213 4.70855 0.00781618 8.73321C0.0462537 12.7579 3.34007 15.9894 7.36472 15.9509C11.3875 15.9465 14.6476 12.6867 14.6524 8.66396V7.99965H8.02907L8.03769 2.04062Z" fill="#108AE2" />
        <path d="M9.37056 0.0495605V6.67222H15.9926C15.9755 3.022 13.0208 0.0670293 9.37056 0.0495605Z" fill="#108AE2" />
      </g>
      <defs>
        <clipPath id="clip0_669_10106">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default ChartIcon;
