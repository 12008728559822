import { SvgIcon } from '@mui/material';
import React from 'react';

function SegmentIcon(props) {
  return (
    <SvgIcon width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
      <circle cx="10.3333" cy="10.8555" r="10" fill="#6F3B96" />
      <path d="M8.90771 12.5837C8.90771 11.2432 8.90771 9.90298 8.90771 8.56316C8.90771 7.99414 9.27917 7.62103 9.84324 7.61993C10.1701 7.61993 10.4981 7.63864 10.8233 7.61553C11.3318 7.57866 11.7291 8.00074 11.7589 8.50758C11.7589 8.55215 11.7589 8.59673 11.7589 8.6413C11.7589 11.2696 11.7589 13.898 11.7589 16.5267C11.7589 16.897 11.6857 17.2487 11.3104 17.4523C11.2072 17.5106 11.0904 17.5406 10.9719 17.5393C10.5713 17.5393 10.1701 17.5393 9.76895 17.5393C9.28028 17.5393 8.91707 17.186 8.91377 16.6934C8.90826 15.9158 8.91377 15.1388 8.91377 14.3612L8.90771 12.5837Z" fill="white" />
      <path d="M7.03658 7.0458C7.2567 7.0458 7.48233 7.0458 7.70465 7.0458C8.13224 7.0458 8.44316 7.32536 8.45802 7.7513C8.47398 8.21741 8.47178 8.68682 8.45802 9.15074C8.44647 9.55191 8.12013 9.86174 7.67438 9.88265C7.23689 9.90356 6.79774 9.90356 6.36024 9.88265C5.84681 9.85898 5.60742 9.58438 5.60632 9.06489C5.60632 8.63565 5.60632 8.20622 5.60632 7.77661C5.60632 7.34297 5.90624 7.0469 6.34538 7.0447C6.57596 7.0425 6.80655 7.0458 7.03658 7.0458Z" fill="white" />
      <path d="M10.3132 3.96454C10.6098 3.96454 10.9069 3.96454 11.2041 3.96454C11.4727 3.96784 11.6482 4.10487 11.6554 4.37342C11.673 4.97986 11.673 5.58685 11.6554 6.19439C11.6487 6.46955 11.3472 6.75296 11.0363 6.77277C10.5937 6.80669 10.1495 6.81312 9.70616 6.79203C9.16135 6.75791 8.90491 6.44864 8.90491 5.90768C8.90491 5.46303 8.90491 5.01893 8.90491 4.57483C8.90491 4.161 9.10357 3.96454 9.51025 3.96289C9.77715 3.96289 10.0446 3.96289 10.3121 3.96289L10.3132 3.96454Z" fill="white" />
      <path d="M13.6585 7.04663C13.9033 7.04663 14.1488 7.04278 14.3937 7.04663C14.7139 7.05269 14.9957 7.31078 15.0029 7.63216C15.015 8.16486 15.0084 8.69811 15.0029 9.23136C15.0029 9.47295 14.8532 9.64079 14.6573 9.73875C14.4935 9.82773 14.3118 9.87888 14.1257 9.88843C13.8213 9.89283 13.5165 9.89889 13.2127 9.88843C12.6167 9.86477 12.3372 9.56705 12.3372 8.97492C12.3372 8.58255 12.3372 8.19018 12.3372 7.79726C12.3372 7.29702 12.5876 7.04718 13.0773 7.04718H13.6568L13.6585 7.04663Z" fill="white" />
      <path d="M4.58716 10.1965C4.77261 10.1965 4.95807 10.1965 5.14352 10.1965C5.56286 10.1965 5.83195 10.3847 5.88368 10.8019C5.95247 11.3588 5.96733 11.9256 5.86277 12.4809C5.84285 12.5992 5.78764 12.7088 5.70436 12.7952C5.62109 12.8816 5.51364 12.9408 5.39611 12.9651C4.8954 13.0775 4.37673 13.0839 3.87341 12.9838C3.50195 12.9162 3.33631 12.6883 3.33521 12.3103C3.33521 11.8073 3.33521 11.3037 3.33521 10.8008C3.33796 10.4155 3.55533 10.1993 3.94055 10.1954C4.15682 10.1949 4.37199 10.1965 4.58716 10.1965Z" fill="white" />
      <path d="M16.2075 12.9155C15.9924 12.9155 15.7772 12.9095 15.5626 12.9155C15.4539 12.9238 15.3457 12.8949 15.2557 12.8334C15.1656 12.772 15.0992 12.6818 15.0673 12.5776C14.8532 11.9261 14.8692 11.2569 15.0095 10.5916C15.0816 10.2498 15.3397 10.1293 15.6501 10.1155C16.0419 10.0974 16.4365 10.099 16.8288 10.1155C17.2339 10.1315 17.4848 10.4133 17.503 10.8788C17.52 11.3147 17.5239 11.7522 17.503 12.1875C17.4826 12.6007 17.2548 12.9695 16.7232 12.9188C16.5542 12.9023 16.382 12.9161 16.2114 12.9161L16.2075 12.9155Z" fill="white" />
    </SvgIcon>
  );
}

export default SegmentIcon;
