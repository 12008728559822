import { SvgIcon } from '@mui/material';
import React from 'react';

function DeleteCircleIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '16px', ...sx }} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <circle cx="8" cy="8" r="8" fill="#131330" fillOpacity="0.05" />
      <g clipPath="url(#clip0_126_5760)">
        <path d="M10 5.66667V4.83333C10 4.61232 9.91571 4.40036 9.76569 4.24408C9.61566 4.0878 9.41217 4 9.2 4H6.8C6.58783 4 6.38434 4.0878 6.23431 4.24408C6.08429 4.40036 6 4.61232 6 4.83333V5.66667H4V6.5H4.8V12.75C4.8 13.0815 4.92643 13.3995 5.15147 13.6339C5.37652 13.8683 5.68174 14 6 14H10C10.3183 14 10.6235 13.8683 10.8485 13.6339C11.0736 13.3995 11.2 13.0815 11.2 12.75V6.5H12V5.66667H10ZM7.6 11.0833H6.8V8.58333H7.6V11.0833ZM9.2 11.0833H8.4V8.58333H9.2V11.0833ZM9.2 5.66667H6.8V4.83333H9.2V5.66667Z" fill="#131330" />
      </g>
      <defs>
        <clipPath id="clip0_126_5760">
          <rect width="11" height="11" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default DeleteCircleIcon;
