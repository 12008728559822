import { SvgIcon } from '@mui/material';
import React from 'react';

function CheckboxCircleCheckedIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '24px', ...sx }} width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx="12" cy="12" r="12" fill="#63D4A0" />
      <g clipPath="url(#clip0_2404_1450)">
        <path d="M18.169 7.82952C17.8586 7.52232 17.357 7.52232 17.0474 7.82952L10.3194 14.5015L6.95536 11.1655C6.64496 10.8583 6.14336 10.8583 5.83376 11.1655C5.52416 11.4727 5.52416 11.9703 5.83376 12.2775L9.75856 16.1695C10.069 16.4767 10.5706 16.4767 10.8802 16.1695L18.169 8.94152C18.4786 8.63512 18.4786 8.13672 18.169 7.82952Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_2404_1450">
          <rect width="12.8" height="12.8" fill="white" transform="translate(5.60156 5.6001)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default CheckboxCircleCheckedIcon;
