import { SvgIcon } from '@mui/material';
import React from 'react';

function SegmentIcon(props) {
  return (
    <SvgIcon width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
      <path d="M17 8.5C17 8.89171 16.6827 9.20833 16.2917 9.20833H15.5479C15.2143 12.5495 12.5495 15.215 9.20833 15.5479V16.2917C9.20833 16.6834 8.891 17 8.5 17C8.109 17 7.79167 16.6834 7.79167 16.2917V15.5479C4.45046 15.2143 1.785 12.5495 1.45208 9.20833H0.708333C0.317333 9.20833 0 8.89171 0 8.5C0 8.10829 0.317333 7.79167 0.708333 7.79167H1.45208C1.785 4.45046 4.45046 1.785 7.79167 1.45208V0.708333C7.79167 0.316625 8.109 0 8.5 0C8.891 0 9.20833 0.316625 9.20833 0.708333V1.45208C12.5495 1.78571 15.215 4.45046 15.5479 7.79167H16.2917C16.6827 7.79167 17 8.10829 17 8.5ZM12.0417 8.5C12.0417 6.54713 10.4529 4.95833 8.5 4.95833C6.54713 4.95833 4.95833 6.54713 4.95833 8.5C4.95833 10.4529 6.54713 12.0417 8.5 12.0417C10.4529 12.0417 12.0417 10.4529 12.0417 8.5Z" fill="#108AE2" />
      <path d="M8.5 10.625C9.67158 10.625 10.625 9.67158 10.625 8.5C10.625 7.32842 9.67158 6.375 8.5 6.375C7.32842 6.375 6.375 7.32842 6.375 8.5C6.375 9.67158 7.32842 10.625 8.5 10.625Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default SegmentIcon;
