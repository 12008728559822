import { SvgIcon } from '@mui/material';
import React from 'react';

function BellIcon(props) {
  return (
    <SvgIcon width="17" height="21" viewBox="0 0 17 21" fill="none" {...props}>
      <path d="M11.9086 17.0141C11.846 18.7685 10.7131 20.0705 8.99172 20.3655C8.96461 20.3703 8.94029 20.3743 8.92993 20.4034H8.0533C7.94726 20.3264 7.8169 20.338 7.6981 20.3085C6.17407 19.9258 5.12602 18.5967 5.10449 17.0165C7.37281 17.0157 9.64072 17.0153 11.909 17.0145L11.9086 17.0141Z" fill="#E02037" />
      <path d="M8.69039 0C9.20823 0.186568 9.39719 0.569669 9.36131 1.09868C9.34656 1.31634 9.36012 1.536 9.36051 1.75485C8.79164 1.69147 8.22277 1.69625 7.6539 1.75286C7.6539 1.4754 7.65709 1.19794 7.6539 0.920879C7.64911 0.497115 7.83728 0.203311 8.23154 0.0430541C8.25386 0.0338852 8.2722 0.01475 8.29213 0.000398649H8.69078L8.69039 0Z" fill="#E02037" />
      <path d="M7.6534 1.75796C8.22227 1.70136 8.79114 1.69617 9.36002 1.75996C10.18 1.89709 10.9586 2.15502 11.6558 2.61347C13.4773 3.81101 14.4105 5.50806 14.4563 7.68588C14.4731 8.48915 14.45 9.29323 14.4631 10.0965C14.4898 11.7657 15.136 13.1597 16.3683 14.2803C16.6657 14.551 16.9132 14.8444 16.9786 15.2534C17.1309 16.2074 16.454 17.0035 15.4741 17.0103C14.3651 17.0179 13.256 17.0119 12.147 17.0123C12.0672 17.0123 11.9879 17.0167 11.9082 17.019C9.63987 17.0198 7.37195 17.0202 5.10364 17.021C3.94237 17.0179 2.78071 17.0147 1.61945 17.0127C1.44603 17.0127 1.27422 17.0027 1.10758 16.9541C0.551465 16.7922 0.186701 16.4302 0.0495658 15.8709C-0.0903601 15.3017 0.0671063 14.7986 0.507614 14.4119C1.46916 13.5675 2.12254 12.5406 2.41037 11.2889C2.50883 10.8607 2.55508 10.4266 2.55388 9.98688C2.55228 9.23024 2.55268 8.47321 2.55388 7.71657C2.55787 4.81162 4.51922 2.40417 7.36159 1.81537C7.45886 1.79504 7.55653 1.7775 7.6542 1.75836L7.6534 1.75796Z" fill="#E02037" />
    </SvgIcon>
  );
}

export default BellIcon;
