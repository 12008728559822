import { SvgIcon } from '@mui/material';
import React from 'react';

function SidebarArrow({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '22px', ...sx }} width="22" height="23" viewBox="0 0 22 23" fill="none" {...props}>
      <circle cx="11.7266" cy="11.1875" r="10" fill="#FAFAFA" stroke="#FAFAFA" strokeWidth="2" />
      <path d="M10.6509 11.8077C10.6225 11.7779 10.5999 11.7424 10.5845 11.7034C10.5691 11.6644 10.5612 11.6225 10.5612 11.5803C10.5612 11.538 10.5691 11.4962 10.5845 11.4571C10.5999 11.4181 10.6225 11.3827 10.6509 11.3528L13.452 8.40714C13.6239 8.22644 13.7205 7.98133 13.7205 7.72572C13.7206 7.47011 13.6241 7.22495 13.4523 7.04416C13.2805 6.86338 13.0475 6.76178 12.8045 6.76172C12.5615 6.76166 12.3284 6.86314 12.1565 7.04384L9.35542 9.99017C8.95521 10.4123 8.73047 10.9841 8.73047 11.5803C8.73047 12.1764 8.95521 12.7482 9.35542 13.1704L12.1565 16.1167C12.3284 16.2974 12.5615 16.3989 12.8045 16.3988C13.0475 16.3987 13.2805 16.2971 13.4523 16.1164C13.6241 15.9356 13.7206 15.6904 13.7205 15.4348C13.7205 15.1792 13.6239 14.9341 13.452 14.7534L10.6509 11.8077Z" fill="#171717" />
    </SvgIcon>
  );
}

export default SidebarArrow;
