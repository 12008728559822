import ReactDOM from 'react-dom';
import { Suspense, lazy } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from '@mui/material';
import { store } from './redux/store';
import { theme } from './theme';
import { datonicsTheme } from './theme/datonicsTheme';
import Spinner from './@core/components/spinner/Loading-spinner';
import { SKINS, skin } from './constants/skin';
import './index.scss';

const App = lazy(() => import('./App'));

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={skin === SKINS.IIQ ? theme : datonicsTheme}>
        <Suspense fallback={<Spinner />}>
          <App />
          <Toaster
            toastOptions={{ className: 'react-hot-toast' }}
            position="top-right"
          />
        </Suspense>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
