import { SvgIcon } from '@mui/material';
import React from 'react';

function AlarmIcon({ sx, ...props }) {
  return (
    <SvgIcon width="25" height="25" viewBox="0 0 25 25" fill="none" {...props}>
      <circle cx="12.3333" cy="12.8555" r="11.5" fill="#FF9F43" stroke="#FF9F43" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5 7.84654C9.73365 7.84654 7.49107 10.0891 7.49107 12.8555C7.49107 15.6218 9.73365 17.8644 12.5 17.8644C15.2664 17.8644 17.5089 15.6218 17.5089 12.8555C17.5089 10.0891 15.2664 7.84654 12.5 7.84654ZM6.125 12.8555C6.125 9.33465 8.97918 6.48047 12.5 6.48047C16.0208 6.48047 18.875 9.33465 18.875 12.8555C18.875 16.3763 16.0208 19.2305 12.5 19.2305C8.97918 19.2305 6.125 16.3763 6.125 12.8555Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5003 8.75684C12.8775 8.75684 13.1833 9.06264 13.1833 9.43987V12.4329L15.0825 13.3825C15.42 13.5512 15.5567 13.9615 15.388 14.2989C15.2193 14.6363 14.809 14.7731 14.4716 14.6044L12.1948 13.466C11.9634 13.3503 11.8173 13.1138 11.8173 12.8551V9.43987C11.8173 9.06264 12.1231 8.75684 12.5003 8.75684Z" fill="white" />
    </SvgIcon>
  );
}

export default AlarmIcon;
