import { SvgIcon } from '@mui/material';
import React from 'react';

function ProfileMatchingIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '114px', ...sx }} width="114" height="113" viewBox="0 0 114 113" fill="none" {...props}>
      <ellipse cx="56.1797" cy="56.7969" rx="56" ry="56.5" fill="#EBE9F1" fillOpacity="0.05" />
      <ellipse cx="56.1797" cy="56.7969" rx="34" ry="33.5" fill="#EBE9F1" />
      <circle cx="56.1797" cy="56.7969" r="40.5" fill="#EBE9F1" fillOpacity="0.1" />
      <path fillRule="evenodd" clipRule="evenodd" d="M50.1389 64.2669C49.0632 64.7467 48.4281 65.477 48.0324 66.7976C47.8017 67.5676 46.9905 68.0046 46.2206 67.7739C45.4507 67.5433 45.0135 66.7321 45.2442 65.9622C45.8547 63.925 47.0169 62.4723 48.9532 61.6086C50.7575 60.8038 53.1357 60.5586 56.0979 60.5586C59.06 60.5586 61.4383 60.8038 63.2426 61.6086C65.1789 62.4723 66.3412 63.925 66.9515 65.9622C67.1823 66.7321 66.7452 67.5433 65.9753 67.7739C65.2053 68.0046 64.3941 67.5676 64.1634 66.7976C63.7677 65.477 63.1326 64.7467 62.0568 64.2669C60.8492 63.7281 59.0007 63.4692 56.0979 63.4692C53.1951 63.4692 51.3466 63.7281 50.1389 64.2669Z" fill="white" stroke="white" strokeWidth="0.5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M56.0978 48.9106C54.0884 48.9106 52.4595 50.5396 52.4595 52.5489C52.4595 54.5583 54.0884 56.1872 56.0978 56.1872C58.1071 56.1872 59.7361 54.5583 59.7361 52.5489C59.7361 50.5396 58.1071 48.9106 56.0978 48.9106ZM49.5488 52.5489C49.5488 48.9321 52.4809 46 56.0978 46C59.7147 46 62.6467 48.9321 62.6467 52.5489C62.6467 56.1659 59.7147 59.0979 56.0978 59.0979C52.4809 59.0979 49.5488 56.1659 49.5488 52.5489Z" fill="white" stroke="white" strokeWidth="0.5" />
    </SvgIcon>
  );
}

export default ProfileMatchingIcon;
