import { SvgIcon } from '@mui/material';
import React from 'react';

function DashboardIcon(props) {
  return (
    <SvgIcon width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
      <path d="M15.8333 0H13.4583C11.7095 0 10.2917 1.41776 10.2917 3.16665V5.54165C10.2917 7.29054 11.7095 8.70831 13.4583 8.70831H15.8333C17.5822 8.70835 19 7.29058 19 5.54165V3.16665C19 1.41776 17.5822 0 15.8333 0Z" fill="white" />
      <path d="M5.54184 0H3.16684C1.41795 0 0.000188927 1.41776 0.000188927 3.16665V5.54165C0.000188927 7.29054 1.41795 8.70831 3.16684 8.70831H5.54184C7.29073 8.70831 8.7085 7.29054 8.7085 5.54165V3.16665C8.70853 1.41776 7.29077 0 5.54184 0Z" fill="#615E8B" />
      <path d="M15.8333 10.2915H13.4583C11.7095 10.2915 10.2917 11.7093 10.2917 13.4582V15.8332C10.2917 17.582 11.7095 18.9998 13.4583 18.9998H15.8333C17.5822 18.9998 19 17.5821 19 15.8332V13.4582C19 11.7093 17.5822 10.2915 15.8333 10.2915Z" fill="#615E8B" />
      <path d="M5.54184 10.2915H3.16684C1.41795 10.2915 0.000188927 11.7093 0.000188927 13.4582V15.8332C0.000188927 17.5821 1.41795 18.9998 3.16684 18.9998H5.54184C7.29073 18.9998 8.7085 17.5821 8.7085 15.8332V13.4582C8.70853 11.7093 7.29077 10.2915 5.54184 10.2915Z" fill="#615E8B" />
    </SvgIcon>
  );
}

export default DashboardIcon;
