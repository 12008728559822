import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import http from '../services/Http';

const initialState = {
  data: [],
  updateData: [],
  modules: [],
  modulesOptions: {},
  selectedPermission: {},
  loading: false,
  loadingCreate: false,
};

export const getPermissions = createAsyncThunk(
  'permissions/list',
  async ({ filters: { search, order_by, order_dir } }) => {
    const data = await http.get('/roles', { params: { search: search || null, order_by, order_dir } });

    return data;
  },
);

export const getModules = createAsyncThunk(
  'permissions/modules',
  async () => {
    const data = await http.get('/roles/modules');

    return data;
  },
);

export const getModulesOptions = createAsyncThunk(
  'permissions/modules-options',
  async () => {
    const data = await http.get('/roles/modules-options');

    return data;
  },
);

export const createPermissions = createAsyncThunk(
  'permissions/create',
  async ({ data, callback, errorCallback }, { rejectWithValue }) => {
    try {
      const res = await http.post('/roles', data);
      if (callback) callback();

      return res;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage && errorCallback) {
        errorCallback(errorMessage);
      }
      rejectWithValue(null);
    }
  },
);

export const deletePermission = createAsyncThunk(
  'permissions/delete',
  async ({ id, errorCallback }, { rejectWithValue }) => {
    try {
      const res = await http.delete(`/roles/${id}`);
      toast.success('Permission deleted');

      return res;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage && errorCallback) {
        errorCallback(errorMessage);
      }
      return rejectWithValue(errorMessage);
    }
  },
);

export const updatePermission = createAsyncThunk(
  'permissions/update',
  async (callback, { getState, dispatch }) => {
    const { homeData: { partner }, permissions: { selectedPermission, updateData } } = getState();
    const { name, creation_date, role_id } = selectedPermission;
    if (!updateData.length) return {};

    const res = await http.patch(`/roles/${role_id}`, {
      data: updateData, partner_id: partner, name, creation_date,
    });
    toast.success('Permission updated');

    dispatch(getPermissions({ filters: { search: '' } }));
    if (callback) {
      callback();
    }

    return res;
  },
);

export const permissionSlice = createSlice({
  name: 'permissions',
  reducers: {
    setUpdateData: (state, { payload }) => {
      const updateDataIds = state.updateData.map((item) => item.module_id);
      const data = updateDataIds.includes(payload.module_id)
        ? state.updateData.map((item) => (item.module_id === payload.module_id
          ? { ...item, ...payload }
          : item))
        : [...state.updateData, payload];
      state.updateData = data;
    },
    setSelectedPermission: (state, { payload }) => {
      state.selectedPermission = payload;
    },
  },
  initialState,
  extraReducers: {
    [getPermissions.pending]: (state) => {
      state.loading = true;
    },
    [getPermissions.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = false;
    },
    [getModules.pending]: (state) => {
      state.loading = true;
    },
    [getModules.fulfilled]: (state, { payload }) => {
      state.modules = payload.data;
      state.loading = false;
    },
    [getModulesOptions.pending]: (state) => {
      state.loading = true;
    },
    [getModulesOptions.fulfilled]: (state, { payload }) => {
      state.modulesOptions = payload.data;
      state.loading = false;
    },
    [createPermissions.pending]: (state) => {
      state.loadingCreate = true;
    },
    [createPermissions.fulfilled]: (state) => {
      state.loadingCreate = false;
    },
    [createPermissions.rejected]: (state) => {
      state.loadingCreate = false;
    },
    [updatePermission.fulfilled]: (state) => {
      state.updateData = [];
      state.selectedPermission = {};
    },
    [deletePermission.pending]: (state) => {
      state.loading = true;
    },
    [deletePermission.fulfilled]: (state, { meta: { arg } }) => {
      state.data = state.data.filter((item) => item.role_id !== arg.id);
      state.loading = false;
    },
    [deletePermission.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const {
  setUpdateData, setSelectedPermission,
} = permissionSlice.actions;
export default permissionSlice.reducer;
