import { SvgIcon } from '@mui/material';
import React from 'react';

function ManageUsersIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '18px', ...sx }} width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path d="M8.5 1C3.80573 1 0 4.80573 0 9.5C0 14.1943 3.80573 18 8.5 18C13.1943 18 17 14.1943 17 9.5C17 4.80573 13.1943 1 8.5 1ZM11.1531 13.4667C11.2931 13.8157 11.3333 13.882 11.3333 14.0333C11.3333 14.3399 11.0891 14.6 10.7667 14.6H6.23333C5.9109 14.6 5.66667 14.3399 5.66667 14.0333C5.66667 13.882 5.7069 13.8157 5.84687 13.4667C5.84687 13.4667 6.63907 11.6052 7.39047 9.84057C6.37727 9.40877 5.66667 8.40407 5.66667 7.23333C5.66667 5.66877 6.93543 4.4 8.5 4.4C10.0646 4.4 11.3333 5.66877 11.3333 7.23333C11.3333 8.40407 10.6227 9.40877 9.60953 9.84057L11.1531 13.4667Z" fill="#615E8B" />
      <circle cx="14.7891" cy="3.21094" r="2.71094" fill="white" stroke="#131330" />
    </SvgIcon>
  );
}

export default ManageUsersIcon;
