import { SvgIcon } from '@mui/material';
import React from 'react';

function NavbarLogoBackgroundWIthoutLizard({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '96px', ...sx }} width="211" height="611" viewBox="0 0 211 611" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g style={{ mixBlendMode: 'screen' }} opacity="0.3" filter="url(#filter0_f_1830_3963)">
        <circle cx="36.5" cy="381.5" r="181.5" fill="#46B1FF" />
      </g>
      <defs>
        <filter id="filter0_f_1830_3963" x="-345" y="0" width="763" height="763" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_1830_3963" />
        </filter>
      </defs>
    </SvgIcon>
  );
}

export default NavbarLogoBackgroundWIthoutLizard;
