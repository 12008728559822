import { SvgIcon } from '@mui/material';
import React from 'react';

function ManageIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '15px', ...sx }} width="15" height="16" viewBox="0 0 15 16" fill="none" {...props}>
      <path d="M11.2643 0.86285L4.735 1.12443L0.768719 9.04033C0.564832 9.44725 0.530943 9.9185 0.674508 10.3504C0.818072 10.7823 1.12733 11.1395 1.53425 11.3434L8.69426 14.9309C9.10141 15.1334 9.57215 15.1665 10.0037 15.0231C10.4352 14.8797 10.7924 14.5713 10.9973 14.1654L14.9636 6.24949L11.2643 0.86285ZM9.08617 5.21C8.93444 5.13397 8.80867 5.01467 8.72475 4.86716C8.64083 4.71966 8.60253 4.55058 8.6147 4.38131C8.62688 4.21204 8.68897 4.05018 8.79313 3.9162C8.8973 3.78222 9.03885 3.68213 9.19989 3.6286C9.36094 3.57507 9.53424 3.5705 9.69788 3.61546C9.86152 3.66042 10.0082 3.7529 10.1192 3.8812C10.2303 4.0095 10.3009 4.16786 10.322 4.33625C10.343 4.50464 10.3137 4.67551 10.2377 4.82723C10.1357 5.03069 9.95716 5.18532 9.7412 5.2571C9.52525 5.32889 9.28963 5.31194 9.08617 5.21Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default ManageIcon;
