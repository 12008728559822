import { SvgIcon } from '@mui/material';
import React from 'react';

function StopCampaignIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '23px', ...sx }} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M7.99998 0.975586C4.11835 0.975586 0.975586 4.11835 0.975586 7.99998C0.975586 11.8816 4.11835 15.0244 7.99998 15.0244C11.8816 15.0244 15.0244 11.8816 15.0244 7.99998C15.0244 4.11835 11.8816 0.975586 7.99998 0.975586ZM10.7785 10.0084C10.7785 10.4351 10.4351 10.7785 10.0084 10.7785H5.99152C5.56485 10.7785 5.22144 10.4351 5.22144 10.0084V5.99152C5.22144 5.56485 5.56485 5.22144 5.99152 5.22144H10.0084C10.4351 5.22144 10.7785 5.56485 10.7785 5.99152V10.0084Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default StopCampaignIcon;
