import { SvgIcon } from '@mui/material';
import React from 'react';

function ArrowSmallDownIcon(props) {
  return (
    <SvgIcon width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
      <path d="M12.3339 6.16495C12.2807 6.11268 12.2175 6.0712 12.1479 6.04289C12.0783 6.01458 12.0036 6 11.9282 6C11.8528 6 11.7781 6.01458 11.7084 6.04289C11.6388 6.0712 11.5756 6.11268 11.5225 6.16495L8.90567 8.71891C8.85255 8.77118 8.78936 8.81266 8.71973 8.84097C8.65011 8.86928 8.57543 8.88386 8.5 8.88386C8.42457 8.88386 8.34989 8.86928 8.28027 8.84097C8.21064 8.81266 8.14745 8.77118 8.09433 8.71891L5.47749 6.16495C5.42437 6.11268 5.36118 6.0712 5.29155 6.04289C5.22192 6.01458 5.14724 6 5.07182 6C4.99639 6 4.92171 6.01458 4.85208 6.04289C4.78246 6.0712 4.71926 6.11268 4.66615 6.16495C4.55973 6.26943 4.5 6.41076 4.5 6.55808C4.5 6.7054 4.55973 6.84673 4.66615 6.95121L7.28871 9.51075C7.6101 9.82403 8.04576 10 8.5 10C8.95424 10 9.3899 9.82403 9.71129 9.51075L12.3339 6.95121C12.4403 6.84673 12.5 6.7054 12.5 6.55808C12.5 6.41076 12.4403 6.26943 12.3339 6.16495Z" fill="#D3D0D9" />
    </SvgIcon>
  );
}

export default ArrowSmallDownIcon;
