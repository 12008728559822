import { SvgIcon } from '@mui/material';
import React from 'react';

function InsightIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '16px', ...sx }} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M15.6667 14H15.3333V7.66666C15.3333 7.57825 15.2982 7.49347 15.2357 7.43095C15.1732 7.36844 15.0884 7.33332 15 7.33332H11.6667C11.5783 7.33332 11.4935 7.36844 11.431 7.43095C11.3685 7.49347 11.3333 7.57825 11.3333 7.66666V14H10V0.99999C10 0.911584 9.96488 0.8268 9.90237 0.764288C9.83986 0.701775 9.75507 0.666656 9.66667 0.666656H6.33333C6.24493 0.666656 6.16014 0.701775 6.09763 0.764288C6.03512 0.8268 6 0.911584 6 0.99999V14H4.66667V4.33332C4.66667 4.24492 4.63155 4.16013 4.56904 4.09762C4.50652 4.03511 4.42174 3.99999 4.33333 3.99999H1C0.911595 3.99999 0.82681 4.03511 0.764298 4.09762C0.701786 4.16013 0.666667 4.24492 0.666667 4.33332V14H0.333333C0.244928 14 0.160143 14.0351 0.0976311 14.0976C0.035119 14.1601 0 14.2449 0 14.3333L0 15C0 15.0884 0.035119 15.1732 0.0976311 15.2357C0.160143 15.2982 0.244928 15.3333 0.333333 15.3333H15.6667C15.7551 15.3333 15.8399 15.2982 15.9024 15.2357C15.9649 15.1732 16 15.0884 16 15V14.3333C16 14.2449 15.9649 14.1601 15.9024 14.0976C15.8399 14.0351 15.7551 14 15.6667 14Z" fill="#131330" />
    </SvgIcon>
  );
}

export default InsightIcon;
