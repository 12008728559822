import { SvgIcon } from '@mui/material';
import React from 'react';

function HeartIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '19px', ...sx }} width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
      <path d="M13.7215 0.718994C12.8386 0.732727 11.9749 0.979197 11.2177 1.43351C10.4605 1.88783 9.83659 2.5339 9.40898 3.30649C8.98137 2.5339 8.35746 1.88783 7.60027 1.43351C6.84308 0.979197 5.97941 0.732727 5.09648 0.718994C3.68899 0.780146 2.36291 1.39586 1.40795 2.43163C0.452984 3.4674 -0.053233 4.83902 -0.000110385 6.24683C-0.000110385 11.5591 8.59039 17.6946 8.95578 17.9549L9.40898 18.2756L9.86219 17.9549C10.2276 17.6961 18.8181 11.5591 18.8181 6.24683C18.8712 4.83902 18.365 3.4674 17.41 2.43163C16.4551 1.39586 15.129 0.780146 13.7215 0.718994Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default HeartIcon;
