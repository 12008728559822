import { SvgIcon } from '@mui/material';
import React from 'react';

function UploadSuccessIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '147px', ...sx }} width="147" height="148" viewBox="0 0 147 148" fill="none" {...props}>
      <circle cx="73.7372" cy="73.8984" r="59.9999" fill="#F5F7F9" fillOpacity="0.4" />
      <circle cx="73.7378" cy="73.8979" r="72.8745" stroke="#63D4A0" strokeWidth="0.5" fill="transparent" />
      <circle cx="73.7369" cy="73.9009" r="39.9244" fill="#63D4A0" stroke="#63D4A0" strokeWidth="0.5" />
      <path d="M83.1751 65.2L80.186 62.2109C79.6128 61.6344 78.931 61.1774 78.18 60.8662C77.429 60.5551 76.6237 60.3961 75.8108 60.3985H68.6749C67.0344 60.4002 65.4616 61.0527 64.3016 62.2127C63.1416 63.3727 62.4891 64.9455 62.4873 66.586V81.2112C62.4891 82.8516 63.1416 84.4244 64.3016 85.5844C65.4616 86.7444 67.0344 87.3969 68.6749 87.3987H78.8C80.4404 87.3969 82.0132 86.7444 83.1732 85.5844C84.3332 84.4244 84.9857 82.8516 84.9875 81.2112V69.5752C84.9897 68.7623 84.8307 67.957 84.5196 67.2061C84.2085 66.4551 83.7515 65.7732 83.1751 65.2ZM78.8 84.0237H68.6749C67.9289 84.0237 67.2136 83.7274 66.6861 83.1999C66.1587 82.6725 65.8623 81.9571 65.8623 81.2112V66.586C65.8623 65.8401 66.1587 65.1247 66.6861 64.5973C67.2136 64.0698 67.9289 63.7735 68.6749 63.7735H74.8624V68.2735C74.8624 68.8703 75.0995 69.4426 75.5214 69.8645C75.9434 70.2865 76.5157 70.5236 77.1124 70.5236H81.6125V81.2112C81.6125 81.9571 81.3162 82.6725 80.7887 83.1999C80.2613 83.7274 79.5459 84.0237 78.8 84.0237ZM79.4592 73.2978C79.7676 73.6219 79.9347 74.0552 79.9237 74.5025C79.9127 74.9497 79.7246 75.3743 79.4007 75.6829L75.3664 79.5236C74.6256 80.2492 73.6284 80.6532 72.5914 80.6479C71.5545 80.6426 70.5615 80.2285 69.828 79.4955L68.1124 77.9711C67.9469 77.8234 67.8121 77.6445 67.7158 77.4447C67.6195 77.2449 67.5634 77.028 67.5509 76.8066C67.5384 76.5851 67.5696 76.3633 67.6427 76.1539C67.7159 75.9445 67.8296 75.7516 67.9774 75.5861C68.1251 75.4206 68.304 75.2859 68.5038 75.1896C68.7036 75.0932 68.9204 75.0372 69.1419 75.0246C69.3634 75.0121 69.5851 75.0433 69.7946 75.1165C70.004 75.1897 70.1969 75.3034 70.3624 75.4511L72.1466 77.0419C72.2044 77.1102 72.2755 77.166 72.3556 77.2058C72.4357 77.2457 72.5231 77.2687 72.6124 77.2736C72.7613 77.2736 72.9041 77.2145 73.0095 77.1094L77.0742 73.2393C77.3983 72.9309 77.8316 72.7639 78.2788 72.7749C78.726 72.7858 79.1506 72.9739 79.4592 73.2978Z" fill="white" />
    </SvgIcon>
  );
}

export default UploadSuccessIcon;
