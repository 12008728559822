import { SvgIcon } from '@mui/material';
import React from 'react';

function DeleteIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '16px', ...sx }} width="14" height="16" viewBox="0 0 14 16" fill="none" {...props}>
      <path d="M10.333 2.66667V1.33333C10.333 0.979711 10.1925 0.640573 9.94248 0.390524C9.69244 0.140476 9.3533 0 8.99967 0L4.99967 0C4.64605 0 4.30691 0.140476 4.05687 0.390524C3.80682 0.640573 3.66634 0.979711 3.66634 1.33333V2.66667H0.333008V4H1.66634V14C1.66634 14.5304 1.87705 15.0391 2.25213 15.4142C2.6272 15.7893 3.13591 16 3.66634 16H10.333C10.8634 16 11.3721 15.7893 11.7472 15.4142C12.1223 15.0391 12.333 14.5304 12.333 14V4H13.6663V2.66667H10.333ZM6.33301 11.3333H4.99967V7.33333H6.33301V11.3333ZM8.99967 11.3333H7.66634V7.33333H8.99967V11.3333ZM8.99967 2.66667H4.99967V1.33333H8.99967V2.66667Z" fill="#D3D0D9" />
    </SvgIcon>
  );
}

export default DeleteIcon;
