import { SvgIcon } from '@mui/material';
import React from 'react';

function MoreIcon(props) {
  return (
    <SvgIcon width="6" height="26" viewBox="0 0 6 26" fill="none" {...props}>
      <rect x="5.03369" y="7.0127" width="4.99996" height="4.99996" rx="2.49998" transform="rotate(-179.855 5.03369 7.0127)" fill="#D3D0D9" />
      <rect x="5.01709" y="15.0254" width="4.99996" height="4.99996" rx="2.49998" transform="rotate(-179.855 5.01709 15.0254)" fill="#D3D0D9" />
      <rect x="5" y="23.0381" width="4.99996" height="4.99996" rx="2.49998" transform="rotate(-179.855 5 23.0381)" fill="#D3D0D9" />
    </SvgIcon>
  );
}

export default MoreIcon;
