import { SvgIcon } from '@mui/material';
import React from 'react';

function BreadcrumbsArrow({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '12px', ...sx }} width="8" height="12" viewBox="0 0 8 12" fill="none" {...props}>
      <path d="M1.25239 5.58584C1.14375 5.69513 1.08276 5.84298 1.08276 5.99709C1.08276 6.1512 1.14375 6.29904 1.25239 6.40834L5.91906 11.075C6.03065 11.1706 6.1742 11.2205 6.32101 11.2148C6.46782 11.2092 6.60708 11.1483 6.71097 11.0444C6.81486 10.9405 6.87572 10.8013 6.88139 10.6545C6.88706 10.5076 6.83712 10.3641 6.74156 10.2525L2.48906 6.00001L6.74739 1.74751C6.85724 1.63766 6.91895 1.48868 6.91895 1.33334C6.91895 1.178 6.85724 1.02902 6.74739 0.919173C6.63755 0.809329 6.48857 0.74762 6.33323 0.74762C6.17788 0.74762 6.0289 0.809329 5.91906 0.919173L1.25239 5.58584Z" fill="#5E5873" stroke="#5E5873" />
    </SvgIcon>
  );
}

export default BreadcrumbsArrow;
