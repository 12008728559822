import toast from 'react-hot-toast';
import Box from '@mui/material/Box';
import {
  arrowUpSx, closeIconSx, containerSx, successContainerSx,
} from './styles';
import { CloseIcon } from '../../assets/svg';

export const toastError = (text, options) => toast((t) => (
  <Box sx={containerSx} id="toast-error">
    <CloseIcon onClick={() => toast.dismiss(t.id)} sx={closeIconSx} id="toast-error-close-icon" />

    {text}
    <Box sx={arrowUpSx} />
  </Box>
), {
  duration: 30000, position: 'bottom-right', id: 'error', ...options,
});

export const toastSuccess = (text, options) => toast(() => (
  <Box sx={successContainerSx} id="toast-success">
    {text}
    <Box sx={arrowUpSx} />
  </Box>
), {
  duration: 5000, position: 'bottom-right', id: 'success', ...options,
});

export const toastErrorDatonics = (text, options) => toast((t) => (
  <Box sx={containerSx} id="toast-error">
    <CloseIcon onClick={() => toast.dismiss(t.id)} sx={closeIconSx} id="toast-error-close-icon" />

    {text}
  </Box>
), {
  duration: 30000, position: 'top-right', id: 'error', ...options, style: { marginTop: '100px', marginRight: '44px' },
});

export const toastSuccessDatonics = (text, options) => toast(() => (
  <Box sx={successContainerSx} id="toast-success">
    {text}
  </Box>
), {
  duration: 5000, position: 'top-right', id: 'success', ...options, style: { marginTop: '100px', marginRight: '44px' },
});
