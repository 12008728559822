import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import http from '../services/Http';

export const getMainReport = createAsyncThunk('reports/main', async ({ page, perPage, filters }) => {
  const { data } = await http.get('/dashboard/performance', {
    params: {
      page,
      per_page: perPage,
      ...filters,
    },
  });
  return data;
});

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    data: [],
    loading: false,
    headers: {},
    total: 0,
    lastPage: 1,
    from: 0,
    to: 0,
    currentPage: 1,
  },
  extraReducers: {
    [getMainReport.pending]: (state) => {
      state.loading = true;
    },
    [getMainReport.fulfilled]: (state, {
      payload: {
        data, pagination: {
          total, lastPage, from, to, currentPage,
        }, headers,
      },
    }) => {
      state.data = data
        .map((row) => ({
          status: 'no_data',
          users: [1, 2, 3],
          favorite: false,
          ...row,
        }));

      state.headers = headers;

      if (total) {
        state.total = total;
      }

      if (lastPage) {
        state.lastPage = lastPage;
      }

      if (from) {
        state.from = from;
      }

      if (to) {
        state.to = to;
      }

      if (currentPage) {
        state.currentPage = currentPage;
      }

      state.loading = false;
    },
  },
});

export default reportsSlice.reducer;
