import { SvgIcon } from '@mui/material';
import React from 'react';

function ArrowUpCircle({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '17px', ...sx }} width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <circle cx="8" cy="8.5" r="8" fill="#131330" fillOpacity="0.05" />
      <path d="M4.16615 10.3351C4.21926 10.3873 4.28246 10.4288 4.35208 10.4571C4.42171 10.4854 4.49639 10.5 4.57182 10.5C4.64724 10.5 4.72192 10.4854 4.79155 10.4571C4.86118 10.4288 4.92437 10.3873 4.97748 10.3351L7.59433 7.78109C7.64745 7.72882 7.71064 7.68734 7.78027 7.65903C7.84989 7.63072 7.92457 7.61614 8 7.61614C8.07543 7.61614 8.15011 7.63072 8.21973 7.65903C8.28936 7.68734 8.35255 7.72882 8.40567 7.78109L11.0225 10.3351C11.0756 10.3873 11.1388 10.4288 11.2085 10.4571C11.2781 10.4854 11.3528 10.5 11.4282 10.5C11.5036 10.5 11.5783 10.4854 11.6479 10.4571C11.7175 10.4288 11.7807 10.3873 11.8339 10.3351C11.9403 10.2306 12 10.0892 12 9.94192C12 9.7946 11.9403 9.65327 11.8339 9.54879L9.21129 6.98925C8.8899 6.67597 8.45424 6.5 8 6.5C7.54576 6.5 7.1101 6.67597 6.78871 6.98925L4.16615 9.54879C4.05973 9.65327 4 9.7946 4 9.94192C4 10.0892 4.05973 10.2306 4.16615 10.3351Z" fill="#131330" />
    </SvgIcon>
  );
}

export default ArrowUpCircle;
