import { SvgIcon } from '@mui/material';
import React from 'react';

function ClockIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '19px', ...sx }} width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
      <path d="M9.40909 0.181885C7.54815 0.181885 5.729 0.733718 4.18168 1.7676C2.63437 2.80149 1.42838 4.27099 0.716229 5.99027C0.00407683 7.70956 -0.182255 9.60141 0.180797 11.4266C0.543849 13.2518 1.43998 14.9283 2.75586 16.2442C4.07175 17.5601 5.74829 18.4562 7.57347 18.8193C9.39866 19.1823 11.2905 18.996 13.0098 18.2838C14.7291 17.5717 16.1986 16.3657 17.2325 14.8184C18.2664 13.2711 18.8182 11.4519 18.8182 9.59098C18.8155 7.09636 17.8233 4.70469 16.0593 2.94072C14.2954 1.17676 11.9037 0.184583 9.40909 0.181885V0.181885ZM10.1932 9.4961C10.1933 9.62901 10.1596 9.75977 10.0952 9.87608C10.0309 9.99238 9.93807 10.0904 9.82545 10.161L6.81454 12.0428C6.72702 12.0975 6.62958 12.1344 6.5278 12.1514C6.42601 12.1684 6.32187 12.1653 6.22132 12.142C6.12077 12.1188 6.02578 12.076 5.94177 12.0161C5.85776 11.9561 5.78638 11.8802 5.73171 11.7927C5.67703 11.7052 5.64013 11.6077 5.62311 11.506C5.60609 11.4042 5.60928 11.3 5.63251 11.1995C5.65574 11.0989 5.69854 11.0039 5.75848 10.9199C5.81841 10.8359 5.89431 10.7646 5.98183 10.7099L8.625 9.06171V5.67052C8.625 5.46257 8.70761 5.26313 8.85466 5.11609C9.00171 4.96904 9.20114 4.88643 9.40909 4.88643C9.61705 4.88643 9.81649 4.96904 9.96353 5.11609C10.1106 5.26313 10.1932 5.46257 10.1932 5.67052V9.4961Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default ClockIcon;
