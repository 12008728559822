import { SvgIcon } from '@mui/material';
import React from 'react';

function CloseIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '103px', ...sx }} width="103" height="85" viewBox="0 0 103 85" fill="none" {...props}>
      <path d="M79.7493 47.3607L52.3734 72.5354L25 47.3607L49.9828 29.7577C51.4165 28.7475 53.3303 28.7474 54.764 29.7575L79.7493 47.3607Z" fill="url(#paint0_linear_2631_15663)" />
      <path d="M74.921 72.5357H29.8243V38.1877C29.8243 37.4692 30.4068 36.8867 31.1254 36.8867H73.6199C74.3385 36.8867 74.921 37.4692 74.921 38.1877V72.5357V72.5357Z" fill="#F5F7F9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M63.328 62.0674L61.5209 60.2624C62.772 58.4002 63.5038 56.1542 63.5038 53.7366C63.5038 47.2557 58.2421 42 51.7539 42C45.2658 42 40 47.2557 40 53.7366C40 60.2175 45.2617 65.4732 51.7498 65.4732C54.1579 65.4732 56.3901 64.7504 58.2585 63.513L60.0737 65.3262C60.9731 66.2246 62.4367 66.2246 63.3321 65.3262C64.2233 64.4278 64.2233 62.9658 63.328 62.0674ZM58.1235 56.877L57.3876 56.1337C56.4882 55.2353 55.0246 55.2353 54.1293 56.1337C53.2298 57.0322 53.2298 58.4941 54.1293 59.3885L54.857 60.1154C53.9207 60.5809 52.8619 60.8382 51.7458 60.8382C47.821 60.8382 44.6362 57.657 44.6362 53.7366C44.6362 49.8162 47.821 46.635 51.7458 46.635C55.6706 46.635 58.8554 49.8162 58.8554 53.7366C58.8513 54.8637 58.5937 55.9296 58.1235 56.877Z" fill="#D9D9D9" />
      <path d="M25.0002 47.3613L61.4997 80.9258H27.7944C26.2512 80.9258 25.0002 79.6748 25.0002 78.1316V47.3613Z" fill="url(#paint1_linear_2631_15663)" />
      <path d="M79.7513 47.3613L43.2518 80.9258H76.9571C78.5003 80.9258 79.7513 79.6748 79.7513 78.1316V47.3613Z" fill="url(#paint2_linear_2631_15663)" />
      <path d="M31.6241 21.079C30.7483 21.6201 29.6935 21.7911 28.6916 21.5545C27.6898 21.3178 26.8229 20.6929 26.2818 19.8171C26.1015 19.5252 25.8125 19.3169 25.4786 19.238C25.1446 19.1592 24.793 19.2162 24.5011 19.3965C24.2092 19.5769 24.0009 19.8658 23.922 20.1998C23.8431 20.5338 23.9001 20.8854 24.0805 21.1773C24.6216 22.053 24.7926 23.1079 24.556 24.1097C24.3193 25.1116 23.6944 25.9784 22.8186 26.5195C22.5267 26.6999 22.3184 26.9888 22.2395 27.3228C22.1606 27.6568 22.2177 28.0084 22.398 28.3003C22.5784 28.5922 22.8673 28.8005 23.2013 28.8794C23.5352 28.9583 23.8869 28.9013 24.1788 28.7209C25.0545 28.1798 26.1094 28.0088 27.1112 28.2454C28.1131 28.4821 28.9799 29.107 29.521 29.9828C29.7014 30.2747 29.9903 30.483 30.3243 30.5619C30.6582 30.6407 31.0099 30.5837 31.3018 30.4034C31.5937 30.223 31.802 29.9341 31.8809 29.6001C31.9598 29.2661 31.9028 28.9145 31.7224 28.6226C31.1813 27.7469 31.0103 26.692 31.2469 25.6902C31.4835 24.6883 32.1085 23.8215 32.9842 23.2804C33.2762 23.1 33.4845 22.8111 33.5634 22.4771C33.6422 22.1431 33.5852 21.7915 33.4049 21.4996C33.2245 21.2077 32.9355 20.9994 32.6016 20.9205C32.2676 20.8416 31.916 20.8986 31.6241 21.079Z" fill="#108AE2" />
      <path d="M5.60139 19.7571C6.5482 19.1722 7.68861 18.9872 8.77175 19.2431C9.85489 19.4989 10.792 20.1746 11.377 21.1214C11.572 21.437 11.8844 21.6622 12.2455 21.7474C12.6065 21.8327 12.9866 21.7711 13.3022 21.5761C13.6178 21.3811 13.843 21.0687 13.9283 20.7077C14.0136 20.3466 13.952 19.9665 13.757 19.6509C13.172 18.7041 12.9871 17.5637 13.2429 16.4805C13.4987 15.3974 14.1744 14.4602 15.1212 13.8752C15.4368 13.6803 15.662 13.3679 15.7473 13.0068C15.8326 12.6458 15.7709 12.2656 15.5759 11.95C15.3809 11.6344 15.0685 11.4092 14.7075 11.3239C14.3464 11.2387 13.9663 11.3003 13.6507 11.4953C12.7039 12.0803 11.5635 12.2652 10.4804 12.0094C9.39722 11.7535 8.46007 11.0779 7.87508 10.1311C7.68008 9.81549 7.3677 9.59028 7.00665 9.505C6.6456 9.41972 6.26547 9.48136 5.94987 9.67635C5.63427 9.87135 5.40905 10.1837 5.32378 10.5448C5.2385 10.9058 5.30013 11.286 5.49513 11.6016C6.08012 12.5484 6.26504 13.6888 6.0092 14.7719C5.75336 15.8551 5.07772 16.7922 4.13092 17.3772C3.81532 17.5722 3.59011 17.8846 3.50483 18.2456C3.41955 18.6067 3.48119 18.9868 3.67618 19.3024C3.87118 19.618 4.18356 19.8432 4.54461 19.9285C4.90566 20.0138 5.28579 19.9521 5.60139 19.7571Z" fill="#5F82FF" />
      <path d="M67.6014 27.7571C68.5482 27.1722 69.6886 26.9872 70.7717 27.2431C71.8549 27.4989 72.792 28.1746 73.377 29.1214C73.572 29.437 73.8844 29.6622 74.2455 29.7474C74.6065 29.8327 74.9866 29.7711 75.3022 29.5761C75.6178 29.3811 75.843 29.0687 75.9283 28.7077C76.0136 28.3466 75.952 27.9665 75.757 27.6509C75.172 26.7041 74.9871 25.5637 75.2429 24.4805C75.4987 23.3974 76.1744 22.4602 77.1212 21.8752C77.4368 21.6803 77.662 21.3679 77.7473 21.0068C77.8326 20.6458 77.7709 20.2656 77.5759 19.95C77.3809 19.6344 77.0685 19.4092 76.7075 19.3239C76.3464 19.2387 75.9663 19.3003 75.6507 19.4953C74.7039 20.0803 73.5635 20.2652 72.4804 20.0094C71.3972 19.7535 70.4601 19.0779 69.8751 18.1311C69.6801 17.8155 69.3677 17.5903 69.0067 17.505C68.6456 17.4197 68.2655 17.4814 67.9499 17.6764C67.6343 17.8714 67.4091 18.1837 67.3238 18.5448C67.2385 18.9058 67.3001 19.286 67.4951 19.6016C68.0801 20.5484 68.265 21.6888 68.0092 22.7719C67.7534 23.8551 67.0777 24.7922 66.1309 25.3772C65.8153 25.5722 65.5901 25.8846 65.5048 26.2456C65.4195 26.6067 65.4812 26.9868 65.6762 27.3024C65.8712 27.618 66.1836 27.8432 66.5446 27.9285C66.9057 28.0138 67.2858 27.9521 67.6014 27.7571Z" fill="#5F82FF" />
      <path d="M14.9631 34.294C14.5295 34.133 14.0497 34.1508 13.6292 34.3435C13.2087 34.5362 12.8821 34.8881 12.721 35.3217C12.56 35.7553 12.5778 36.2351 12.7705 36.6556C12.9632 37.076 13.3151 37.4027 13.7487 37.5638L14.1465 37.7733C16.7549 38.3645 19.202 39.5203 21.3154 41.1593C21.6785 41.4317 22.1326 41.5537 22.5832 41.4999C23.0339 41.446 23.4465 41.2206 23.7352 40.8704C24.0162 40.5105 24.1435 40.0542 24.0893 39.6009C24.0352 39.1476 23.804 38.7341 23.4462 38.4506C20.9541 36.4652 18.0592 35.0468 14.9631 34.294V34.294Z" fill="#5F82FF" />
      <path d="M100.388 21.7819C100.799 21.5697 101.109 21.203 101.249 20.7624C101.39 20.3217 101.35 19.8433 101.138 19.4323C100.926 19.0213 100.559 18.7113 100.118 18.5707C99.6776 18.4301 99.1991 18.4703 98.7881 18.6824L98.3653 18.8354C96.1719 20.3659 93.6697 21.397 91.0349 21.8559C90.589 21.941 90.1931 22.1946 89.9294 22.564C89.6658 22.9335 89.5547 23.3903 89.6192 23.8396C89.6961 24.2896 89.9477 24.6909 90.3193 24.9561C90.6909 25.2213 91.1523 25.3288 91.6028 25.2552C94.7487 24.7494 97.7467 23.5641 100.388 21.7819V21.7819Z" fill="#5F82FF" />
      <path d="M88.1389 35.0927C88.3906 35.2217 88.6791 35.2597 88.9557 35.2004C89.2322 35.1411 89.4797 34.9881 89.6564 34.7672L89.8097 34.5757C89.8922 34.4306 89.9442 34.2702 89.9626 34.1043C89.981 33.9384 89.9653 33.7705 89.9165 33.6109C89.8678 33.4512 89.7869 33.3032 89.679 33.1759C89.5711 33.0486 89.4383 32.9447 89.2888 32.8704C87.4216 31.8864 85.3012 31.4878 83.2041 31.7268C81.107 31.9657 79.1306 32.831 77.5326 34.2098C77.2912 34.4334 77.1485 34.7436 77.1359 35.0724C77.1233 35.4012 77.2418 35.7215 77.4653 35.9629C77.6888 36.2043 77.9991 36.347 78.3279 36.3596C78.6566 36.3722 78.9769 36.2537 79.2183 36.0302C80.4459 35.0092 81.9486 34.3752 83.5364 34.2084C85.1243 34.0415 86.726 34.3492 88.1389 35.0927V35.0927Z" fill="#FF795C" />
      <path d="M41.6632 21.792C42.0469 22.0093 42.4991 22.0719 42.9274 21.9668C43.3557 21.8618 43.7277 21.5972 43.9673 21.227C44.2069 20.8568 44.2961 20.4091 44.2166 19.9754C44.1371 19.5416 43.8949 19.1546 43.5396 18.8935C40.3526 16.6739 37.6926 13.7809 35.7478 10.4192C35.5338 9.98927 35.1578 9.662 34.7025 9.50935C34.2472 9.35669 33.75 9.39115 33.3201 9.60515C32.8902 9.81915 32.5629 10.1952 32.4103 10.6505C32.2576 11.1058 32.2921 11.603 32.5061 12.0329C34.7746 15.9415 37.9068 19.2796 41.6632 21.792V21.792Z" fill="#FF795C" />
      <path d="M59.7927 6.30449C62.9729 13.4995 55.5176 18.3616 55.2422 18.6241C54.8612 18.8757 54.5952 19.2676 54.502 19.7145C54.4089 20.1615 54.4962 20.6271 54.745 21.0098L54.9598 21.2351C55.2311 21.5436 55.6034 21.7453 56.0099 21.8043C56.4164 21.8633 56.8306 21.7757 57.1784 21.5571C59.9846 19.9042 62.1177 17.3143 63.2023 14.2434C64.2868 11.1725 64.2533 7.81744 63.1076 4.76882C62.9263 4.346 62.5844 4.01252 62.1572 3.84176C61.73 3.67099 61.2524 3.67693 60.8296 3.85826C60.4068 4.03959 60.0733 4.38147 59.9025 4.80867C59.7318 5.23588 59.7377 5.71342 59.919 6.13625L59.7927 6.30449Z" fill="#F9C134" />
      <path d="M90.7082 15.1623C92.8508 10.025 98.978 11.4817 99.2469 11.4951C99.5616 11.5681 99.8924 11.5136 100.167 11.3437C100.442 11.1738 100.638 10.9021 100.713 10.5879L100.724 10.3679C100.75 10.0785 100.673 9.78915 100.505 9.55181C100.337 9.31447 100.09 9.1446 99.8086 9.07261C97.5933 8.43796 95.225 8.60518 93.1208 9.54482C91.0166 10.4845 89.3113 12.1363 88.3051 14.2095C88.1765 14.5086 88.172 14.8465 88.2925 15.1489C88.413 15.4513 88.6487 15.6934 88.9477 15.8221C89.2468 15.9507 89.5847 15.9552 89.8871 15.8347C90.1895 15.7142 90.4317 15.4785 90.5603 15.1795L90.7082 15.1623Z" fill="#F9C134" />
      <path d="M82.4399 10.5408C84.0308 9.55788 84.5236 7.47143 83.5407 5.88058C82.5578 4.28974 80.4713 3.79692 78.8805 4.77984C77.2896 5.76276 76.7968 7.8492 77.7797 9.44005C78.7627 11.0309 80.8491 11.5237 82.4399 10.5408Z" fill="#108AE2" />
      <path d="M49.4399 7.5408C51.0308 6.55788 51.5236 4.47143 50.5407 2.88058C49.5578 1.28974 47.4713 0.796918 45.8805 1.77984C44.2896 2.76276 43.7968 4.8492 44.7797 6.44005C45.7627 8.03089 47.8491 8.52372 49.4399 7.5408Z" fill="#FF795C" />
      <path d="M22.8039 9.45416C23.7436 8.87357 24.0347 7.64115 23.4541 6.70148C22.8735 5.7618 21.6411 5.4707 20.7014 6.05129C19.7618 6.63188 19.4707 7.8643 20.0512 8.80397C20.6318 9.74365 21.8643 10.0347 22.8039 9.45416Z" fill="#F9C134" />
      <path d="M89.2434 84.5314H12.2566C12.1149 84.5314 12 84.1887 12 83.7657C12 83.3427 12.1149 83 12.2566 83H89.2434C89.3851 83 89.5 83.3427 89.5 83.7657C89.5 84.1887 89.3851 84.5314 89.2434 84.5314Z" fill="url(#paint3_linear_2631_15663)" />
      <defs>
        <linearGradient id="paint0_linear_2631_15663" x1="85.1124" y1="34.351" x2="71.6341" y2="79.2696" gradientUnits="userSpaceOnUse">
          <stop stopColor="#108AE2" />
          <stop offset="1" stopColor="#FF53A5" />
        </linearGradient>
        <linearGradient id="paint1_linear_2631_15663" x1="65.0751" y1="51.4868" x2="53.3838" y2="85.1788" gradientUnits="userSpaceOnUse">
          <stop stopColor="#108AE2" />
          <stop offset="1" stopColor="#FF53A5" />
        </linearGradient>
        <linearGradient id="paint2_linear_2631_15663" x1="56.0018" y1="52.9995" x2="86.6053" y2="57.4674" gradientUnits="userSpaceOnUse">
          <stop stopColor="#108AE2" />
          <stop offset="1" stopColor="#FF53A5" />
        </linearGradient>
        <linearGradient id="paint3_linear_2631_15663" x1="37.1875" y1="81.2133" x2="37.5766" y2="89.5978" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFEFEF" />
          <stop offset="1" stopColor="#B7B7D1" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}

export default CloseIcon;
