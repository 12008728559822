import axios from 'axios';

console.log(process.env);
console.log('process.env.REACT_APP_BACKEND_URL', process.env.REACT_APP_BACKEND_URL);

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

export default client;
