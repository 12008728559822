import { SvgIcon } from '@mui/material';
import React from 'react';

function LoadingIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '156px', ...sx }} width="156" height="156" viewBox="0 0 156 156" fill="none" {...props}>
      <path d="M137.829 118.192L138.058 117.851C145.989 106.032 150.629 91.7766 150.629 76.4312C150.629 35.2964 117.271 1.9375 76.1366 1.9375C35.0023 1.9375 1.61816 35.2966 1.61816 76.4312C1.61816 117.566 34.9765 150.925 76.1105 150.925C91.3774 150.925 105.529 146.337 117.375 138.483L117.716 138.257L118.005 138.546L129.59 150.132C135.136 155.678 144.159 155.677 149.678 150.134C155.173 144.588 155.173 135.562 149.653 130.016L137.829 118.192ZM137.829 118.192L138.12 118.483L149.652 130.015L137.829 118.192Z" stroke="#DEDEDE" />
      <path d="M150.361 129.31L150.36 129.309L139.117 118.066C147.016 106.154 151.629 91.8332 151.629 76.4312C151.629 34.7441 117.823 0.9375 76.1366 0.9375C34.4505 0.9375 0.618164 34.7439 0.618164 76.4312C0.618164 118.118 34.4242 151.925 76.1105 151.925C91.435 151.925 105.652 147.363 117.586 139.542L128.883 150.839C134.818 156.775 144.477 156.776 150.387 150.838L150.388 150.837C156.27 144.902 156.27 135.245 150.361 129.31Z" fill="#E8E8E8" stroke="#E8E8E8" />
      <mask id="mask0_126_5760" style={{ 'mask-type': 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="154" height="154">
        <path d="M137.829 118.192L138.058 117.851C145.989 106.032 150.629 91.7766 150.629 76.4312C150.629 35.2964 117.271 1.9375 76.1366 1.9375C35.0023 1.9375 1.61816 35.2966 1.61816 76.4312C1.61816 117.566 34.9765 150.925 76.1105 150.925C91.3774 150.925 105.529 146.337 117.375 138.483L117.716 138.257L118.005 138.546L129.59 150.132C135.136 155.678 144.159 155.677 149.678 150.134C155.173 144.588 155.173 135.562 149.653 130.016L137.829 118.192ZM137.829 118.192L138.12 118.483L149.652 130.015L137.829 118.192Z" fill="#8F3737" stroke="#D3D0D8" />
      </mask>
      <g mask="url(#mask0_126_5760)">
        <path d="M33.6236 116.287L25.8587 109.25C22.6756 106.365 17.7891 106.486 14.7516 109.523C9.6761 114.599 0.997821 111.004 0.997807 103.826L0.99762 11.9688C0.997586 -4.59976 14.4291 -18.0312 30.9976 -18.0312H124.417C140.985 -18.0312 154.417 -4.5998 154.417 11.9687V38.5475V103.187C154.417 110.068 145.848 113.224 141.385 107.988C138.503 104.606 133.312 104.509 130.305 107.78L126.253 112.189C120.926 117.984 111.666 117.589 106.853 111.361L101.457 104.38C98.6813 100.789 93.2162 100.929 90.6285 104.658L84.5441 113.425C80.6024 119.105 73.1242 121.09 66.8847 118.112L62.4109 115.977C60.1933 114.919 57.5633 115.241 55.667 116.804C49.2092 122.126 39.8246 121.906 33.6236 116.287Z" fill="white" />
        <ellipse cx="75.0002" cy="77.2175" rx="60.3908" ry="60.7097" fill="white" />
      </g>
      <path d="M105.196 129.202C112.574 124.942 118.967 119.169 123.956 112.263" stroke="#108AE2" strokeWidth="4" strokeLinejoin="round" />
      <circle cx="75.0002" cy="76.8986" r="60.3908" stroke="#108AE2" strokeOpacity="0.9" fill="transparent" />
      <circle cx="75.0003" cy="76.9017" r="48.3782" stroke="#F5F7F9" strokeWidth="0.5" fill="transparent" />
    </SvgIcon>
  );
}

export default LoadingIcon;
