import { SvgIcon } from '@mui/material';
import React from 'react';

function SmallDatonicsLogo({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '35px', ...sx }} width="35" height="28" viewBox="0 0 35 28" fill="none" {...props}>
      <path d="M14.6718 21.9637C14.6718 18.5671 14.6718 15.1715 14.6718 11.7768C14.6718 10.3351 15.613 9.38975 17.0422 9.38696C17.8704 9.38696 18.7014 9.43437 19.5254 9.37581C20.8138 9.28239 21.8205 10.3518 21.8958 11.636C21.8958 11.7489 21.8958 11.8619 21.8958 11.9748C21.8958 18.6341 21.8958 25.2938 21.8958 31.954C21.8958 32.8923 21.7103 33.7833 20.7594 34.2992C20.498 34.447 20.2022 34.523 19.9019 34.5195C18.8868 34.5195 17.8704 34.5195 16.8539 34.5195C15.6158 34.5195 14.6955 33.6243 14.6872 32.3764C14.6732 30.4063 14.6872 28.4375 14.6872 26.4673L14.6718 21.9637Z" fill="white" />
      <path d="M9.93116 7.93409C10.4889 7.93409 11.0606 7.93409 11.6239 7.93409C12.7072 7.93409 13.495 8.6424 13.5327 9.7216C13.5731 10.9026 13.5675 12.0919 13.5327 13.2673C13.5034 14.2838 12.6766 15.0688 11.5472 15.1218C10.4387 15.1748 9.32602 15.1748 8.21754 15.1218C6.91664 15.0618 6.31012 14.3661 6.30733 13.0498C6.30733 11.9623 6.30733 10.8742 6.30733 9.78574C6.30733 8.68702 7.06723 7.93688 8.17989 7.9313C8.76411 7.92572 9.34834 7.93409 9.93116 7.93409Z" fill="white" />
      <path d="M18.233 0.125918C18.9845 0.125918 19.7374 0.125918 20.4903 0.125918C21.1708 0.134283 21.6155 0.481468 21.6337 1.16189C21.6783 2.69843 21.6783 4.23636 21.6337 5.77568C21.6169 6.47284 20.8529 7.19091 20.0651 7.24111C18.9438 7.32705 17.8183 7.34335 16.695 7.28991C15.3146 7.20346 14.6649 6.41986 14.6649 5.04925C14.6649 3.92264 14.6649 2.79743 14.6649 1.67221C14.6649 0.623688 15.1682 0.125918 16.1986 0.121735C16.8749 0.121735 17.5525 0.121735 18.2302 0.121735L18.233 0.125918Z" fill="white" />
      <path d="M26.7089 7.93406C27.3294 7.93406 27.9513 7.9243 28.5717 7.93406C29.3832 7.94939 30.0971 8.60333 30.1152 9.41761C30.1459 10.7673 30.1292 12.1184 30.1152 13.4695C30.1152 14.0816 29.736 14.5069 29.2396 14.755C28.8246 14.9805 28.3644 15.1101 27.8927 15.1343C27.1216 15.1455 26.3492 15.1608 25.5795 15.1343C24.0695 15.0743 23.3612 14.32 23.3612 12.8197C23.3612 11.8256 23.3612 10.8314 23.3612 9.8359C23.3612 8.56847 23.9956 7.93545 25.2365 7.93545H26.7047L26.7089 7.93406Z" fill="white" />
      <path d="M3.72501 15.9151C4.19489 15.9151 4.66477 15.9151 5.13466 15.9151C6.19713 15.9151 6.87894 16.3919 7.01001 17.4488C7.1843 18.8599 7.22194 20.296 6.95702 21.7029C6.90655 22.0027 6.76666 22.2804 6.55566 22.4993C6.34467 22.7183 6.07242 22.8684 5.77465 22.9299C4.506 23.2146 3.19183 23.2307 1.91658 22.9773C0.975414 22.8058 0.555729 22.2285 0.55294 21.2707C0.55294 19.9963 0.55294 18.7205 0.55294 17.446C0.559912 16.47 1.11067 15.9221 2.08669 15.9123C2.63465 15.9109 3.17983 15.9151 3.72501 15.9151Z" fill="white" />
      <path d="M33.1674 22.8044C32.6222 22.8044 32.077 22.7891 31.5332 22.8044C31.2579 22.8254 30.9837 22.752 30.7556 22.5964C30.5275 22.4408 30.3592 22.2123 30.2783 21.9483C29.7359 20.2974 29.7764 18.6019 30.1319 16.9162C30.3146 16.0503 30.9685 15.745 31.7549 15.7101C32.7477 15.6641 33.7474 15.6683 34.7415 15.7101C35.7678 15.7505 36.4036 16.4644 36.4496 17.644C36.4928 18.7483 36.5026 19.8568 36.4496 20.9597C36.398 22.0068 35.8207 22.941 34.4738 22.8128C34.0458 22.7709 33.6094 22.8058 33.1771 22.8058L33.1674 22.8044Z" fill="white" />
    </SvgIcon>
  );
}

export default SmallDatonicsLogo;
