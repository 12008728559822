import { SvgIcon } from '@mui/material';
import React from 'react';

function HistoryIcon(props) {
  return (
    <SvgIcon width="19" height="16" viewBox="0 0 19 16" fill="none" {...props}>
      <path d="M16.7468 4.99997H1.74984C1.33571 4.99997 1 5.32622 1 5.72868V11.5585C1.00246 13.5698 2.6796 15.1997 4.74922 15.2021H13.7474C15.817 15.1997 17.4942 13.5698 17.4967 11.5585V5.72868C17.4967 5.32622 17.1609 4.99997 16.7468 4.99997ZM11.4979 8.64361H6.99876C6.58464 8.64361 6.24893 8.31736 6.24893 7.9149C6.24893 7.51243 6.58464 7.18618 6.99876 7.18618H11.4979C11.912 7.18618 12.2477 7.51243 12.2477 7.9149C12.2477 8.31736 11.912 8.64361 11.4979 8.64361Z" fill="#615E8B" />
      <path d="M1.29159 1.50012C1.47039 1.14252 1.83589 0.916626 2.2357 0.916626H16.324C16.6769 0.916626 17.0065 1.09301 17.2022 1.38666L17.906 2.44222C18.3736 3.1437 17.8707 4.08329 17.0277 4.08329H1.70792C0.923243 4.08329 0.412887 3.25752 0.763807 2.55568L1.29159 1.50012Z" fill="white" />
    </SvgIcon>
  );
}

export default HistoryIcon;
