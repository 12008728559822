import { SvgIcon } from '@mui/material';
import React from 'react';

function DeleteIcon({ sx, ...props }) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25ZM5.75 13.5H18.25V10.5H5.75V13.5Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default DeleteIcon;
