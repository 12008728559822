import { SvgIcon } from '@mui/material';
import React from 'react';

function ArrowLeftIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '18px', ...sx }} width="12" height="20" viewBox="0 0 12 20" fill="none" {...props}>
      <path d="M1.12804 9.28427C0.938761 9.47468 0.832519 9.73225 0.832519 10.0007C0.832519 10.2692 0.938761 10.5268 1.12804 10.7172L9.25805 18.8472C9.45246 19.0137 9.70254 19.1007 9.9583 19.0908C10.2141 19.0809 10.4567 18.9749 10.6377 18.7939C10.8187 18.6129 10.9247 18.3703 10.9346 18.1145C10.9445 17.8588 10.8575 17.6087 10.691 17.4143L3.28249 10.0058L10.7011 2.59734C10.8925 2.40597 11 2.14643 11 1.8758C11 1.60517 10.8925 1.34562 10.7011 1.15426C10.5098 0.962895 10.2502 0.855388 9.97959 0.855388C9.70896 0.855388 9.44942 0.962895 9.25805 1.15426L1.12804 9.28427Z" fill="#D3D0D9" stroke="#D3D0D9" strokeWidth="1.2195" />
    </SvgIcon>
  );
}

export default ArrowLeftIcon;
