import { SvgIcon } from '@mui/material';
import React from 'react';

function CloseIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '20px', ...sx }} width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M11.7674 9.99998L19.6338 2.13448C20.1221 1.64618 20.1221 0.854488 19.6338 0.366227C19.1455 -0.122074 18.3538 -0.122074 17.8655 0.366227L9.99998 8.23255L2.13447 0.366227C1.64617 -0.122074 0.854487 -0.122074 0.366226 0.366227C-0.122036 0.854527 -0.122075 1.64621 0.366226 2.13448L8.23255 9.99998L0.366226 17.8655C-0.122075 18.3538 -0.122075 19.1455 0.366226 19.6338C0.854526 20.122 1.64621 20.1221 2.13447 19.6338L9.99998 11.7674L17.8655 19.6338C18.3538 20.1221 19.1455 20.1221 19.6337 19.6338C20.122 19.1455 20.122 18.3538 19.6337 17.8655L11.7674 9.99998Z" fill="#D3D0D9" />
    </SvgIcon>
  );
}

export default CloseIcon;
