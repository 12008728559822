export const SKINS = {
  IIQ: 'iiq',
  DATONICS: 'datonics',
};

const { hostname, origin } = window.location;

let defaultSkin = SKINS.IIQ;
const datonicsHost = ':7000';
if (hostname.endsWith('datonics.com') || origin.includes(datonicsHost)) { defaultSkin = SKINS.DATONICS; }

export const skin = defaultSkin;
