import { SvgIcon } from '@mui/material';
import React from 'react';

function StatusSuccessIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '67px', ...sx }} width="67" height="66" viewBox="0 0 67 66" fill="none" {...props}>
      <g filter="url(#filter0_f_3402_19787)">
        <rect x="15.2935" y="15" width="36" height="36" rx="18" fill="#3ADB90" fillOpacity="0.12" />
      </g>
      <path d="M32.5153 38.451C32.4103 38.5566 32.2671 38.6156 32.1183 38.6156C31.9694 38.6156 31.8262 38.5566 31.7212 38.451L28.5501 35.2794C28.2209 34.9502 28.2209 34.4166 28.5501 34.0881L28.9472 33.691C29.2763 33.3619 29.8093 33.3619 30.1384 33.691L32.1183 35.6708L37.468 30.3211C37.7971 29.9919 38.3307 29.9919 38.6593 30.3211L39.0564 30.7182C39.3855 31.0473 39.3855 31.5809 39.0564 31.9094L32.5153 38.451Z" fill="#63D4A0" />
      <defs>
        <filter id="filter0_f_3402_19787" x="0.293457" y="0" width="66" height="66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_3402_19787" />
        </filter>
      </defs>

    </SvgIcon>
  );
}

export default StatusSuccessIcon;
