import { SvgIcon } from '@mui/material';
import React from 'react';

function UploadingToS3ActiveIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '114px', ...sx }} width="114" height="113" viewBox="0 0 114 113" fill="none" {...props}>
      <circle cx="57.3188" cy="56.5" r="56.5" fill="#63D4A0" fillOpacity="0.05" />
      <circle cx="57.319" cy="56.4982" r="38.2912" fill="#63D4A0" fillOpacity="0.1" />
      <circle cx="57.3188" cy="56.4961" r="32.6172" fill="#63D4A0" />
      <path d="M52.4088 52.2721C51.9829 51.8462 51.9829 51.1578 52.4088 50.7319C52.8347 50.306 53.5231 50.306 53.9489 50.7319L56.4466 53.2295L56.4466 45.6049C56.4466 45.0025 56.9334 44.5156 57.5358 44.5156C58.1381 44.5156 58.625 45.0025 58.625 45.6049L58.625 53.2295L61.1226 50.7319C61.5485 50.306 62.2369 50.306 62.6628 50.7319C63.0887 51.1578 63.0887 51.8462 62.6628 52.2721L59.076 55.8589C58.6544 56.2804 58.1 56.4928 57.5456 56.495L57.5358 56.4972L57.526 56.495C56.9716 56.4928 56.4171 56.2804 55.9956 55.8589L52.4088 52.2721Z" fill="white" stroke="white" strokeWidth="0.6" />
      <rect x="45.1191" y="62.4883" width="24.3988" height="3.48555" fill="white" />
      <path d="M46.644 55.5156C47.4862 55.5156 48.1689 56.1984 48.1689 57.0406L48.1689 65.9723L45.1191 65.9723L45.1191 57.0405C45.1191 56.1984 45.8018 55.5156 46.644 55.5156Z" fill="white" />
      <path d="M67.9931 55.5156C68.8353 55.5156 69.5181 56.1984 69.5181 57.0406L69.5181 65.9723L66.4682 65.9723L66.4682 57.0405C66.4682 56.1984 67.1509 55.5156 67.9931 55.5156Z" fill="white" />

    </SvgIcon>
  );
}

export default UploadingToS3ActiveIcon;
