import { SvgIcon } from '@mui/material';
import React from 'react';

function OfflineDataIcon(props) {
  return (
    <SvgIcon width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
      <path d="M7.58301 10.8346L12.9997 5.41797M12.9997 5.41797L18.4163 10.8346M12.9997 5.41797L12.9997 12.4596" stroke="#615E8B" strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9997 14.082V17.8737C11.9997 18.426 12.4474 18.8737 12.9997 18.8737C13.552 18.8737 13.9997 18.426 13.9997 17.8737V14.082H19.6663C20.7709 14.082 21.6663 14.9775 21.6663 16.082V20.7487C21.6663 21.8533 20.7709 22.7487 19.6663 22.7487H6.33301C5.22844 22.7487 4.33301 21.8533 4.33301 20.7487V16.082C4.33301 14.9775 5.22844 14.082 6.33301 14.082H11.9997Z" fill="#615E8B" />
    </SvgIcon>
  );
}

export default OfflineDataIcon;
