import { SvgIcon } from '@mui/material';
import React from 'react';

function DatinocsGrayIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '282px', ...sx }} width="729" height="615" viewBox="0 0 729 615" fill="none" {...props}>
      <g style={{ mixBlendMode: 'multiply' }}>
        <rect x="275.973" y="366.026" width="87.0053" height="87.0053" stroke="#EBEFF3" strokeWidth="4" />
        <rect x="1.45605" y="91.5098" width="90.0053" height="90.0053" stroke="#EBEFF3" strokeOpacity="0.3" />
        <rect x="364.978" y="273.021" width="91.0053" height="91.0053" fill="#EBEFF3" />
        <rect x="461.483" y="187.516" width="80.0053" height="80.0053" stroke="#EBEFF3" strokeWidth="11" />
        <rect x="184.467" y="187.906" width="88.0053" height="88.0053" stroke="#EBEFF3" strokeOpacity="0.2" strokeWidth="3" />
        <rect x="546.989" y="273.021" width="91.0053" height="91.0053" fill="#EBEFF3" />
        <rect x="527.334" y="0.504883" width="90.0053" height="90.0053" stroke="#EBEFF3" />
        <rect x="640.495" y="366.526" width="86.0053" height="86.0053" stroke="#EBEFF3" strokeWidth="5" />
        <rect x="366.978" y="93.0098" width="87.0053" height="87.0053" stroke="#EBEFF3" strokeWidth="4" />
        <rect x="617.839" y="91.0098" width="91.0053" height="91.0053" fill="#EBEFF3" fillOpacity="0.1" />
        <rect x="455.606" y="364.026" width="91.3827" height="250.974" fill="#EBEFF3" fillOpacity="0.6" />
      </g>

    </SvgIcon>
  );
}

export default DatinocsGrayIcon;
