import { SvgIcon } from '@mui/material';
import React from 'react';

function PlusCircleIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '37px', ...sx }} width="37" height="36" viewBox="0 0 37 36" fill="none" {...props}>
      <circle cx="18.3477" cy="18" r="18" fill="#D3D0D9" />
      <path d="M24.181 16.8333H19.5143V12.1667C19.5143 11.8572 19.3914 11.5605 19.1726 11.3417C18.9538 11.1229 18.6571 11 18.3477 11C18.0382 11 17.7415 11.1229 17.5227 11.3417C17.3039 11.5605 17.181 11.8572 17.181 12.1667V16.8333H12.5143C12.2049 16.8333 11.9082 16.9563 11.6894 17.175C11.4706 17.3938 11.3477 17.6906 11.3477 18C11.3477 18.3094 11.4706 18.6062 11.6894 18.825C11.9082 19.0438 12.2049 19.1667 12.5143 19.1667H17.181V23.8333C17.181 24.1428 17.3039 24.4395 17.5227 24.6583C17.7415 24.8771 18.0382 25 18.3477 25C18.6571 25 18.9538 24.8771 19.1726 24.6583C19.3914 24.4395 19.5143 24.1428 19.5143 23.8333V19.1667H24.181C24.4904 19.1667 24.7872 19.0438 25.0059 18.825C25.2247 18.6062 25.3477 18.3094 25.3477 18C25.3477 17.6906 25.2247 17.3938 25.0059 17.175C24.7872 16.9563 24.4904 16.8333 24.181 16.8333Z" fill="white" />
    </SvgIcon>
  );
}

export default PlusCircleIcon;
