import { SvgIcon } from '@mui/material';
import React from 'react';

function UploadErrorIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '147px', ...sx }} width="147" height="148" viewBox="0 0 147 148" fill="none" {...props}>
      <circle cx="73.7382" cy="73.8984" r="59.9999" fill="#F5F7F9" fillOpacity="0.4" />
      <circle cx="73.7378" cy="73.8979" r="72.8745" stroke="#E90D41" strokeWidth="0.5" fill="transparent" />
      <circle cx="73.7379" cy="73.9009" r="39.9244" fill="#E90D41" stroke="#E90D41" strokeWidth="0.5" />
      <path d="M73.7383 60.3984C66.2824 60.3984 60.2383 66.4426 60.2383 73.8984C60.2383 81.3543 66.2824 87.3984 73.7383 87.3984C81.1941 87.3984 87.2383 81.3543 87.2383 73.8984C87.2302 66.4459 81.1908 60.4065 73.7383 60.3984ZM73.7383 84.0234C68.1464 84.0234 63.6133 79.4903 63.6133 73.8984C63.6133 68.3065 68.1464 63.7734 73.7383 63.7734C79.3302 63.7734 83.8633 68.3065 83.8633 73.8984C83.8571 79.4878 79.3276 84.0172 73.7383 84.0234Z" fill="white" />
      <path d="M73.7383 77.375C74.6703 77.375 75.4258 76.6195 75.4258 75.6875V67.8125C75.4258 66.8805 74.6703 66.125 73.7383 66.125C72.8063 66.125 72.0508 66.8805 72.0508 67.8125V75.6875C72.0508 76.6195 72.8063 77.375 73.7383 77.375Z" fill="white" />
      <path d="M73.7441 81.7734C74.6761 81.7734 75.4316 81.0179 75.4316 80.0859C75.4316 79.154 74.6761 78.3984 73.7441 78.3984C72.8122 78.3984 72.0566 79.154 72.0566 80.0859C72.0566 81.0179 72.8122 81.7734 73.7441 81.7734Z" fill="white" />
    </SvgIcon>
  );
}

export default UploadErrorIcon;
