import { SvgIcon } from '@mui/material';
import React from 'react';

function NoteBookIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '18px', ...sx }} width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path d="M14.3125 3.15625H12.7188C12.7188 2.56922 12.2433 2.09375 11.6563 2.09375H10.5938C10.5938 1.50672 10.1183 1.03125 9.53125 1.03125H8.46875C7.88172 1.03125 7.40625 1.50672 7.40625 2.09375H6.34375C5.75672 2.09375 5.28125 2.56922 5.28125 3.15625H3.6875C3.10047 3.15625 2.625 3.63172 2.625 4.21875V15.9063C2.625 16.4933 3.10047 16.9688 3.6875 16.9688H14.3125C14.8995 16.9688 15.375 16.4933 15.375 15.9063V4.21875C15.375 3.63172 14.8995 3.15625 14.3125 3.15625ZM12.7188 13.7813H5.28125C4.988 13.7813 4.75 13.5433 4.75 13.25C4.75 12.9568 4.988 12.7188 5.28125 12.7188H12.7188C13.012 12.7188 13.25 12.9568 13.25 13.25C13.25 13.5433 13.012 13.7813 12.7188 13.7813ZM12.7188 10.5938H5.28125C4.988 10.5938 4.75 10.3558 4.75 10.0625C4.75 9.76925 4.988 9.53125 5.28125 9.53125H12.7188C13.012 9.53125 13.25 9.76925 13.25 10.0625C13.25 10.3558 13.012 10.5938 12.7188 10.5938ZM12.7188 7.40625H5.28125C4.988 7.40625 4.75 7.16825 4.75 6.875C4.75 6.58175 4.988 6.34375 5.28125 6.34375H12.7188C13.012 6.34375 13.25 6.58175 13.25 6.875C13.25 7.16825 13.012 7.40625 12.7188 7.40625Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default NoteBookIcon;
