import { SvgIcon } from '@mui/material';
import React from 'react';

function ViewIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '19px', ...sx }} width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
      <g clipPath="url(#clip0_277_20355)">
        <path d="M18.8596 8.94883C18.1712 7.44337 15.4716 2.53418 9.59087 2.53418C3.71019 2.53418 1.01057 7.44337 0.322136 8.94883C0.229722 9.15058 0.181885 9.36987 0.181885 9.59178C0.181885 9.81369 0.229722 10.033 0.322136 10.2347C1.01057 11.7386 3.71019 16.6478 9.59087 16.6478C15.4716 16.6478 18.1712 11.7386 18.8596 10.2332C18.9518 10.0316 18.9996 9.81262 18.9996 9.591C18.9996 9.36937 18.9518 9.15035 18.8596 8.94883ZM9.59087 14.2955C8.6604 14.2955 7.75083 14.0196 6.97717 13.5027C6.20351 12.9857 5.60052 12.251 5.24444 11.3913C4.88837 10.5317 4.7952 9.58578 4.97673 8.67319C5.15825 7.76059 5.60632 6.92232 6.26426 6.26438C6.9222 5.60644 7.76047 5.15837 8.67306 4.97685C9.58566 4.79532 10.5316 4.88849 11.3912 5.24456C12.2509 5.60064 12.9856 6.20363 13.5026 6.97729C14.0195 7.75095 14.2954 8.66053 14.2954 9.591C14.2942 10.8383 13.7981 12.0342 12.9161 12.9162C12.0341 13.7982 10.8382 14.2943 9.59087 14.2955Z" fill="#108AE2" />
        <path d="M9.59071 12.7273C11.3229 12.7273 12.7271 11.3231 12.7271 9.59095C12.7271 7.85879 11.3229 6.45459 9.59071 6.45459C7.85854 6.45459 6.45435 7.85879 6.45435 9.59095C6.45435 11.3231 7.85854 12.7273 9.59071 12.7273Z" fill="#108AE2" />
      </g>
      <defs>
        <clipPath id="clip0_277_20355">
          <rect width="18.8182" height="18.8182" fill="white" transform="translate(0.181885 0.181885)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default ViewIcon;
