import { SvgIcon } from '@mui/material';
import React from 'react';

function AccordionArrow({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '8px', ...sx }} width="8" height="5" viewBox="0 0 8 5" fill="none" {...props}>
      <path d="M7.83385 0.664947C7.78074 0.612681 7.71754 0.571196 7.64792 0.542886C7.57829 0.514575 7.50361 0.5 7.42818 0.5C7.35276 0.5 7.27808 0.514575 7.20845 0.542886C7.13882 0.571196 7.07563 0.612681 7.02252 0.664947L4.40567 3.21891C4.35255 3.27118 4.28936 3.31266 4.21973 3.34097C4.15011 3.36928 4.07543 3.38386 4 3.38386C3.92457 3.38386 3.84989 3.36928 3.78027 3.34097C3.71064 3.31266 3.64745 3.27118 3.59433 3.21891L0.977485 0.664947C0.924369 0.612681 0.861176 0.571196 0.79155 0.542886C0.721924 0.514575 0.647243 0.5 0.571817 0.5C0.49639 0.5 0.421709 0.514575 0.352083 0.542886C0.282457 0.571196 0.219264 0.612681 0.166148 0.664947C0.0597313 0.769427 0 0.91076 0 1.05808C0 1.2054 0.0597313 1.34673 0.166148 1.45121L2.78871 4.01075C3.1101 4.32403 3.54576 4.5 4 4.5C4.45424 4.5 4.8899 4.32403 5.21129 4.01075L7.83385 1.45121C7.94027 1.34673 8 1.2054 8 1.05808C8 0.91076 7.94027 0.769427 7.83385 0.664947V0.664947Z" fill="#D3D0D9" />
    </SvgIcon>
  );
}

export default AccordionArrow;
