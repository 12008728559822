import { SvgIcon } from '@mui/material';
import React from 'react';

function FinishedIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '43px', ...sx }} width="42" height="43" viewBox="0 0 42 43" fill="none" {...props}>
      <path d="M4.80982 2.62017C4.66982 2.06017 4.29982 1.58017 3.79982 1.29017C3.28982 1.00017 2.69982 0.920172 2.12982 1.07017C0.969816 1.39017 0.269816 2.59017 0.589816 3.75017L10.8198 41.9002C10.9798 42.5002 11.6198 42.9902 12.2498 42.9902H14.5198C15.1498 42.9902 15.5198 42.5002 15.3598 41.9002L4.80982 2.62017ZM41.4498 24.3802C39.7898 18.1902 38.1298 12.0002 36.4698 5.82017C36.3098 5.22017 35.7398 4.47017 35.1798 4.20017C24.9098 -0.749828 19.0598 10.7602 8.49982 4.72017C10.3498 11.6402 12.2098 18.5602 14.0598 25.4702C24.6098 31.5002 30.4698 20.0002 40.7298 24.9402C41.2998 25.2202 41.6098 24.9902 41.4498 24.3802Z" fill="#D32196" />
    </SvgIcon>
  );
}

export default FinishedIcon;
