import { SvgIcon } from '@mui/material';
import React from 'react';

function StarIcon(props) {
  return (
    <SvgIcon width="22" height="21" viewBox="0 0 22 21" fill="none" {...props}>
      <path d="M10.1103 0.73821C10.3903 -0.123661 11.6097 -0.123662 11.8897 0.738209L13.7087 6.33637C13.8339 6.72182 14.1931 6.98278 14.5984 6.98278H20.4846C21.3908 6.98278 21.7676 8.14242 21.0345 8.67509L16.2724 12.1349C15.9445 12.3732 15.8073 12.7954 15.9326 13.1808L17.7515 18.779C18.0315 19.6409 17.0451 20.3576 16.3119 19.8249L11.5499 16.3651C11.222 16.1268 10.778 16.1268 10.4501 16.3651L5.68805 19.8249C4.9549 20.3576 3.96845 19.6409 4.24849 18.779L6.06744 13.1808C6.19268 12.7954 6.05548 12.3732 5.72761 12.1349L0.965526 8.67509C0.232375 8.14242 0.609164 6.98278 1.51539 6.98278H7.40165C7.80692 6.98278 8.16611 6.72182 8.29135 6.33638L10.1103 0.73821Z" fill="#D3D0D9" />
    </SvgIcon>
  );
}

export default StarIcon;
